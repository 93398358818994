using Aip.Florence.FrontEnd.SpafApp.Classes;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge;
using Bridge.Html5;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.StruttureGiorni
{
    public class EditStrutturaGiornoViewModel: LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IStruttureGiorniResource struttureGiorniResource;
        private readonly IStruttureGiorniService _strutturaGiornoService;
        private readonly IMessenger _messenger;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.EditStruttureGiorniId;
}
        public int IdStruttura { get; set; }
        public int IdGiorno { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.StruttureGiorniErroriDto>StruttureGiorniErrori { get; set; }

        public EditStrutturaGiornoViewModel(INavigator navigator, IStruttureGiorniService StrutturaGiornoService,
            IStruttureGiorniResource StruttureGiorniResource, IMessenger messenger)
        {
            _navigator = navigator;
            _strutturaGiornoService = StrutturaGiornoService;
            struttureGiorniResource = StruttureGiorniResource;
            _messenger = messenger;

            StruttureGiorniErrori = Retyped.knockout.ko.observableArray.Self<StruttureGiorniErroriDto>();
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            IdStruttura = parameters.GetParameter<int>("idStruttura");
            IdGiorno = parameters.GetParameter<int>("idGiorno");

            base.OnLoad(parameters);

            await Hydrate();
        }

        private async Task Hydrate()
        {
            if (IdStruttura != 0 && IdGiorno != 0)
            {
                var strutturaDtoTask = await this.struttureGiorniResource.GetErrori(IdStruttura, IdGiorno);

                this.StruttureGiorniErrori.push(strutturaDtoTask.Items);
            }
        }
    }
}
