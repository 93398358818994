using System;
using System.Linq;
using System.Reflection;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Classes;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using Aip.Florence.FrontEnd.SpafApp.Resources.Impl;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Services.Impl;
using Aip.Florence.FrontEnd.SpafApp.ViewModels;
using Bridge;
using Bridge.Html5;
using Bridge.Ioc;
using Bridge.jQuery2;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf.Attributes;
using Retyped.Primitive;

namespace Bridge.Spaf
{
    public class SpafApp
    {
        public static IIoc Container;

        public static void Main()
        {
            Container = new BridgeIoc();
            ContainerConfig(); // config container
            Container.Resolve<MainViewModel>().Init().ContinueWith((global::System.Action<global::System.Threading.Tasks.Task>)(c =>
            {
                var exception = !c.IsFaulted ? null : System.Linq.Enumerable.First<global::System.Exception>(c.Exception.InnerExceptions);

                if (exception == null)
                {
                    jQuery.Select(".preloader").FadeOut();
                    Container.Resolve<INavigator>().InitNavigation(); // init navigation
                }
                else
                {
PromiseException promiseException;                    if (!((promiseException = exception as PromiseException) != null)) return;
                    
                    var errorCode = promiseException.ErrorCode();
                    if(errorCode != 401)
                        Global.Alert("Si è verificato un errore imprevisto. Contattare l'assistenza.");
                }
               
            }));

        }

        private static void ContainerConfig()
        {
            // navigator
            Container.RegisterSingleInstance<INavigator, BridgeNavigatorWithRouting>();
            Container.RegisterSingleInstance<IBrowserHistoryManager, QueryParameterNavigationHistory>();

            Container.Register<INavigatorConfigurator, CustomRoutesConfig>(); 

            // messenger
            Container.RegisterSingleInstance<IMessenger, Messenger.Messenger>();

            // viewmodels
            RegisterAllViewModels();

            // resources
            Container.Register<IInfermieriResource, InfermieriResource>();
            Container.Register<IStruttureResource, StruttureResource>();
            Container.Register<ITipiTurniResource, TipiTurniResource>();
            Container.Register<IGiorniResource, GiorniResource>();
            //TODO: CLENTI RESOURCE
            Container.Register<IPeriodoPrototipoResource, PeriodoPrototipoResource>();
            Container.Register<IStruttureGiorniResource, StruttureGiorniResource>();
            Container.Register<IFasceOrarieResource, FasceOrarieResource>();
            Container.Register<IDashboardResource, DashboardResource>();
            Container.Register<IAnomalieResource, AnomalieResource>();
            Container.Register<ITurnazioniMensiliResource, TurnazioniMensiliResource>();
            Container.Register<IIndisponibilitaResource, IndisponibilitaResource>();
            Container.Register<ICalendariResource, CalendariResource>();
            Container.Register<INecessitaGiorniResource, NecessitaGiorniResource>();
            Container.Register<IUtentiResource, UtentiResource>();
            Container.Register<ITimbratureResource, TimbratureResource>();
            Container.Register<IExportResource, ExportResource>();


            // services
            Container.Register<IInfermieriService, InfermieriService>();
            Container.Register<IStruttureService, StruttureService>();
            Container.Register<ITipiTurniService, TipiTurniService>();
            Container.Register<IClientiService, ClientiService>();
            Container.Register<IPeriodoPrototipoService, PeriodoPrototipoService>();
            Container.Register<IStruttureGiorniService, StruttureGiorniService>();
            Container.Register<IGiorniService, GiorniService>();
            Container.Register<IFasceOrarieService, FasceOrarieService>();
            Container.Register<IDashboardService, DashboardService>();
            Container.Register<IAnomalieService, AnomalieService>();
            Container.Register<ITurnazioniMensiliService, TurnazioniMensiliService>();
            Container.Register<IIndisponibilitaService, IndisponibilitaService>();
            Container.Register<ICalendariService, CalendariService>();
            Container.Register<INecessitaGiorniService, NecessitaGiorniService>();
            Container.Register<IKeyValueRepository, KeyValueRepository>();
            Container.Register<ITimbratureService, TimbratureService>();
            Container.Register<ICookieService, CookieService>();
            Container.Register<IExportService, ExportService>();


            // register custom resource, services..
            Container.RegisterSingleInstance<ISettings,Settings>();

        }
#region PAGES IDS
// static pages id
public static string HomeId
{
    get
    {
        return "home";
    }
}public static string LoginId
{
    get
    {
        return "loginPage";
    }
}public static string HeaderId
{
    get
    {
        return "header";
    }
}public static string EditCreateInfermieriId
{
    get
    {
        return "infermieriEditCreate";
    }
}public static string EditStruttureId
{
    get
    {
        return "struttureEdit";
    }
}public static string InfermieriId
{
    get
    {
        return "infermieri";
    }
}public static string StruttureId
{
    get
    {
        return "strutture";
    }
}public static string TipiTurniId
{
    get
    {
        return "tipiturni";
    }
}public static string ClientiId
{
    get
    {
        return "clienti";
    }
}public static string EditClientiId
{
    get
    {
        return "clientiEdit";
    }
}public static string PeriodoPrototipoId
{
    get
    {
        return "periodoPrototipo";
    }
}public static string EditStruttureGiorniId
{
    get
    {
        return "struttureGiorniEdit";
    }
}public static string AnomaliaId
{
    get
    {
        return "anomalie";
    }
}public static string IndisponibilitaId
{
    get
    {
        return "IndisponibilitaId";
    }
}public static string NecessitaId
{
    get
    {
        return "NecessitaId";
    }
}public static string ResolveAnomaliaId
{
    get
    {
        return "ResolveAnomaliaId";
    }
}public static string Indisponibilita
{
    get
    {
        return "indisponibilita";
    }
}public static string Necessita
{
    get
    {
        return "necessita";
    }
}public static string Timbrature
{
    get
    {
        return "timbrature";
    }
}public static string OverlapNecessita
{
    get
    {
        return "overlapping";
    }
}public static string AlertNightNecessita
{
    get
    {
        return "alertNight";
    }
}
        #endregion

        #region MESSAGES
        // messenger helper for global messages and messages ids

        public static class Messages
        {
            public class GlobalSender { };

            public static GlobalSender Sender = new GlobalSender();
public static string LoginDone
{
    get
    {
        return "LoginDone";
    }
}public static string ModificaMeseAnno
{
    get
    {
        return "ModificaMeseAnno";
    }
}public static string AnomalieStruttura
{
    get
    {
        return "AnomalieStruttura";
    }
}public static string ChiudiTurnazioneMensile
{
    get
    {
        return "ChiudiTurnazioneMensile";
    }
}public static string CambiaAnnoMeseInfermiere
{
    get
    {
        return "CambiaAnnoMeseInfermiere";
    }
}public static string CambiaInfermierePeriodoPrototipo
{
    get
    {
        return "CambiaInfermierePeriodoPrototipo";
    }
}public static string CambiaPaginaPeriodoPrototipo
{
    get
    {
        return "CambiaPaginaPeriodoPrototipo";
    }
}public static string AggiungiSettimana
{
    get
    {
        return "AggiungiSettimana";
    }
}public static string RimuoviUltimaSettimana
{
    get
    {
        return "RimuoviUltimaSettimana";
    }
}public static string RimuoviTipoTurno
{
    get
    {
        return "RimuoviTipoTurno";
    }
}public static string RefreshInfermieriListByStructure
{
    get
    {
        return "RefreshInfermieriListByStructure";
    }
}public static string RefreshInfermieriListAll
{
    get
    {
        return "RefreshInfermieriListAll";
    }
}            
        }


        #endregion

        /// <summary>
        /// Register all types that end with "viewmodel".
        /// You can register a viewmode as Singlr Instance adding "SingleInstanceAttribute" to the class
        /// </summary>
        private static void RegisterAllViewModels()
        {
            var types = System.Linq.Enumerable.SelectMany<global::System.Reflection.Assembly,global::System.Type>(AppDomain.CurrentDomain.GetAssemblies(),(global::System.Func<global::System.Reflection.Assembly, global::System.Collections.Generic.IEnumerable<global::System.Type>>)(s => s.GetTypes()))
                .Where((global::System.Func<global::System.Type, bool>)(w => w.Name.ToLower().EndsWith("viewmodel"))).ToList();

            types.ForEach((global::System.Action<global::System.Type>)(f =>
            {
                var attributes = f.GetCustomAttributes(typeof(SingleInstanceAttribute), true);

                if (System.Linq.Enumerable.Any<object>(attributes))
                    Container.RegisterSingleInstance(f);
                else
                    Container.Register(f);
            }));

        }
    }
}
