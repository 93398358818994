using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class StruttureGiorniDto
    {
        [JsonProperty("strutturadto")]
        public StruttureDto Strutture { get; set; }

        [JsonProperty("crossstrutturesiornidDto")]
        public CrossStruttureGiorniDto[] CrossGiorni { get; set; }

        public StruttureGiorniDto(StruttureDto strutturaDto, CrossStruttureGiorniDto[] crossStruttureGiorniDto)
        {
            Strutture = strutturaDto;
            CrossGiorni = crossStruttureGiorniDto;
        }
    }
}
