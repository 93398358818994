using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.KnockoutModels;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf;
using System;
using System.Collections.Generic;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture
{
    public class NecessitaGiorniViewModel : LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IInfermieriService _infermieriService;
        private readonly IStruttureService _struttureService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        private readonly IMessenger _messenger;

        public NecessitaGiornoKo necessitaGiornoKo { get; set; }
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.NecessitaId;
}
        public NecessitaGiorniViewModel(INavigator navigator, IInfermieriService InfermieriService, IStruttureService StruttureService, INecessitaGiorniService NecessitaGiorniService, IMessenger messenger)
        {
            _navigator = navigator;
            _infermieriService = InfermieriService;
            _struttureService = StruttureService;
            _necessitaGiorniService = NecessitaGiorniService;
            _messenger = messenger;

            necessitaGiornoKo = new NecessitaGiornoKo(null);
        }

        public void InitNuovaNecessitaGiorni(int idTurnazioneMensile, int idInfermiere, int anno, int mese, string causale, string errore)
        {
            DateTime data = Convert.ToDateTime(anno.ToString() + "-" + mese.ToString().PadLeft(2, '0') + "-" + DateTime.Now.Day.ToString().PadLeft(2, '0'));

            NecessitaGiornoDto dto = new NecessitaGiornoDto();
            dto.IdTurnazioneMensile = idTurnazioneMensile;
            dto.IdInfermiere = idInfermiere;
            dto.Data = data;
            dto.PeriodoDa = "";
            dto.PeriodoA = "";
            dto.Causale = causale;

            necessitaGiornoKo.Refresh(dto, errore);
        }
    }
}
