using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class StruttureService: IStruttureService
    {
        private readonly IStruttureResource _struttureResource;

        public StruttureService(IStruttureResource StruttureResource)
        {
            _struttureResource = StruttureResource;
        }

        public Task<BaseDtoOutputModel<StruttureDto>> GetAllStrutture()
        {
            return _struttureResource.GetAllStrutture();
        }

        public Task<StruttureDto[]> GetAllStruttureByClientId(int cliId)
        {
            throw new NotImplementedException();
        }

        public async Task<InfermiereOrdinatoDto[]> GetInfermieriOrdinatiByStruttura(int strutturaId)
        {
            var list = await _struttureResource.GetInfermieriOrdinatiByStruttura(strutturaId);
            var ret = new List<InfermiereOrdinatoDto>();
            var empty = new InfermiereOrdinatoDto {
                Id = 0,
                Infermiere = new InfermieriDto { Id = 0, Cognome = "", MexalId = "", Nome = "", NomeCompleto = ""},
                NomeCompletoPriorita = "",
                Priorita = -1
            };
            ret.Add(empty);
            ret.AddRange(list);
            return ret.ToArray();
        }

        public Task<InfermieriDto[]> GetOrderedAssociatedInfermieriForStruttura(int strutturaId, int all)
        {
            return _struttureResource.GetOrderedAssociatedInfermieriForStruttura(strutturaId, all);
        }

        public Task<StruttureDto> EditStruttura(int strutturaId, StruttureDto dto)
        {
            return _struttureResource.EditStruttura(strutturaId, dto);
        }
    }
}
