using Newtonsoft.Json;
using System;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class IndisponibilitaDto
    {
        [JsonProperty("infermiereid")]
        public int InfermiereId { get; set; }

        [JsonProperty("data")]
        public DateTime Data { get; set; }

        [JsonProperty("periodo")]
        public PeriodiDto Periodo { get; set; }

        [JsonProperty("id")]
        public int Id { get; set; }

        [JsonProperty("infermiere")]
        public InfermieriDto Infermiere { get; set; }

        [JsonProperty("dataindisponibilita")]
        public string DataIndisponibilita { get; set; }

        [JsonProperty("note")]
        public string Note { get; set; }

        public IndisponibilitaDto(int infermiereId, DateTime data, PeriodiDto periodo, string note, int id)
        {
            InfermiereId = infermiereId;
            Data = data;
            Periodo = periodo;
            Note = note;
            Id = id;
        }

        public IndisponibilitaDto(int infermiereId, DateTime data, PeriodiDto periodo, int id, InfermieriDto infermiere, string note)
        {
            InfermiereId = infermiereId;
            Data = data;
            Periodo = periodo;
            Note = note;
            Id = id;
            Infermiere = infermiere;
        }

        public IndisponibilitaDto()
        {
        }
    }
}
