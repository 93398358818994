using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class FascieOrarieGiorniPerCalendarioDto
    {
        [JsonProperty("fasciaoraria")]
        public FasceOrarieDto FasceOrarie { get; set; }

        [JsonProperty("crossgiorni")]
        public CrossFasceOrarieGiorniPerCalendarioDto[] CrossGiorni { get; set; }

        public FascieOrarieGiorniPerCalendarioDto(FasceOrarieDto fasciaOrariaDto, CrossFasceOrarieGiorniPerCalendarioDto[] crossFasciaOrariaGiornoDto)
        {
            FasceOrarie = fasciaOrariaDto;
            CrossGiorni = crossFasciaOrariaGiornoDto;
        }
    }
}
