using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Bridge.Navigation;
using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class TimbratureResource : AuthorizedResourceBase, ITimbratureResource
    {
        public Task<NecessitaGiornoDto[]> GetTimbrature(int infermiereId, int strutturaId, string da, string a)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/Timbrature/{0}/{1}/{2}/{3}",strutturaId,infermiereId,da,a)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<NecessitaGiornoDto[]>(options);
        }

        public TimbratureResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
