using System;
using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class OverlapNecessitaDto
    {
        [JsonProperty("data")]
        public DateTime Data { get; set; }
        [JsonProperty("idinfermiere")]
        public int? InfermiereId { get; set; }
        [JsonProperty("infermiere")]
        public string Infermiere { get; set; }

        public NecessitaDescription Turno1 { get; set; }
        public NecessitaDescription Turno2 { get; set; }
        
        public string DataString { get { return Data.Date.ToString("dd/MM/yyyy"); } }
        
    }
    public class NecessitaDescription
    {
        [JsonProperty("idnecessita")]
        public int IdNecessita { get; set; }
        [JsonProperty("causale")]
        public string Causale { get; set; }
        [JsonProperty("periododa")]
        public string PeriodoDa { get; set; }
        [JsonProperty("periodoa")]
        public string PeriodoA { get; set; }
        [JsonProperty("idturnazioneMensile")]
        public int IdTurnazioneMensile { get; set; }
        [JsonProperty("idstruttura")]
        public int IdStruttura { get; set; }
        [JsonProperty("struttura")]
        public string Struttura { get; set; }
public string Url
{
    get
    {
        return string.Format("#struttureEdit?strutturaId={0}",IdStruttura);
    }
}    }
}