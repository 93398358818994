using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.KnockoutModels;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.IndisponibilitaInfermieri
{
    public class IndisponibilitaViewModel : LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IIndisponibilitaService _indisponibilitaService;
        private readonly IMessenger _messenger;

        public IndisponibilitaKo IndisponibilitaKo { get; set; }
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.IndisponibilitaId;
}
        public IndisponibilitaViewModel(INavigator navigator, IIndisponibilitaService IndisponibilitaService, IMessenger messenger)
        {
            _navigator = navigator;
            _indisponibilitaService = IndisponibilitaService;
            _messenger = messenger;
            IndisponibilitaKo = new IndisponibilitaKo(null);
        }

        public void InitNuovaIndisponibilita(int idInfermiere, int anno, int mese, string note, string errore)
        {
            DateTime data = Convert.ToDateTime(anno.ToString() + "-" + mese.ToString().PadLeft(2, '0') + "-" + DateTime.Now.Day.ToString().PadLeft(2, '0'));  
            PeriodiDto periodo = new PeriodiDto();
            periodo.A = "";
            periodo.Da = "";
            IndisponibilitaDto indDto = new IndisponibilitaDto(idInfermiere, data, periodo, note, 0);

            IndisponibilitaKo.Refresh(indDto, errore);
        }

        public void InitEditIndisponibilita(int id, int idInfermiere, string data, string da, string a, string note, string errore)
        {
            PeriodiDto periodo = new PeriodiDto();
            periodo.A = a;
            periodo.Da = da;
            IndisponibilitaDto indDto = new IndisponibilitaDto(idInfermiere, Convert.ToDateTime(data), periodo, note, id);

            IndisponibilitaKo.Refresh(indDto, errore);
        }
    }
}
