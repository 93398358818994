using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class CalendariResource: AuthorizedResourceBase, ICalendariResource
    {
       
        public CalendariResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }


        public Task<CalendariDto[]> GetCalendariPerInfermierePerMese(int idInfermiere, int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/calendari/{0}/{1}/{2}",idInfermiere,anno,mese)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<CalendariDto[]>(options);
        }

        public Task<CalendariStrutturaDto[]> GetCalendariPerStrutturaPerMese(int idStruttura, int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/calendari/GetDashboardPerMesePerStruttura/{0}/{1}/{2}",idStruttura,anno,mese)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<CalendariStrutturaDto[]>(options);
        }

        public Task<BaseDtoOutputModel<MesiDto>> GetMesi()
        {
            BaseDtoOutputModel<MesiDto> mesi = new BaseDtoOutputModel<MesiDto>();

            MesiDto[] items = new MesiDto[12];
            
            items[0] = new MesiDto();
            items[0].Id = 1;
            items[0].Mese = "Gennaio";
            items[1] = new MesiDto();
            items[1].Id = 2;
            items[1].Mese = "Febbraio";
            items[2] = new MesiDto();
            items[2].Id = 3;
            items[2].Mese = "Marzo";
            items[3] = new MesiDto();
            items[3].Id = 4;
            items[3].Mese = "Aprile";
            items[4] = new MesiDto();
            items[4].Id = 5;
            items[4].Mese = "Maggio";
            items[5] = new MesiDto();
            items[5].Id = 6;
            items[5].Mese = "Giugno";
            items[6] = new MesiDto();
            items[6].Id = 7;
            items[6].Mese = "Luglio";
            items[7] = new MesiDto();
            items[7].Id = 8;
            items[7].Mese = "Agosto";
            items[8] = new MesiDto();
            items[8].Id = 9;
            items[8].Mese = "Settembre";
            items[9] = new MesiDto();
            items[9].Id = 10;
            items[9].Mese = "Ottobre";
            items[10] = new MesiDto();
            items[10].Id = 11;
            items[10].Mese = "Novembre";
            items[11] = new MesiDto();
            items[11].Id = 12;
            items[11].Mese = "Dicembre";

            mesi.Items = items;

            return Task.FromResult<BaseDtoOutputModel<MesiDto>>(mesi);
        }

        public Task<BaseDtoOutputModel<AnniDto>> GetAnni()
        {
            BaseDtoOutputModel<AnniDto> anni = new BaseDtoOutputModel<AnniDto>();

            AnniDto[] items = new AnniDto[5];

            int annoAttuale = DateTime.Now.Year;

            items[0] = new AnniDto();
            items[0].Id = annoAttuale - 2;
            items[0].Anno = Convert.ToString(annoAttuale - 2);
            items[1] = new AnniDto();
            items[1].Id = annoAttuale - 1;
            items[1].Anno = Convert.ToString(annoAttuale - 1);
            items[2] = new AnniDto();
            items[2].Id = annoAttuale;
            items[2].Anno = Convert.ToString(annoAttuale);
            items[3] = new AnniDto();
            items[3].Id = annoAttuale + 1;
            items[3].Anno = Convert.ToString(annoAttuale + 1);
            items[4] = new AnniDto();
            items[4].Id = annoAttuale + 2;
            items[4].Anno = Convert.ToString(annoAttuale + 2);

            anni.Items = items;

            return Task.FromResult<BaseDtoOutputModel<AnniDto>>(anni);
        }


        public Task<TipiTurniDto[]> GetDashboardPeriodoPrototipoPerStruttura(int idStruttura, int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/calendari/GetDashboardPeriodoPrototipoPerStruttura/{0}/{1}/{2}",idStruttura,anno,mese)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<TipiTurniDto[]>(options);
        }
    }
}
