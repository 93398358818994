using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Html5;
using Bridge.Navigation;
using Bridge.Spaf;
using System.Collections.Generic;
using System.Threading.Tasks;
using Retyped;
using System;
using System.Linq;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge;
using Bridge.jQuery2;
using System.Globalization;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Timbrature
{
    public class ListaTimbratureViewModel : LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IInfermieriService _infermieriService;
        private readonly IStruttureService _struttureService;
        private readonly ITimbratureService _timbratureService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.Timbrature;
}
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.TimbratureGroupByInfermiereDto>Timbrature { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>Loader { get; set; }

        public int InfermiereId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>DataInizio { get; set; }
        public Retyped.knockout.KnockoutObservable <string>DataFine { get; set; }

        public Retyped.knockout.KnockoutObservable <int>InfermiereCorrente { get; set; }
        public Retyped.knockout.KnockoutObservable <int>StrutturaCorrente { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>Infermieri { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.StruttureDto>Strutture { get; set; }

        public ListaTimbratureViewModel(INavigator navigator, IInfermieriService infermieriService, IStruttureService struttureService, ITimbratureService timbratureService)
        {
            _navigator = navigator;
            _timbratureService = timbratureService;
            _infermieriService = infermieriService;
            _struttureService = struttureService;
            Timbrature = Retyped.knockout.ko.observableArray.Self<TimbratureGroupByInfermiereDto>();
            Loader = Retyped.knockout.ko.observable.Self<bool>();

            this.InfermiereId = 0;
            string dataInizio = DateTime.Now.Year.ToString() + "-" + DateTime.Now.Month.ToString().PadLeft(2, '0') + "-01";
            string dataFine = DateTime.Now.Year.ToString() + "-" + DateTime.Now.Month.ToString().PadLeft(2, '0') + "-" + DateTime.Now.Day.ToString().PadLeft(2, '0');

            DataInizio = Retyped.knockout.ko.observable.Self<string>(dataInizio);
            DataFine = Retyped.knockout.ko.observable.Self<string>(dataFine);
            InfermiereCorrente = Retyped.knockout.ko.observable.Self<int>(0);
            StrutturaCorrente = Retyped.knockout.ko.observable.Self<int>(0);
            Infermieri = Retyped.knockout.ko.observableArray.Self<InfermieriDto>();
            Strutture = Retyped.knockout.ko.observableArray.Self<StruttureDto>();
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            try
            {
                Script.Write("$(\".select2\").select2()");

                Loader.Self(true);

                base.OnLoad(parameters);

                var infermieri = await _infermieriService.GetAllInfermieri();
                Infermieri.push(infermieri.Items);

                var strutture = await _struttureService.GetAllStrutture();
                Strutture.push(strutture.Items);

                if (parameters != null && parameters.Count > 0)
                    jQuery.Select("#infermiere").Val(Convert.ToString(this.InfermiereId)).Trigger("change");

                BootstrapMaterialDatePicker.Render(new BootstrapMaterialDatePickerOption()
                {
                    Time = false,
                    WeekStart = 0
                }, ".dateTimePicker");

                Select2.Render((global::System.Action<object>)(o =>
                {

                }), "#infermiere");

                Select2.Render((global::System.Action<object>)(o =>
                {

                }), "#struttura");

                //await HydrateTimbrature();

                Loader.Self(false);
            }
            catch (Exception e)
            {
                Console.WriteLine(e);
                throw;
            }
        }

        public override void OnLeave()
        {
            Script.Call("RemoveDateTimePickerListaTimbrature");

            base.OnLeave();
        }

        public async void CalcolaLista()
        {
            var infermiereId = 0;
            var strutturaId = 0;
            
            if (this.InfermiereCorrente.Self() > 0)
                infermiereId = this.InfermiereCorrente.Self();
           

            if (this.StrutturaCorrente.Self() > 0)
                strutturaId = this.StrutturaCorrente.Self();
            if (infermiereId == 0 && strutturaId == 0)
            {
                BootstrapDialog.Alert("Selezionare una struttura o un infermiere per visualizzare i dati delle timbrature");
                return;
            }
            
            Loader.Self(true);
            
            await HydrateTimbrature();

            Loader.Self(false);
        }

        private async Task HydrateTimbrature()
        {
            int infermiereId = 0;
            int strutturaId = 0;
            
            this.Timbrature.removeAll(); // clear list
        
            if (this.InfermiereCorrente.Self() > 0)
                infermiereId = this.InfermiereCorrente.Self();
            else
                infermiereId = 0;

            if (this.StrutturaCorrente.Self() > 0)
                strutturaId = this.StrutturaCorrente.Self();
            else
                strutturaId = 0;

            var timbrature = await _timbratureService.GetTimbrature(strutturaId, infermiereId, this.DataInizio.Self(), this.DataFine.Self());

            IEnumerable<NecessitaGiornoDto> orderedList = System.Linq.Enumerable.OrderBy<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto,string>(timbrature,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto, string>)(x => x.Infermiere)).ThenBy<global::System.DateTime>((global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto, global::System.DateTime>)(n => n.Data));

            List<TimbratureGroupByInfermiereDto> timbratureGroupByInfermiereDto = new List<TimbratureGroupByInfermiereDto>();

            int count = System.Linq.Enumerable.Count<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(orderedList);
            int indice = 0;
            int Id = 1;
            foreach (NecessitaGiornoDto nec in orderedList)
            {
                double totalHours = 0;
                IEnumerable<NecessitaGiornoDto> findTimes = System.Linq.Enumerable.Where<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(System.Linq.Enumerable.ToList<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(orderedList),(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto, bool>)(z => z.IdInfermiere == nec.IdInfermiere));

                try
                {
                    foreach (NecessitaGiornoDto tick in findTimes)
                    {
                        DateTime periodoDa = DateTime.ParseExact(tick.PeriodoDa, "HH:mm", new DateTimeFormatInfo());
                        DateTime periodoA = DateTime.ParseExact(tick.PeriodoA, "HH:mm", new DateTimeFormatInfo());

                        TimeSpan diff = periodoA - periodoDa;

                        totalHours += diff.TotalHours;
                    }
                }
                catch { }

                TimbratureGroupByInfermiereDto timbratureInfermiere = new TimbratureGroupByInfermiereDto();
                timbratureInfermiere.IdInfermiere = nec.IdInfermiere;
                timbratureInfermiere.Struttura = nec.Struttura;
                timbratureInfermiere.Data = nec.Data.Year.ToString() + "-" + nec.Data.Month.ToString().PadLeft(2, '0') + "-" + nec.Data.Day.ToString().PadLeft(2, '0');
                timbratureInfermiere.Da = nec.PeriodoDa;
                timbratureInfermiere.A = nec.PeriodoA;
                timbratureInfermiere.PeriodoLavoratoDa = nec.PeriodoLavoratoDa;
                timbratureInfermiere.PeriodoLavoratoA = nec.PeriodoLavoratoA;
                timbratureInfermiere.Infermiere = nec.Infermiere;
                timbratureInfermiere.TotaleOre = totalHours;
                timbratureInfermiere.Id = Id;
                timbratureInfermiere.Note = nec.Note;
                Id++;

                timbratureGroupByInfermiereDto.Add(timbratureInfermiere);

                if (indice + 1 < count)
                {
                    if (nec.IdInfermiere != System.Linq.Enumerable.ToArray<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(orderedList)[indice + 1].IdInfermiere)
                    {
                        TimbratureGroupByInfermiereDto totale = new TimbratureGroupByInfermiereDto();
                        totale.IdInfermiere = nec.IdInfermiere;
                        totale.Struttura = "";
                        totale.Data = "";
                        totale.Da = "";
                        totale.A = "";
                        totale.PeriodoLavoratoDa = "";
                        totale.PeriodoLavoratoA = "";
                        totale.Infermiere = "Totale ore " + totalHours.ToString();
                        totale.TotaleOre = totalHours;
                        totale.Id = Id;
                        Id++;

                        timbratureGroupByInfermiereDto.Add(totale);
                    }
                }
                else
                {
                    TimbratureGroupByInfermiereDto totale = new TimbratureGroupByInfermiereDto();
                    totale.IdInfermiere = nec.IdInfermiere;
                    totale.Struttura = "";
                    totale.Data = "";
                    totale.Da = "";
                    totale.A = "";
                    timbratureInfermiere.PeriodoLavoratoDa = "";
                    timbratureInfermiere.PeriodoLavoratoA = "";
                    totale.Infermiere = "Totale ore " + totalHours.ToString();
                    totale.TotaleOre = totalHours;
                    totale.Id = Id;
                    Id++;

                    timbratureGroupByInfermiereDto.Add(totale);
                }

                indice++;
            }


            this.Timbrature.push(timbratureGroupByInfermiereDto.ToArray());
        }
    }
}
