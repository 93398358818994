using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class NightNecessitaGiornoDto
    {
        [JsonProperty("strutturaId")]
        public int StrutturaId { get; set; }
        
        public NecessitaGiornoDto Turno1 { get; set; }
        public NecessitaGiornoDto Turno2 { get; set; }
        
        [JsonProperty("error")]
        public string Error { get; set; }
public string Url
{
    get
    {
        return string.Format("#struttureEdit?strutturaId={0}",StrutturaId);
    }
}public bool IsValid
{
    get
    {
        return string.IsNullOrEmpty(Error);
    }
}    }
}