using Aip.Florence.FrontEnd.SpafApp.Dto;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.KnockoutModels
{
    public class NecessitaGiornoKo
    {
        public Retyped.knockout.KnockoutObservable <int>IdTurnazioneMensile { get; set; }
        public Retyped.knockout.KnockoutObservable <int>IdInfermiere { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Errore { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>VisualizzaErrore { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Data { get; set; }
        public Retyped.knockout.KnockoutObservable <string>PeriodoDa { get; set; }
        public Retyped.knockout.KnockoutObservable <string>PeriodoA { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Causale { get; set; }
        public Retyped.knockout.KnockoutObservable <int>IdNecessita { get; set; }

        public NecessitaGiornoKo(NecessitaGiornoDto dto)
        {
            IdTurnazioneMensile = Retyped.knockout.ko.observable.Self<int>(dto == null ? 0 : dto.IdTurnazioneMensile);
            IdInfermiere = Retyped.knockout.ko.observable.Self<int>(dto == null ? 0 : dto.IdInfermiere);
            Errore = Retyped.knockout.ko.observable.Self<string>("");
            VisualizzaErrore = Retyped.knockout.ko.observable.Self<bool>(false);
            Data = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : dto.Data.ToString("yyyy-MM-dd"));
            PeriodoDa = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : (string)dto.PeriodoDa);
            PeriodoA = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : (string)dto.PeriodoA);
            Causale = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : (string)dto.Causale);
            IdNecessita = Retyped.knockout.ko.observable.Self<int>(dto == null ? 0 : dto.IdNecessita);
        }

        public void Refresh(NecessitaGiornoDto dto, string errore)
        {
            if (dto == null) return;

            Errore.Self(errore);
            if (errore != "")
                VisualizzaErrore.Self(true);
            else
                VisualizzaErrore.Self(false);
            IdTurnazioneMensile.Self(dto.IdTurnazioneMensile);
            IdInfermiere.Self(dto.IdInfermiere);
            Data.Self(dto.Data.ToString("yyyy-MM-dd"));
            PeriodoDa.Self((string)dto.PeriodoDa);
            PeriodoA.Self((string)dto.PeriodoA);
            Causale.Self((string)dto.Causale);
            IdNecessita.Self(dto.IdNecessita);
        }
    }
}
