using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture;
using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using Bridge.Messenger;
using Bridge.Navigation;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.PeriodoPrototipo
{
    class CausaleEqualityComparer : IEqualityComparer<TipiTurniDto>
    {
        #region IEqualityComparer<Causale> Members

        public bool Equals(TipiTurniDto x, TipiTurniDto y)
        {
            return x.Causale.Equals(y.Causale);
        }

        public int GetHashCode(TipiTurniDto obj)
        {
            return obj.Causale.GetHashCode();
        }

        #endregion
    }

    class GiornoEqualityComparer : IEqualityComparer<TipiTurniDto>
    {
        #region IEqualityComparer<Giorno> Members

        public bool Equals(TipiTurniDto x, TipiTurniDto y)
        {
            return x.GiornoId.Equals(y.GiornoId);
        }

        public int GetHashCode(TipiTurniDto obj)
        {
            return obj.GiornoId.GetHashCode();
        }

        #endregion
    }

    public class PeriodoPrototipoViewModel : BlockableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IPeriodoPrototipoService _prototipoPeriodoService;
        private readonly ICalendariService _calendariService;
        private readonly IMessenger _messenger;
        private readonly ICookieService _cookieService;
        private readonly IInfermieriService _infermieriService;
        private readonly IStruttureService _struttureService;
        private readonly ITipiTurniService _tipiTurniService;
        private readonly IGiorniService _giorniService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.PeriodoPrototipoId;
}
        public int StrutturaId { get; set; }
        public int PeriodoPrototipoId { get; set; }
        public int InfermiereId { get; set; }
        public bool InfermiereObbligatorio { get; set; }
        
        public Retyped.knockout.KnockoutObservable <int>MeseCorrente { get; set; }
        public Retyped.knockout.KnockoutObservable <int>AnnoCorrente { get; set; }

        public Retyped.knockout.KnockoutObservable <bool>FirstLoadPageLoader { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>TableHasValue { get; set; }
        public Retyped.knockout.KnockoutObservable <int>SettimanaId { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>ListaGiorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniPerCalendarioPeriodoPrototipoDto>Turni { get; set; }
        public AssociaInfermiereViewModel associaInfermiereViewModel { get; set; }

        public PeriodoPrototipoViewModel(INavigator navigator, IPeriodoPrototipoService prototipoPeriodoService, 
            ICalendariService calendariService, IStruttureService struttureService, ITipiTurniService tipiTurniService, 
            IGiorniService giorniService, IMessenger messenger, ICookieService cookieService, IInfermieriService infermieriService)
        {
            _navigator = navigator;
            _prototipoPeriodoService = prototipoPeriodoService;
            _calendariService = calendariService;
            _struttureService = struttureService;
            _tipiTurniService = tipiTurniService;
            _giorniService = giorniService;
            _messenger = messenger;
            _cookieService = cookieService;
            this._infermieriService = infermieriService;

            FirstLoadPageLoader = Retyped.knockout.ko.observable.Self<bool>(true);
            SettimanaId = Retyped.knockout.ko.observable.Self<int>(0);
            TableHasValue = Retyped.knockout.ko.observable.Self<bool>(false);

            ListaGiorni = Retyped.knockout.ko.observableArray.Self<TipiTurniDto>();
            Turni = Retyped.knockout.ko.observableArray.Self<TipiTurniPerCalendarioPeriodoPrototipoDto>();
        }

        #region OnLoad
        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            StrutturaId = parameters.GetParameter<int>("strutturaId");

            if (StrutturaId != 0)
            {
                await HydratePeriodoPrototipo();
            }
            base.OnLoad(parameters);
        }
        #endregion

        #region HydratePeriodoPrototipo
        public async Task HydratePeriodoPrototipo()
        {
            FirstLoadPageLoader.Self(true);

            try
            {
                var idAnno = DateTime.Now.Year;
                var idMese = DateTime.Now.Month;

                var defaultDate = _cookieService.GetDefaultDate();
                if (defaultDate.HasValue)
                {
                    idAnno = defaultDate.Value.Year;
                    idMese = defaultDate.Value.Month;
                }

               
                this.ListaGiorni.removeAll();
                this.Turni.removeAll();
                TableHasValue.Self(false);

                var prototipoTask = await _prototipoPeriodoService.GetPeriodoPrototipoByStructureId(StrutturaId);
                this.PeriodoPrototipoId = prototipoTask.Giorni[0].PeriodoPrototipoId;
                
                Console.WriteLine(prototipoTask.Giorni.Count);
                var calendarioPeriodoPrototipoTask = await _calendariService.GetDashboardPeriodoPrototipoPerStruttura(StrutturaId, idAnno, idMese);

//                await Task.WhenAll(prototipoTask, calendarioPeriodoPrototipoTask);

                TipiTurniDto[] tipiTurniGiorno = new TipiTurniDto[System.Linq.Enumerable.Count<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(calendarioPeriodoPrototipoTask)];

                IEnumerable<TipiTurniDto> listaCausali = System.Linq.Enumerable.Distinct<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(calendarioPeriodoPrototipoTask,new CausaleEqualityComparer());
                IEnumerable<TipiTurniDto> listaGiorni = System.Linq.Enumerable.Distinct<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(calendarioPeriodoPrototipoTask,new GiornoEqualityComparer());
                var infermieriOrdinati = await _struttureService.GetOrderedAssociatedInfermieriForStruttura(this.StrutturaId, 1);

                List<TipiTurniDto> listaGiorniOrdereded = new List<TipiTurniDto>();

                int settimane = 0;

                foreach (TipiTurniDto turno in listaGiorni)
                {
                    if (turno.GiornoDellaSettimana == 0 || turno.GiornoDellaSettimana == 7 || turno.GiornoDellaSettimana == 14 || turno.GiornoDellaSettimana == 21)
                        turno.GiornoSettimana = "DOM";
                    else if (turno.GiornoDellaSettimana == 1 || turno.GiornoDellaSettimana == 8 || turno.GiornoDellaSettimana == 15 || turno.GiornoDellaSettimana == 22)
                        turno.GiornoSettimana = "LUN";
                    else if (turno.GiornoDellaSettimana == 2 || turno.GiornoDellaSettimana == 9 || turno.GiornoDellaSettimana == 16 || turno.GiornoDellaSettimana == 23)
                        turno.GiornoSettimana = "MAR";
                    else if (turno.GiornoDellaSettimana == 3 || turno.GiornoDellaSettimana == 10 || turno.GiornoDellaSettimana == 17 || turno.GiornoDellaSettimana == 24)
                        turno.GiornoSettimana = "MER";
                    else if (turno.GiornoDellaSettimana == 4 || turno.GiornoDellaSettimana == 11 || turno.GiornoDellaSettimana == 18 || turno.GiornoDellaSettimana == 25)
                        turno.GiornoSettimana = "GIO";
                    else if (turno.GiornoDellaSettimana == 5 || turno.GiornoDellaSettimana == 12 || turno.GiornoDellaSettimana == 19 || turno.GiornoDellaSettimana == 26)
                        turno.GiornoSettimana = "VEN";
                    else if (turno.GiornoDellaSettimana == 6 || turno.GiornoDellaSettimana == 13 || turno.GiornoDellaSettimana == 20 || turno.GiornoDellaSettimana == 27)
                        turno.GiornoSettimana = "SAB";

                    if (turno.GiornoDellaSettimana > -1)
                    {
                        listaGiorniOrdereded.Add(turno);
                        //if (turno.GiornoDellaSettimana == 6)
                        //{
                        //    TipiTurniDto tipoTurnoDom = listaGiorni.Where(x => x.GiornoDellaSettimana == 0 && x.GiornoId < turno.GiornoId).OrderByDescending(x => x.GiornoId).FirstOrDefault();
                        //    listaGiorniOrdereded.Add(tipoTurnoDom);
                        //}
                    }

                    if (Convert.ToInt32(turno.SettimanaId) > settimane)
                        settimane = Convert.ToInt32(turno.SettimanaId);
                }


                this.SettimanaId.Self(settimane);

                TipiTurniPerCalendarioPeriodoPrototipoDto[] tipiTurni = new TipiTurniPerCalendarioPeriodoPrototipoDto[System.Linq.Enumerable.Count<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(listaCausali)];

                int indice = 0;
                foreach (TipiTurniDto causale in System.Linq.Enumerable.OrderBy<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto,string>(listaCausali,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto, string>)(x=>x.Causale)))
                {
                    CrossTipiTurnoPerCalendarioPeriodoPrototipoDto[] cross = null;

                    IEnumerable<TipiTurniDto> turniFiltarti = System.Linq.Enumerable.OrderBy<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto,int?>(calendarioPeriodoPrototipoTask,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto, int?>)(x => x.GiornoId)).Where((global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto, bool>)(z => z.Causale == causale.Causale));

                    cross = new CrossTipiTurnoPerCalendarioPeriodoPrototipoDto[System.Linq.Enumerable.Count<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(turniFiltarti)];
                    int indiceCross = 0;
                    foreach (TipiTurniDto tipoTurno in System.Linq.Enumerable.OrderBy<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto,int?>(turniFiltarti,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto, int?>)(x=>x.GiornoOrder)))
                    {
                        var infermiere = String.Empty;
                        var obbligatorio = String.Empty;

                        if (tipoTurno.InfermiereId != null)
                        {
                            var infermiereIstance = System.Linq.Enumerable.SingleOrDefault<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>(infermieriOrdinati,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto, bool>)(x => x.Id == tipoTurno.InfermiereId));
                            if (infermiereIstance != null)
                            {
                                infermiere = infermiereIstance.NomeCompleto;
                            }
                            else
                            {
                                var InfermieriDto =  await _infermieriService.Infermiere(tipoTurno.InfermiereId.Value);
                                infermiere = string.Format("Infermiere Cancellato: '{0}'",tipoTurno.InfermiereId);
                                if (InfermieriDto != null)
                                {
                                    infermiere += string.Format(" {0}",InfermieriDto.NomeCompleto);
                                }
                            }
                        }

                        if (tipoTurno.InfermiereObbligatorio)
                            obbligatorio = "(Obbligatorio)";

                        //if (tipoTurno.GiornoDellaSettimana == 0)
                        //    cross[indiceCross + 6] = new CrossTipiTurnoPerCalendarioPeriodoPrototipoDto(tipoTurno.Id, tipoTurno.SettimanaId, tipoTurno.GiornoId,
                        //                                                                        tipoTurno.InfermiereId, tipoTurno.Causale, tipoTurno.Periodo, tipoTurno.InfermiereObbligatorio, infermiere, obbligatorio);
                        //else
                        //    cross[indiceCross - 1] = new CrossTipiTurnoPerCalendarioPeriodoPrototipoDto(tipoTurno.Id, tipoTurno.SettimanaId, tipoTurno.GiornoId,
                        //                                                                        tipoTurno.InfermiereId, tipoTurno.Causale, tipoTurno.Periodo, tipoTurno.InfermiereObbligatorio, infermiere, obbligatorio);

                        cross[indiceCross] = new CrossTipiTurnoPerCalendarioPeriodoPrototipoDto(tipoTurno.Id, tipoTurno.SettimanaId, tipoTurno.GiornoId,
                                                                                                    tipoTurno.InfermiereId, tipoTurno.Causale, tipoTurno.Periodo, tipoTurno.InfermiereObbligatorio, infermiere, obbligatorio);
                        indiceCross++;
                    }
                    TipiTurniPerCalendarioPeriodoPrototipoDto tipiTurniPerCalendarioPeriodoPrototipoDto = new TipiTurniPerCalendarioPeriodoPrototipoDto(causale.Causale, cross);

                    tipiTurni[indice] = tipiTurniPerCalendarioPeriodoPrototipoDto;

                    foreach (var turno in tipiTurni[indice].CrossTipiTurni)
                    {
                        if (turno.Id > 0)
                        {
                            tipiTurni[indice].HasCrossTipiTurni = true;
                            TableHasValue.Self(true);
                            break;
                        }
                        tipiTurni[indice].HasCrossTipiTurni = false;
                    }

                    indice++;
                }

                this.ListaGiorni.push(listaGiorniOrdereded.ToArray());

                this.Turni.push(tipiTurni);
                
                Console.WriteLine(ListaGiorni);
                Console.WriteLine(Turni);
            }
            catch (Exception ex)
            {
                string s = ex.Message;
                Console.WriteLine("Errore: " + ex.Message);
                Console.WriteLine("Stack: " + ex.StackTrace);
            }

            FirstLoadPageLoader.Self(false);
        }
        #endregion

        #region CambiaInfermiereStruttura
        public async void CambiaInfermiereStruttura(int id, int idInfermiere, int alert)
        {
            this.InfermiereId = idInfermiere;
        }
        #endregion

        #region AggiungiSettimana
        public void AggiungiSettimana()
        {
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma inserimento nuova settimana",
                Closable = false,
                Message = "Confermi l'inserimento della nuova settimana?",
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            Block();
                            try {
                                await _giorniService.AggiungiSettimana(this.StrutturaId, this.PeriodoPrototipoId);
                                await HydratePeriodoPrototipo();
                                e.ToDynamic().close();
                            }
                            catch(Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di creazione nuova settimana");
                            }
                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion
        
        #region RimuoviUltimaSettimana
        public void RimuoviUltimaSettimana()
        {
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma rimozione ultima settimana",
                Closable = false,
                Message = "Confermi la rimozione dell'ultima settimana?",
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            Block();
                            try {
                                await _giorniService.RimuoviSettimana(this.StrutturaId, this.PeriodoPrototipoId);
                                await HydratePeriodoPrototipo();
                                e.ToDynamic().close();
                            }
                            catch(Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di rimozione ultima settimana");
                            }
                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region EditStruttura
        public void EditStruttura()
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",this.StrutturaId);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.EditStruttureId, parameters);
        }
        #endregion
        
        #region SetInfermierePeriodoPrototipo
        private void SetInfermierePeriodoPrototipo(int idInfermiere)
        {
            jQuery.Select("#Errore").Text("").Trigger("change");

            var infermiereId = "#Infermiere";
            jQuery.Select(infermiereId).Val(idInfermiere.ToString()).Trigger("change");
        }
        #endregion

        #region Edit
        public async void Edit(CrossTipiTurnoPerCalendarioPeriodoPrototipoDto tipoTurno, string day)
        {
            string titolo = "";
            string titoloBottone = "Conferma";

            if (tipoTurno.Id == 0)
            {
                titoloBottone = "Inserisci";
                titolo = "Conferma nuovo tipo turno";
            }
            else
                titolo = "Modifica tipo turno";
            
            var modalGialogHTML = " <form class=\"form-horizontal\" role=\"form\"> " +
                                  " <div class=\"form-group\"> <div class=\"col-md-12\"><span id=\"Errore\" style=\"background-color: red \" class=\"floating-label\"></span></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Casuale\">Casuale</label> <span type=\"text\" id=\"Casuale\" >" + tipoTurno.Causale + "</span> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoDa\">Periodo inizio</label> <span>" + tipoTurno.Periodo.Da + "</span></div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoA\">Periodo fine</label> <span>" + tipoTurno.Periodo.A + "</span> </div> " +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Infermiere\">Infermiere </label>  ";

            var infermieriOrdinati = _struttureService.GetOrderedAssociatedInfermieriForStruttura(this.StrutturaId, 1);
            await Task.WhenAll<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto[]>(infermieriOrdinati);

            if (System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>(infermieriOrdinati.Result))
            {
                associaInfermiereViewModel = Bridge.Spaf.SpafApp.Container.Resolve<AssociaInfermiereViewModel>();

                modalGialogHTML += " <select id =\"Infermiere\" class=\"form-control\" style=\"width: 60%; background-color: #FFFFFF;\" \">";

                foreach (InfermieriDto w in infermieriOrdinati.Result)
                {
                    modalGialogHTML += "<option value=\"" + w.Id + "\">" + w.NomeCompleto + "</option>";
                }

                modalGialogHTML += "</select>";

            }

            if (tipoTurno.InfermiereId != null)
                this.InfermiereId = Convert.ToInt32(tipoTurno.InfermiereId);
            else
                this.InfermiereId = 0;

            modalGialogHTML += " </div> " + " <div class=\"form-check\"> ";

            if (tipoTurno.InfermiereObbligatorio)
            {
                this.InfermiereObbligatorio = true;
                modalGialogHTML += " <input type=\"checkbox\" class=\"form-check-input\" id=\"infermiereObbligatorio\" checked=\"checked\"> ";
            }
            else
            {
                this.InfermiereObbligatorio = false;
                modalGialogHTML += " <input type=\"checkbox\" class=\"form-check-input\" id=\"infermiereObbligatorio\"> ";
            }

            modalGialogHTML += " <label class=\"form-check-label\" for=\"infermiereObbligatorio\">Infermiere obbligatorio</label></div> " +
                               " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"TipoTurnoId\">TipoTurnoId</label> <span>" + tipoTurno.Id + "</span> </div> " +
                               " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"GiornoId\">GiornoId</label> <span>" + tipoTurno.GiornoId + "</span> </div> " +
                               " <div class=\"clearfix\"></div></form> ";

            if (tipoTurno.Id == 0)
            {
                BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                {
                    Title = titolo,
                    Closable = false,
                    Message = modalGialogHTML,
                    OnShown = () =>
                    {
                        SetInfermierePeriodoPrototipo(Convert.ToInt32(tipoTurno.InfermiereId));
                        Select2.Render((global::System.Action<object>)(o =>
                        {
                            if (jQuery.Select("#Infermiere").Val() == "")
                                this.InfermiereId = 0;
                            else
                                this.InfermiereId = Convert.ToInt32(jQuery.Select("#Infermiere").Val());
                        }), "#Infermiere");
                        Script.Write("$(\".bootstrap-dialog\").removeAttr(\"tabindex\");");

                        CheckInfermiereObbligatorio();

                    },
                    Buttons = new BootstrapDialog.BootstrapDialogButton[]
                    {
                        //chiudi
                        new BootstrapDialog.BootstrapDialogButton()
                        {
                            Label = "Chiudi",
                            Action = (self) => { self.ToDynamic().close(); }
                        },
                        //inserisci
                        new BootstrapDialog.BootstrapDialogButton()
                        {
                            Label = titoloBottone,
                            Action = async(o) => {
                                Block();
                                try {
                                    CheckInfermiereObbligatorio();

                                    TipiTurniDto tipiTurniDto = new TipiTurniDto();
                                    tipiTurniDto.SettimanaId = tipoTurno.SettimanaId;
                                    tipiTurniDto.GiornoId = tipoTurno.GiornoId;
                                    tipiTurniDto.StrutturaId = this.StrutturaId;
                                    if (this.InfermiereId.ToString() == "0")
                                    {
                                        tipiTurniDto.InfermiereId = null;
                                        this.InfermiereObbligatorio = false;
                                    }
                                    else
                                        tipiTurniDto.InfermiereId = this.InfermiereId;
                                    
                                    tipiTurniDto.Causale = tipoTurno.Causale;
                                    tipiTurniDto.Periodo = tipoTurno.Periodo;
                                    tipiTurniDto.InfermiereObbligatorio = this.InfermiereObbligatorio;
                                    tipiTurniDto.Id = tipoTurno.Id;

                                    if (tipoTurno.Id == 0)
                                        await _tipiTurniService.CreateTipiTurni(this.StrutturaId, tipiTurniDto);
                                    else
                                        await _tipiTurniService.EditTipiTurni(this.StrutturaId, tipiTurniDto);

                                    await HydratePeriodoPrototipo();

                                    o.ToDynamic().close();
                                }
                                catch(Exception e)
                                {
                                    jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di gestione tipo turno").Trigger("change");
                                    //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di gestione tipo turno");
                                    Console.WriteLine(e);
                                }
                                UnBlock();
                            }
                        }
                    }
                });
            }
            else
            {
                BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                {
                    Title = titolo,
                    Closable = false,
                    Message = modalGialogHTML,
                    OnShown = () =>
                    {
                        SetInfermierePeriodoPrototipo(Convert.ToInt32(tipoTurno.InfermiereId));
                        Select2.Render((global::System.Action<object>)(o =>
                        {
                            if (jQuery.Select("#Infermiere").Val() == "")
                                this.InfermiereId = 0;
                            else
                                this.InfermiereId = Convert.ToInt32(jQuery.Select("#Infermiere").Val());
                        }), "#Infermiere");
                        Script.Write("$(\".bootstrap-dialog\").removeAttr(\"tabindex\");");

                        CheckInfermiereObbligatorio();

                    },
                    Buttons = EditButtons(tipoTurno),
                });
            }
        }
        #endregion

        #region TranslateTipiTurno
        public void TranslateTipiTurno(int value, string causale)
        {
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma traslazione TipiTurno",
                Closable = false,
                Message = string.Format("Confermi la traslazione di tutti i TipiTurno con causale {0}?",causale),
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            Block();
                            try {
                                await _prototipoPeriodoService.TranslateTipiTurno(value, StrutturaId, PeriodoPrototipoId, causale);
                                await HydratePeriodoPrototipo();
                                e.ToDynamic().close();
                            }
                            catch(Exception ex)
                            {
                                Console.WriteLine(ex.Message);
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di traslazione");
                            }
                            UnBlock();
                        }
                    }
                }
            });
            
        }
        #endregion

        #region DeleteAllTipiTurno
        public void DeleteAllTipiTurno()
        {
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma rimozione TipiTurno",
                Closable = false,
                Message = "Confermi la rimozione di tutti i TipiTurno?",
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            Block();
                            try {
                                await _prototipoPeriodoService.DeleteAllTipiTurno(StrutturaId, PeriodoPrototipoId);
                                await HydratePeriodoPrototipo();
                                e.ToDynamic().close();
                            }
                            catch(Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di eliminazione");
                            }
                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region CreateModalButtons
        private BootstrapDialog.BootstrapDialogButton[] EditButtons(CrossTipiTurnoPerCalendarioPeriodoPrototipoDto tipoTurno)
        {
            BootstrapDialog.BootstrapDialogButton[] buttons;

            if (tipoTurno.InfermiereId.HasValue)
            {
                buttons = new BootstrapDialog.BootstrapDialogButton[]
                        {
                            //chiudi
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Chiudi",
                                Action = (self) => { self.ToDynamic().close(); }
                            },
                            //rimuovi infermiere
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Rimuovi infermiere",
                                Action = async(o) => {
                                    BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                                    {
                                        Title = "Conferma rimozione infermiere",
                                        Closable = false,
                                        Message = "Confermi la rimozione dell'infermiere per il tipo turno selezionato?",
                                        OnShown = () => {
                                        },
                                        Buttons = new BootstrapDialog.BootstrapDialogButton[]
                                        {
                                            new BootstrapDialog.BootstrapDialogButton()
                                            {
                                                Label = "Chiudi",
                                                Action = (self) => { self.ToDynamic().close(); }
                                            },
                                            new BootstrapDialog.BootstrapDialogButton()
                                            {
                                                Label = "Conferma",
                                                Action = async(e) => {
                                                    e.ToDynamic().close();
                                                    Block();
                                                    try {
                                                        await _tipiTurniService.DeleteInfermiere(this.StrutturaId, tipoTurno.Id);

                                                        this.InfermiereObbligatorio = false;

                                                        await HydratePeriodoPrototipo();

                                                        o.ToDynamic().close();
                                                    }
                                                    catch
                                                    {
                                                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di gestione tipo turno").Trigger("change");
                                                        //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di gestione tipo turno");
                                                    }
                                                    UnBlock();
                                                }
                                            }
                                        }
                                    });
                                }
                            },
                            //rimuovi
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Rimuovi",
                                Action = async(o) => {
                                    BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                                    {
                                        Title = "Conferma rimozione tipo turno",
                                        Closable = false,
                                        Message = "Confermi la rimozione per il tipo turno selezionato?",
                                        OnShown = () => {
                                        },
                                        Buttons = new BootstrapDialog.BootstrapDialogButton[]
                                        {
                                            new BootstrapDialog.BootstrapDialogButton()
                                            {
                                                Label = "Chiudi",
                                                Action = (self) => { self.ToDynamic().close(); }
                                            },
                                            new BootstrapDialog.BootstrapDialogButton()
                                            {
                                                Label = "Conferma",
                                                Action = async(e) => {
                                                    e.ToDynamic().close();
                                                    Block();
                                                    try {
                                                        await _tipiTurniService.DeleteTipiTurni(this.StrutturaId, tipoTurno.Id);

                                                        await HydratePeriodoPrototipo();

                                                        o.ToDynamic().close();
                                                    }
                                                    catch
                                                    {
                                                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di gestione tipo turno").Trigger("change");
                                                        //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di gestione tipo turno");
                                                    }
                                                    UnBlock();
                                                }
                                            }
                                        }
                                    });
                                }
                            },
                            //inserisci
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Inserisci",
                                Action = async(o) => {
                                    Block();
                                    try {
                                        CheckInfermiereObbligatorio();

                                        TipiTurniDto tipiTurniDto = new TipiTurniDto();
                                        tipiTurniDto.SettimanaId = tipoTurno.SettimanaId;
                                        tipiTurniDto.GiornoId = tipoTurno.GiornoId;
                                        tipiTurniDto.StrutturaId = this.StrutturaId;
                                        if (this.InfermiereId.ToString() == "0")
                                        {
                                            tipiTurniDto.InfermiereId = null;
                                            this.InfermiereObbligatorio = false;
                                        }
                                        else
                                            tipiTurniDto.InfermiereId = this.InfermiereId;
                                        tipiTurniDto.Causale = tipoTurno.Causale;
                                        tipiTurniDto.Periodo = tipoTurno.Periodo;
                                        tipiTurniDto.InfermiereObbligatorio = this.InfermiereObbligatorio;
                                        tipiTurniDto.Id = tipoTurno.Id;

                                        if (tipoTurno.Id == 0)
                                            await _tipiTurniService.CreateTipiTurni(this.StrutturaId, tipiTurniDto);
                                        else
                                            await _tipiTurniService.EditTipiTurni(this.StrutturaId, tipiTurniDto);

                                        await HydratePeriodoPrototipo();

                                        o.ToDynamic().close();
                                    }
                                    catch
                                    {
                                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di gestione tipo turno").Trigger("change");
                                        //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di gestione tipo turno");
                                    }
                                    UnBlock();
                                }
                            }
                        };
            }
            else
            {
                buttons = new BootstrapDialog.BootstrapDialogButton[]
                        {
                            //chiudi
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Chiudi",
                                Action = (self) => { self.ToDynamic().close(); }
                            },
                            //rimuovi
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Rimuovi",
                                Action = async(o) => {
                                    BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                                    {
                                        Title = "Conferma rimozione tipo turno",
                                        Closable = false,
                                        Message = "Confermi la rimozione per il tipo turno selezionato?",
                                        OnShown = () => {},
                                        Buttons = new BootstrapDialog.BootstrapDialogButton[]
                                        {
                                            new BootstrapDialog.BootstrapDialogButton()
                                            {
                                                Label = "Chiudi",
                                                Action = (self) => { self.ToDynamic().close(); }
                                            },
                                            new BootstrapDialog.BootstrapDialogButton()
                                            {
                                                Label = "Conferma",
                                                Action = async(e) => {
                                                    e.ToDynamic().close();
                                                    Block();
                                                    try {
                                                        await _tipiTurniService.DeleteTipiTurni(this.StrutturaId, tipoTurno.Id);

                                                        await HydratePeriodoPrototipo();

                                                        o.ToDynamic().close();
                                                    }
                                                    catch
                                                    {
                                                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di gestione tipo turno").Trigger("change");
                                                        //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di gestione tipo turno");
                                                    }
                                                    UnBlock();
                                                }
                                            }
                                        }
                                    });
                                }
                            },
                            //inserisci
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Inserisci",
                                Action = async(o) => {
                                    Block();
                                    try {
                                        CheckInfermiereObbligatorio();

                                        TipiTurniDto tipiTurniDto = new TipiTurniDto();
                                        tipiTurniDto.SettimanaId = tipoTurno.SettimanaId;
                                        tipiTurniDto.GiornoId = tipoTurno.GiornoId;
                                        tipiTurniDto.StrutturaId = this.StrutturaId;
                                        if (this.InfermiereId.ToString() == "0")
                                        {
                                            tipiTurniDto.InfermiereId = null;
                                            this.InfermiereObbligatorio = false;
                                        }
                                        else
                                            tipiTurniDto.InfermiereId = this.InfermiereId;
                                        tipiTurniDto.Causale = tipoTurno.Causale;
                                        tipiTurniDto.Periodo = tipoTurno.Periodo;
                                        tipiTurniDto.InfermiereObbligatorio = this.InfermiereObbligatorio;
                                        tipiTurniDto.Id = tipoTurno.Id;

                                        if (tipoTurno.Id == 0)
                                            await _tipiTurniService.CreateTipiTurni(this.StrutturaId, tipiTurniDto);
                                        else
                                            await _tipiTurniService.EditTipiTurni(this.StrutturaId, tipiTurniDto);

                                        await HydratePeriodoPrototipo();

                                        o.ToDynamic().close();
                                    }
                                    catch
                                    {
                                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di gestione tipo turno").Trigger("change");
                                        //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di gestione tipo turno");
                                    }
                                    UnBlock();
                                }
                            }
                        };
            }
            return buttons;
        }
        #endregion

        #region CheckInfermiereObbligatorio
        private void CheckInfermiereObbligatorio()
        {
            if ( ( (HTMLInputElement)jQuery.Select("#infermiereObbligatorio").Get(0) ).Checked )
                this.InfermiereObbligatorio = true;
            else
                this.InfermiereObbligatorio = false;
        }
        #endregion
    }
}
