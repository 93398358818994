using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class CalendariService: ICalendariService
    {
        private readonly ICalendariResource _calendarioResource;

        public CalendariService(ICalendariResource CalendarioResource)
        {
            _calendarioResource = CalendarioResource;
        }

        public Task<CalendariDto[]> GetCalendariPerInfermierePerMese(int idInfermiere, int anno, int mese)
        {
            return _calendarioResource.GetCalendariPerInfermierePerMese(idInfermiere, anno, mese);
        }

        public Task<CalendariStrutturaDto[]> GetCalendariPerStrutturaPerMese(int idStruttura, int anno, int mese)
        {
            return _calendarioResource.GetCalendariPerStrutturaPerMese(idStruttura, anno, mese);
        }

        public Task<BaseDtoOutputModel<MesiDto>> GetMesi()
        {
            return _calendarioResource.GetMesi();
        }

        public Task<BaseDtoOutputModel<AnniDto>> GetAnni()
        {
            return _calendarioResource.GetAnni();
        }

        public Task<TipiTurniDto[]> GetDashboardPeriodoPrototipoPerStruttura(int idStruttura, int anno, int mese)
        {
            return _calendarioResource.GetDashboardPeriodoPrototipoPerStruttura(idStruttura, anno, mese);
        }
    }
}
