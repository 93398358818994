using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class FascieOrarieGiorniDto
    {
        [JsonProperty("fasciaoraria")]
        public FasceOrarieDto FasceOrarie { get; set; }

        [JsonProperty("crossgiorni")]
        public CrossFasceOrarieGiorniDto[] CrossGiorni { get; set; }

        public FascieOrarieGiorniDto(FasceOrarieDto fasciaOrariaDto, CrossFasceOrarieGiorniDto[] crossFasciaOrariaGiornoDto)
        {
            FasceOrarie = fasciaOrariaDto;
            CrossGiorni = crossFasciaOrariaGiornoDto;
        }
    }
}
