using Aip.Florence.FrontEnd.SpafApp.Classes;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.IndisponibilitaInfermieri;
using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;
using Bridge.Messenger;
using Bridge.Navigation;
using Retyped;
using System;
using System.Collections.Generic;
using System.Globalization;
using System.Linq;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Infermieri
{
    public class CreateEditInfermiereViewModel : BlockableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IInfermieriResource _infermieriResource;
        private readonly IInfermieriService _infermieriService;
        private readonly IGiorniService _giornoService;
        private readonly IFasceOrarieService _fasceOrarieService;
        private readonly IIndisponibilitaService _indisponibilitaService;
        private readonly ICalendariService _calendariService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        private readonly ICookieService _cookieService;
        private readonly IMessenger _messenger;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.EditCreateInfermieriId;
}
        public int EditId { get; set; }
        public InfermiereKo infermiereKo { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.GiorniDto>Giorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FasceOrarieDto>FasceOrarie { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FascieOrarieGiorniPerCalendarioDto>FasceOrarieGiorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariDto>CalendarioInfermiere { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.MesiDto>Mesi { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.AnniDto>Anni { get; set; }

        public Retyped.knockout.KnockoutObservableArray <int>Indisponibilita { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>Infermieri { get; set; }

        public Retyped.knockout.KnockoutObservable <int>MeseCorrente { get; set; }
        public Retyped.knockout.KnockoutObservable <int>AnnoCorrente { get; set; }

        public int AnnoSelezionato { get; set; }
        public int MeseSelezionato { get; set; }

        public Retyped.knockout.KnockoutObservable <bool>FirstLoadPageLoader { get; set; }

        public CreateEditInfermiereViewModel(INavigator navigator, IInfermieriResource infermieriResource,
                                             IInfermieriService infermieriService, IGiorniService giornoService,
                                             IFasceOrarieService fasciaOrariaService, IIndisponibilitaService indisponibilitaService,
                                             ICalendariService calendariService, INecessitaGiorniService necessitaGiorniService, IMessenger messenger,
                                             ICookieService cookieService)
        {
            _navigator = navigator;
            _infermieriResource = infermieriResource;
            _infermieriService = infermieriService;
            _fasceOrarieService = fasciaOrariaService;
            _giornoService = giornoService;
            _indisponibilitaService = indisponibilitaService;
            _calendariService = calendariService;
            _necessitaGiorniService = necessitaGiorniService;
            _cookieService = cookieService;
            _messenger = messenger;
            infermiereKo = new InfermiereKo(null);

            Giorni = Retyped.knockout.ko.observableArray.Self<GiorniDto>();
            FasceOrarie = Retyped.knockout.ko.observableArray.Self<FasceOrarieDto>();
            FasceOrarieGiorni = Retyped.knockout.ko.observableArray.Self<FascieOrarieGiorniPerCalendarioDto>();
            CalendarioInfermiere = Retyped.knockout.ko.observableArray.Self<CalendariDto>();

            Mesi = Retyped.knockout.ko.observableArray.Self<MesiDto>();
            Anni = Retyped.knockout.ko.observableArray.Self<AnniDto>();

            Indisponibilita = Retyped.knockout.ko.observableArray.Self<int>();

            MeseCorrente = Retyped.knockout.ko.observable.Self<int>();
            AnnoCorrente = Retyped.knockout.ko.observable.Self<int>();
            this.AnnoSelezionato = AnnoSelezionato;
            this.MeseSelezionato = MeseSelezionato;

            FirstLoadPageLoader = knockout.ko.observable.Self<bool>(true);

        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            Script.Write("$(\".select2\").select2()");

            FirstLoadPageLoader.Self(true);

            this.EditId = parameters.GetParameter<int>("id");

            if (this.EditId != 0)
            {
                var infermiereDto = await this._infermieriResource.GetInfermiereById(this.EditId);
                infermiereKo = new InfermiereKo(infermiereDto);
            }

            var mesi = _indisponibilitaService.GetMesi();
            var anni = _indisponibilitaService.GetAnni();
            await Task.WhenAll(mesi, anni);

            this.Mesi.push(mesi.Result.Items);
            this.Anni.push(anni.Result.Items);

            var idAnno = DateTime.Now.Year;
            var idMese = DateTime.Now.Month;

            var defaultDate = _cookieService.GetDefaultDate();
            if (defaultDate.HasValue)
            {
                idAnno = defaultDate.Value.Year;
                idMese = defaultDate.Value.Month;
            }

            await LoadCalendario(idAnno, idMese, this.EditId);
            base.OnLoad(parameters);

            FirstLoadPageLoader.Self(false);

            // gestione anno/mese
            Select2.Render((global::System.Action<object>)(async o =>
            {
                await CambiaAnnoMeseInfermiere(AnnoCorrente.Self(), MeseCorrente.Self());


            }), "#mese");

            Select2.Render((global::System.Action<object>)(async o =>
            {
                await CambiaAnnoMeseInfermiere(AnnoCorrente.Self(), MeseCorrente.Self());

            }), "#anno");

            Script.Call("RefreshTableSaw");
        }

        public override void OnLeave()
        {
            Script.Call("RemoveDateTimePickerDettaglioIndisponibilita");

            base.OnLeave();
        }

        #region VaiAMeseSuccessivo
        public async void VaiAMeseSuccessivo()
        {
            var actualMonth = this.MeseCorrente.Self();
            if (actualMonth == 12)
            {
                actualMonth = 1;
                var actualYear = this.AnnoCorrente.Self() + 1;
                AnnoCorrente.Self(actualYear);
                MeseCorrente.Self(actualMonth);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth)).Trigger("change");
                jQuery.Select("#anno").Val(Convert.ToString(actualYear)).Trigger("change");
            }
            else
            {
                this.MeseCorrente.Self(actualMonth + 1);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth + 1)).Trigger("change");
            }


            await CambiaAnnoMeseInfermiere(AnnoCorrente.Self(), MeseCorrente.Self());
        }
        #endregion

        #region VaiAMesePrecedente
        public async void VaiAMesePrecedente()
        {
            var actualMonth = this.MeseCorrente.Self();
            if (actualMonth == 1)
            {
                actualMonth = 12;
                var actualYear = this.AnnoCorrente.Self() - 1;
                AnnoCorrente.Self(actualYear);
                MeseCorrente.Self(actualMonth);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth)).Trigger("change");
                jQuery.Select("#anno").Val(Convert.ToString(actualYear)).Trigger("change");
            }
            else
            {
                this.MeseCorrente.Self(actualMonth - 1);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth - 1)).Trigger("change");
            }


            await CambiaAnnoMeseInfermiere(AnnoCorrente.Self(), MeseCorrente.Self());
        }
        #endregion

        public async Task CambiaAnnoMeseInfermiere(int idAnno, int idMese)
        {
            Block();
            Script.Call("DestroyTableSaw");
            await LoadCalendario(idAnno, idMese, this.EditId);
            Script.Call("RefreshTableSaw");
            UnBlock();
        }

        public void ListaIndisponibilita()
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("infermiereId",this.EditId);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.Indisponibilita, parameters);
        }

        public async void EditCreateConfirm()
        {
            try
            {
                var currentInfermiere = new InfermieriDto
                {
                    Id = infermiereKo.InfermiereId.Self(),
                    Nome = infermiereKo.Nome.Self(),
                    Cognome = infermiereKo.Cognome.Self(),
                    MexalId = infermiereKo.MexalId.Self()
                };

                if (currentInfermiere.Id == 0)
                    await _infermieriResource.CreateNewInfermiere(currentInfermiere);
                else
                    await _infermieriResource.EditInfermiere(currentInfermiere);

                _navigator.Navigate(Bridge.Spaf.SpafApp.InfermieriId);
            }
            catch (PromiseException e)
            {
                Global.Alert(e.GetErrorMessage());
            }
        }

        private async Task LoadCalendario(int idAnno, int idMese, int idInfermiere)
        {
            //this.AnnoCorrente.removeAll();
            //this.MeseCorrente.removeAll();
            this.CalendarioInfermiere.removeAll();
            this.Giorni.removeAll();
            this.FasceOrarie.removeAll();
            this.FasceOrarieGiorni.removeAll();

            var giorni = _giornoService.GetAllGiorni(0, idAnno, idMese);
            var fasce = _fasceOrarieService.GetAllFasceOrarie();
            var calendarioInfermiere = _calendariService.GetCalendariPerInfermierePerMese(idInfermiere, idAnno, idMese);

            await Task.WhenAll(fasce, giorni, calendarioInfermiere);

            CalendariDto[] calendari = calendarioInfermiere.Result;

            try
            {
                foreach (FasceOrarieDto fascia in fasce.Result.Items)
                {
                    CrossFasceOrarieGiorniPerCalendarioDto[] listaGiorni = new CrossFasceOrarieGiorniPerCalendarioDto[giorni.Result.Length];
                    int index = 0;
                    foreach (GiorniDto giorno in giorni.Result)
                    {
                        CrossFasceOrarieGiorniPerCalendarioDto crossFasciaGiorno = null;

                        CalendariDto item = System.Array.Find<global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariDto>(calendari, (global::System.Predicate<global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariDto>)(p => p.Giorno == giorno.Giorno && p.FasciaOraria == fascia.Fascia));

                        if (item != null)
                        {
                            if (Convert.ToInt32(item.Stato) == 1)
                                crossFasciaGiorno = new CrossFasceOrarieGiorniPerCalendarioDto(item.IdIndisponibilita, item.PeriodoDa, item.PeriodoA, item.Note, idInfermiere, "", giorno.Id, "", "", fascia.Id, Convert.ToInt32(item.Stato));
                            else
                                crossFasciaGiorno = new CrossFasceOrarieGiorniPerCalendarioDto(0, item.PeriodoDa, item.PeriodoA, item.Note, idInfermiere, "", giorno.Id, item.Struttura, item.Causale, fascia.Id, Convert.ToInt32(item.Stato));
                        }
                        else
                            crossFasciaGiorno = new CrossFasceOrarieGiorniPerCalendarioDto(0, "", "", "", idInfermiere, "", giorno.Id, "", "", fascia.Id, 0);

                        listaGiorni[index] = crossFasciaGiorno;
                        index++;


                    }
                    FascieOrarieGiorniPerCalendarioDto fasceOrarieGiorniDto = new FascieOrarieGiorniPerCalendarioDto(fascia, listaGiorni);
                    this.FasceOrarieGiorni.push(fasceOrarieGiorniDto);
                }
            }
            catch (Exception ex)
            {
                Console.WriteLine("Error: " + ex.Message);
            }

            this.FasceOrarie.push(fasce.Result.Items);
            this.AnnoCorrente.Self(Convert.ToInt32(idAnno));
            this.MeseCorrente.Self(Convert.ToInt32(idMese));
            this.Indisponibilita.push(1);
            this.Giorni.push(giorni.Result);
        }

        /// <summary>
        /// Definizione di una nuova indisponibilità in caso di allocazione già esistente
        /// </summary>
        /// <param name="indisponibilitaViewModel"></param>
        /// <param name="indisponibilitaDto"></param>
        /// <param name="necessitaGiorno"></param>
        /// <param name="dateTime"></param>
        /// <param name="idInfermiere"></param>
        /// <param name="o"></param>
        /// <returns></returns>
        public async Task<bool> InserimentoAllocazioneEsistente(IndisponibilitaViewModel indisponibilitaViewModel, IndisponibilitaDto indisponibilitaDto, 
                                                                NecessitaGiornoDto[] necessitaGiorno, 
                                                                DateTime dateTime, int idInfermiere, object o)
        {
            bool result = true;

            string necessita = "ATTENZIONE! Nel periodo indicato esiste già una allocazione presso le strutture: ";
            int[] idsNecessita = new int[necessitaGiorno.Length];
            for (int i = 0; i < necessitaGiorno.Length; i++)
            {
                idsNecessita[i] = necessitaGiorno[i].IdNecessita;
                necessita += necessitaGiorno[i].Struttura + "; \n";
            }
            necessita += "\nSe si procede la allocazione verrà eliminata. Confermi?";

            //var idNecessita = necessitaGiorno[0].IdNecessita;
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma nuova indisponibilità",
                Closable = false,
                Message = necessita,
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            e.ToDynamic().close();
                            try
                            {
                                await _indisponibilitaService.CreateNewIndisponibilita(indisponibilitaDto);

                                Script.Call("DestroyTableSaw");
                                await LoadCalendario(dateTime.Year, dateTime.Month, idInfermiere);
                                Script.Call("RefreshTableSaw");
                                o.ToDynamic().close();
                            }
                            catch
                            {
                                result = false;
                                indisponibilitaViewModel.IndisponibilitaKo.Errore.Self("ATTENZIONE! Errore in fase di inserimento della indisponibilità");
                                //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di inserimento della indisponibilità");
                            }
                            UnBlock();
                        }
                    }
                }
            });

            return result;
        }
        
        /// <summary>
        /// Creazione di una nuova indisponibilità
        /// </summary>
        public void NuovaIndisponibilitaDialog()
        {
            int idInfermiere = 0;
            var modalGialogHTML = "<form class=\"form-horizontal\" role=\"form\"> " +
                                  " <div class=\"form-group\"> <div class=\"col-md-12\"><span id=\"Errore\" data-bind=\"text: IndisponibilitaKo.Errore;\" style=\"background-color: red \" class=\"floating-label\"></span></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Data\">Data<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\"><input type=\"text\" id=\"Data\" data-bind=\"textInput: IndisponibilitaKo.Data\" class=\"dateTimePicker form-control floating-label\"></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoDa\">Da<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\"><input type=\"text\" id=\"PeriodoDa\" data-bind=\"textInput: IndisponibilitaKo.PeriodoDa\" class=\"form-control floating-label\" maxLength=\"5\" placeholder=\"__:__\"></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoA\">A<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\"><input type=\"text\" id=\"PeriodoA\" class=\"form-control floating-label\" data-bind=\"textInput: IndisponibilitaKo.PeriodoA\" maxLength=\"5\" placeholder=\"__:__\"></div> </div> " +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Note\">Note</label> <div class=\"col-md-8\"><textarea id=\"Note\" class=\"form-control floating-label\" data-bind=\"textInput: IndisponibilitaKo.Note\" style=\"height: 150px; width:400px\"></textarea></div> </div> " +
                                  " <div class=\"clearfix\"></div>" +
                                  "</form>";

            var htmlElement = new HTMLElement();
            htmlElement.InnerHTML = modalGialogHTML;

            IndisponibilitaViewModel indisponibilitaViewModel = null;

            BootstrapDialog.Show(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Nuova indisponibilità",
                Closable = false,
                Message = htmlElement.InnerHTML,
                OnShown = () =>
                {
                    Script.Call("SetNoteIndisponibilitaInfermiere");

                    var pageNode = dom.document.getElementsByClassName("modal-dialog")[0];

                    BootstrapMaterialDatePicker.Render(new BootstrapMaterialDatePickerOption()
                    {
                        Time = false,
                        WeekStart = 0
                    }, ".dateTimePicker");

                    indisponibilitaViewModel = Bridge.Spaf.SpafApp.Container.Resolve<IndisponibilitaViewModel>();                    

                    idInfermiere = this.EditId;
                    indisponibilitaViewModel.InitNuovaIndisponibilita(idInfermiere, this.AnnoCorrente.Self(), this.MeseCorrente.Self(), "", "");

                    Retyped.knockout.ko.applyBindings(indisponibilitaViewModel, pageNode);
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Salva",
                        Action = async(o) => {
                            indisponibilitaViewModel.IndisponibilitaKo.Errore.Self("");
                            Block();
                            bool errore = false;
                            string messaggioErrore = "ATTENZIONE! ";
                            string messaggioErroreDa = "Il campo Da non è nel formato corretto. ";
                            string messaggioErroreA = "Il campo A non è nel formato corretto. ";
                            string messaggioErroreDaA = "I campi Da e A non sono nel formato corretto. ";
                            bool erroreDa = false;
                            bool erroreA = false;

                            TimeSpan da = TimeSpan.MinValue;
                            TimeSpan a = TimeSpan.MinValue;
                            if(indisponibilitaViewModel.IndisponibilitaKo.Data.Self() == "")
                            {
                                errore = true;
                                messaggioErrore += "E' necessario inserire una data.";
                            }

                            try
                            {
                                string[] values = indisponibilitaViewModel.IndisponibilitaKo.PeriodoDa.Self().Split(':');
                                da = new TimeSpan(Convert.ToInt32(values[0]), Convert.ToInt32(values[1]), 0);
                            }
                            catch
                            {
                                errore = true;
                                erroreDa = true;
                            }

                            try
                            {
                                string[] values = indisponibilitaViewModel.IndisponibilitaKo.PeriodoA.Self().Split(':');
                                a = new TimeSpan(Convert.ToInt32(values[0]), Convert.ToInt32(values[1]), 0);
                            }
                            catch
                            {
                                errore = true;
                                erroreA = true;
                            }
                            if(errore)
                            {
                                if(erroreDa && erroreA)
                                    messaggioErrore += messaggioErroreDaA;
                                else if(erroreDa && !erroreA)
                                    messaggioErrore += messaggioErroreDa;
                                 else if(!erroreDa && erroreA)
                                    messaggioErrore += messaggioErroreA;
                            }

                            if(!errore)
                            {
                                if(da > a)
                                {
                                    errore = true;
                                    messaggioErrore += " Fasce orarie non coerenti.";
                                }
                            }

                            if(errore)
                            {
                                //BootstrapDialog.Alert(messaggioErrore);
                                indisponibilitaViewModel.IndisponibilitaKo.Errore.Self(messaggioErrore);
                            }
                            else
                            {
                                DateTime dateTime = DateTime.ParseExact(indisponibilitaViewModel.IndisponibilitaKo.Data.Self(), "yyyy-MM-dd", CultureInfo.InvariantCulture);

                                this.MeseCorrente.Self(Convert.ToInt32(dateTime.Month));
                                this.AnnoCorrente.Self(Convert.ToInt32(dateTime.Year));

                                jQuery.Select("#mese").Val(Convert.ToString(dateTime.Month)).Trigger("change");
                                jQuery.Select("#anno").Val(Convert.ToString(dateTime.Year)).Trigger("change");

                                PeriodiDto periodo = new PeriodiDto();
                                periodo.Da = indisponibilitaViewModel.IndisponibilitaKo.PeriodoDa.Self();
                                periodo.A = indisponibilitaViewModel.IndisponibilitaKo.PeriodoA.Self();

                                var indisponibilitaDto = new IndisponibilitaDto()
                                {
                                    InfermiereId = indisponibilitaViewModel.IndisponibilitaKo.InfermiereId.Self(),
                                    Data = dateTime,
                                    Periodo = periodo,
                                    Note = indisponibilitaViewModel.IndisponibilitaKo.Note.Self(),
                                    Id = indisponibilitaViewModel.IndisponibilitaKo.Id.Self()
                                };

                                try
                                {
                                    NecessitaGiornoDto[] necessitaGiorno = await _necessitaGiorniService.GetTurnazioneMensileForDayForInfermiere(0, dateTime.Year,
                                        dateTime.Month, dateTime.Day, periodo.Da, periodo.A,
                                        indisponibilitaViewModel.IndisponibilitaKo.InfermiereId.Self());

                                    if (System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(necessitaGiorno))
                                       await InserimentoAllocazioneEsistente(indisponibilitaViewModel, indisponibilitaDto, necessitaGiorno, dateTime, idInfermiere, o);
                                    else
                                    {
                                        await _indisponibilitaService.CreateNewIndisponibilita(indisponibilitaDto);

                                        Script.Call("DestroyTableSaw");
                                        await LoadCalendario(dateTime.Year,dateTime.Month, idInfermiere);
                                        Script.Call("RefreshTableSaw");

                                        o.ToDynamic().close();
                                    }
                                }
                                catch
                                {
                                    indisponibilitaViewModel.IndisponibilitaKo.Errore.Self("ATTENZIONE! Errore in fase di inserimento della indisponibilità");
                                    //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di inserimento della indisponibilità");
                                }
                            }

                            UnBlock();
                        }
                    }
                }
            });

        }

        public async Task<bool> EliminaIndisponibilita(IndisponibilitaViewModel indisponibilitaViewModel, int idInfermiere, object o)
        {
            bool result = true;

            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma eliminazione",
                Closable = false,
                Message = "Confermi eliminazione indisponibilità?",
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            e.ToDynamic().close();
                            Block();
                            try {
                                DateTime dateTime = DateTime.ParseExact(indisponibilitaViewModel.IndisponibilitaKo.Data.Self(), "yyyy-MM-dd", CultureInfo.InvariantCulture);

                                await _indisponibilitaService.DeleteIndisponibilitaPerInfermiere(indisponibilitaViewModel.IndisponibilitaKo.InfermiereId.Self(), indisponibilitaViewModel.IndisponibilitaKo.Id.Self());

                                Script.Call("DestroyTableSaw");
                                await LoadCalendario(dateTime.Year,dateTime.Month, idInfermiere);
                                Script.Call("RefreshTableSaw");
                                
                                o.ToDynamic().close();
                            }
                            catch
                            {
                                result = false;
                                indisponibilitaViewModel.IndisponibilitaKo.Errore.Self("ATTENZIONE! Errore in fase di eliminazione della indisponibilità");
                                //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di eliminazione della indisponibilità");
                            }
                            UnBlock();
                        }
                    }
                }
            });

            return result;
        }

        /// <summary>
        /// Modifica indisponibilità su allocazione esistente
        /// </summary>
        /// <param name="indisponibilitaViewModel"></param>
        /// <param name="indisponibilitaDto"></param>
        /// <param name="necessitaGiorno"></param>
        /// <param name="dateTime"></param>
        /// <param name="idInfermiere"></param>
        /// <param name="o"></param>
        /// <returns></returns>
        public async Task<bool> ModificaSuAllocazioneEsistente(IndisponibilitaViewModel indisponibilitaViewModel, IndisponibilitaDto indisponibilitaDto, 
                                                               NecessitaGiornoDto[] necessitaGiorno, DateTime dateTime, int idInfermiere, object o)
        {
            bool result = true;

            string necessita = "ATTENZIONE! Nel periodo indicato esiste già una allocazione presso le strutture: ";
            int[] idsNecessita = new int[necessitaGiorno.Length];
            for (int i = 0; i < necessitaGiorno.Length; i++)
            {
                idsNecessita[i] = necessitaGiorno[i].IdNecessita;
                necessita += necessitaGiorno[i].Struttura + "; \n";
            }
            necessita += "\nSe si procede la allocazione verrà eliminata. Confermi?";
            //var idNecessita = necessitaGiorno[0].IdNecessita;

            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma modifica indisponibilità",
                Closable = false,
                Message = necessita,
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            e.ToDynamic().close();
                            try
                            {
                                IndisponibilitaDto dto = await _indisponibilitaService.EditIndisponibilita(indisponibilitaDto);

                                Script.Call("DestroyTableSaw");
                                await LoadCalendario(dateTime.Year,dateTime.Month, idInfermiere);
                                Script.Call("RefreshTableSaw");

                                o.ToDynamic().close();
                            }
                            catch
                            {
                                result = false;
                                indisponibilitaViewModel.IndisponibilitaKo.Errore.Self("ATTENZIONE! Errore in fase di modifica della indisponibilità");
                                //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di modifica della indisponibilità");
                            }
                        }
                    }
                }
            });

            return result;
        }

        /// <summary>
        /// Modifica indisponibilità
        /// </summary>
        /// <param name="indisponibilita"></param>
        public void Edit(CrossFasceOrarieGiorniPerCalendarioDto indisponibilita)
        {
            var indisponibiltaDto = _indisponibilitaService.GetIndisponibilitaPerInfermierePerId(indisponibilita.IdInfermiere, indisponibilita.IdIndisponibilita);

            int idInfermiere = 0;
            var modalGialogHTML = "<form class=\"form-horizontal\" role=\"form\"> " +
                                  " <div class=\"form-group\"> <div class=\"col-md-12\"><span id=\"Errore\" data-bind=\"text: IndisponibilitaKo.Errore;\" style=\"background-color: red \" class=\"floating-label\"></span></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Data\">Data<span class=\"mandatory\">*</span></label> " +
                                  " <div class=\"col-md-8\"><input type=\"text\" id=\"Data\" data-bind=\"textInput: IndisponibilitaKo.Data\" class=\"dateTimePicker form-control floating-label\"></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoDa\">Da<span class=\"mandatory\">*</span></label> " +
                                  " <div class=\"col-md-8\"><input type=\"text\" id=\"PeriodoDa\" data-bind=\"textInput: IndisponibilitaKo.PeriodoDa\" class=\"form-control floating-label\" maxLength=\"5\" placeholder=\"__:__\"></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoA\">A<span class=\"mandatory\">*</span></label> " +
                                  " <div class=\"col-md-8\"><input type=\"text\" id=\"PeriodoA\" class=\"form-control floating-label\" data-bind=\"textInput: IndisponibilitaKo.PeriodoA\" maxLength=\"5\" placeholder=\"__:__\"></div> </div> " +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Note\">Note</label> <div class=\"col-md-8\"><textarea id=\"Note\" class=\"form-control floating-label\" data-bind=\"textInput: IndisponibilitaKo.Note\" style=\"height: 150px; width:400px;\"></textarea></div> </div> " +
                                  " <div class=\"clearfix\"></div>" +
                                  "</form>";

            var htmlElement = new HTMLElement();
            htmlElement.InnerHTML = modalGialogHTML;

            IndisponibilitaViewModel indisponibilitaViewModel = null;

            BootstrapDialog.Show(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Modifica indisponibilità",
                Closable = false,
                Message = htmlElement.InnerHTML,
                OnShown = () =>
                {
                    var pageNode = dom.document.getElementsByClassName("modal-dialog")[0];

                    Script.Call("SetNoteIndisponibilitaInfermiere");

                    BootstrapMaterialDatePicker.Render(new BootstrapMaterialDatePickerOption()
                    {
                        Time = false,
                        WeekStart = 0
                    }, ".dateTimePicker");

                    indisponibilitaViewModel = Bridge.Spaf.SpafApp.Container.Resolve<IndisponibilitaViewModel>();
                    idInfermiere = this.EditId;

                    indisponibilitaViewModel.InitEditIndisponibilita(indisponibiltaDto.Result.Id, indisponibiltaDto.Result.InfermiereId,
                        indisponibiltaDto.Result.Data.ToString("yyyy-MM-dd"), indisponibiltaDto.Result.Periodo.Da, indisponibiltaDto.Result.Periodo.A, indisponibiltaDto.Result.Note, "");

                    Retyped.knockout.ko.applyBindings(indisponibilitaViewModel, pageNode);
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Salva",
                        Action = async(o) => {
                            indisponibilitaViewModel.IndisponibilitaKo.Errore.Self("");
                            Block();
                            bool errore = false;
                            string messaggioErrore = "ATTENZIONE! ";
                            string messaggioErroreDa = "Il campo Da non è nel formato corretto. ";
                            string messaggioErroreA = "Il campo A non è nel formato corretto. ";
                            string messaggioErroreDaA = "I campi Da e A non sono nel formato corretto. ";
                            bool erroreDa = false;
                            bool erroreA = false;

                            TimeSpan da = TimeSpan.MinValue;
                            TimeSpan a = TimeSpan.MinValue;
                            if(indisponibilitaViewModel.IndisponibilitaKo.Data.Self() == "")
                            {
                                errore = true;
                                messaggioErrore += "E' necessario inserire una data.";
                            }

                            try
                            {
                                string[] values = indisponibilitaViewModel.IndisponibilitaKo.PeriodoDa.Self().Split(':');
                                da = new TimeSpan(Convert.ToInt32(values[0]), Convert.ToInt32(values[1]), 0);
                            }
                            catch
                            {
                                errore = true;
                                erroreDa = true;
                            }

                            try
                            {
                                string[] values = indisponibilitaViewModel.IndisponibilitaKo.PeriodoA.Self().Split(':');
                                a = new TimeSpan(Convert.ToInt32(values[0]), Convert.ToInt32(values[1]), 0);
                            }
                            catch
                            {
                                errore = true;
                                erroreA = true;
                            }
                            if(errore)
                            {
                                if(erroreDa && erroreA)
                                    messaggioErrore += messaggioErroreDaA;
                                else if(erroreDa && !erroreA)
                                    messaggioErrore += messaggioErroreDa;
                                 else if(!erroreDa && erroreA)
                                    messaggioErrore += messaggioErroreA;
                            }

                            if(!errore)
                            {
                                if(da > a)
                                {
                                    errore = true;
                                    messaggioErrore += " Fasce orarie non coerenti.";
                                }
                            }

                            if(errore)
                            {
                                //BootstrapDialog.Alert(messaggioErrore);
                                indisponibilitaViewModel.IndisponibilitaKo.Errore.Self(messaggioErrore);
                            }
                            else
                            {
                                DateTime dateTime = DateTime.ParseExact(indisponibilitaViewModel.IndisponibilitaKo.Data.Self(), "yyyy-MM-dd", CultureInfo.InvariantCulture);

                                this.MeseCorrente.Self(Convert.ToInt32(dateTime.Month));
                                this.AnnoCorrente.Self(Convert.ToInt32(dateTime.Year));

                                jQuery.Select("#mese").Val(Convert.ToString(dateTime.Month)).Trigger("change");
                                jQuery.Select("#anno").Val(Convert.ToString(dateTime.Year)).Trigger("change");

                                PeriodiDto periodo = new PeriodiDto();
                                periodo.Da = indisponibilitaViewModel.IndisponibilitaKo.PeriodoDa.Self();
                                periodo.A = indisponibilitaViewModel.IndisponibilitaKo.PeriodoA.Self();

                                var indisponibilitaDto = new IndisponibilitaDto()
                                {
                                    InfermiereId = indisponibilitaViewModel.IndisponibilitaKo.InfermiereId.Self(),
                                    Data = dateTime,
                                    Periodo = periodo,
                                    Note = indisponibilitaViewModel.IndisponibilitaKo.Note.Self(),
                                    Id = indisponibilitaViewModel.IndisponibilitaKo.Id.Self()
                                };

                                try
                                {
                                    NecessitaGiornoDto[] necessitaGiorno = await _necessitaGiorniService.GetTurnazioneMensileForDayForInfermiere(0, dateTime.Year,
                                        dateTime.Month, dateTime.Day, periodo.Da, periodo.A,
                                        indisponibilitaViewModel.IndisponibilitaKo.InfermiereId.Self());

                                    if (System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(necessitaGiorno))
                                        await ModificaSuAllocazioneEsistente(indisponibilitaViewModel, indisponibilitaDto, necessitaGiorno, dateTime, idInfermiere, o);
                                    else
                                    {
                                        IndisponibilitaDto dto = await _indisponibilitaService.EditIndisponibilita(indisponibilitaDto);

                                        Script.Call("DestroyTableSaw");
                                        await LoadCalendario(dateTime.Year,dateTime.Month, idInfermiere);
                                        Script.Call("RefreshTableSaw");

                                        o.ToDynamic().close();
                                    }
                                }
                                catch
                                {
                                    indisponibilitaViewModel.IndisponibilitaKo.Errore.Self("ATTENZIONE! Errore in fase di modifica della indisponibilità");
                                    //BootstrapDialog.Alert("ATTENZIONE! Errore in fase di modifica della indisponibilità");
                                }
                            }

                            UnBlock();
                        }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Elimina",
                        Action = async(o) => {
                            await EliminaIndisponibilita(indisponibilitaViewModel, idInfermiere, o);
                        }
                    }
                }
            });
        }

        public class InfermiereKo
        {
            public Retyped.knockout.KnockoutObservable <int>InfermiereId { get; set; }
            public Retyped.knockout.KnockoutObservable <string>Nome { get; set; }
            public Retyped.knockout.KnockoutObservable <string>Cognome { get; set; }
            public Retyped.knockout.KnockoutObservable <string>MexalId { get; set; }

            public InfermiereKo(InfermieriDto dto)
            {
                this.InfermiereId = Retyped.knockout.ko.observable.Self<int>((dto!=null?dto.Id:(int?)null) ?? 0);
                this.Nome = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.Nome);
                this.Cognome = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.Cognome);
                this.MexalId = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.MexalId);
            }
        }
    }
}
