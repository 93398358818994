using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CalendariStrutturaDto
    {
        [JsonProperty("idnecessita")]
        public int IdNecessita { get; set; }

        [JsonProperty("idinfermiere")]
        public int IdInfermiere { get; set; }

        [JsonProperty("infermiere")]
        public string Infermiere { get; set; }

        [JsonProperty("struttura")]
        public string Struttura { get; set; }

        [JsonProperty("causale")]
        public string Causale { get; set; }

        [JsonProperty("giorno")]
        public string Giorno { get; set; }

        [JsonProperty("fasciaoraria")]
        public string FasciaOraria { get; set; }

        [JsonProperty("fasciaorariada")]
        public string FasciaOrariaDa { get; set; }

        [JsonProperty("fasciaorariaa")]
        public string FasciaOrariaA { get; set; }

        [JsonProperty("giorniFestivi")]
        public string GiorniFestivi { get; set; }

        [JsonProperty("giorniPreFestivi")]
        public string GiorniPreFestivi { get; set; }

        [JsonProperty("patrono")]
        public string Patrono { get; set; }

        public CalendariStrutturaDto(int idNcessita, int idInfermiere, string infermiere, string struttura, string causale, string giorno, string fasciaOraria, string fasciaOrariaDa, 
                                     string fasciaOrariaA, string giorniFestivi, string giorniPreFestivi, string patrono)
        {
            IdNecessita = IdNecessita;
            IdInfermiere = idInfermiere;
            Infermiere = infermiere;
            Struttura = struttura;
            Causale = causale;
            Giorno = giorno;
            FasciaOraria = fasciaOraria;
            FasciaOrariaDa = fasciaOrariaDa;
            FasciaOrariaA = fasciaOrariaA;
            GiorniFestivi = giorniFestivi;
            GiorniPreFestivi = giorniPreFestivi;
            Patrono = patrono;
        }
    }
}
