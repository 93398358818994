using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Html5;
using Bridge.jQuery2;
using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class AnomalieResource: AuthorizedResourceBase, IAnomalieResource
    {
       

        public Task<AnomalieDto[]> GetAnamaliePerFasciaGiorno(int idFascia, int idGiorno)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/anomalie/{0}/{1}",idFascia,idGiorno)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<AnomalieDto[]>(options);
        }

        public Task<AnomalieDto[]> GetAnomalieByFilters(int anno, int mese, int giorno, string periodoDa, string periodoA, int strutturaId, string causale)
        {

            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/anomalie/GetByFilters?StrutturaId=")+ strutturaId + "&Causale=" + causale + "&Anno=" + anno + "&Mese=" + mese + "&Giorno=" + giorno + "&PeriodoDa=" + periodoDa + "&PeriodoA=" + periodoA ),
                Type = "GET",
                DataType = "json",
                ContentType = "application/json"
            };

            return base.MakeCall<AnomalieDto[]>(options);
        }

        public Task<ConflittoDto[]> LoadConflicts(int anno, int mese, int giorno, string periodoDa, string periodoA, int infermiereId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/anomalie/GetInfermiereConflict?idInfermiere=")+ infermiereId + "&Anno=" + anno + "&Mese=" + mese + "&Giorno=" + giorno + "&PeriodoDa=" + periodoDa + "&PeriodoA=" + periodoA),
                Type = "GET",
                DataType = "json",
                ContentType = "application/json"
            };

            return base.MakeCall<ConflittoDto[]>(options);
        }

        public Task<int> ResolveAnomalia(int idAnomaliaToResolve, ResolveAnomaliaDto data)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/anomalie/{0}",idAnomaliaToResolve)),
                Type = "PUT",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(data)
            };

            return base.MakeCall<int>(options);
        }

        public AnomalieResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
