
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class PeriodoPrototipoService : IPeriodoPrototipoService
    {
        private readonly IPeriodoPrototipoResource _periodoPrototipoResource;

        public PeriodoPrototipoService(IPeriodoPrototipoResource periodoPrototipoResource)
        {
            _periodoPrototipoResource = periodoPrototipoResource;
        }

        public Task<PeriodoPrototipoDto> CreateNewPeriodoPrototipo(int strutturaId, PeriodoPrototipoDto currentPeriodoPrototipo)
        {
            return _periodoPrototipoResource.CreateNewPeriodoPrototipo(strutturaId, currentPeriodoPrototipo);          
        }

        public Task DeletePeriodoPrototipo(int strutturaId, int id)
        {
            return _periodoPrototipoResource.DeletePeriodoPrototipo(strutturaId, id);
        }

        public Task<PeriodoPrototipoDto> EditPeriodoPrototipo(int strutturaId, int id, PeriodoPrototipoDto currentPeriodoPrototipo)
        {
            return _periodoPrototipoResource.EditPeriodoPrototipo(strutturaId, id, currentPeriodoPrototipo);
        }

        public Task<PeriodoPrototipoDto> GetPeriodoPrototipoByStructureId(int structId)
        {
            return _periodoPrototipoResource.GetPeriodoPrototipoByStructureId(structId);
        }

        public Task TranslateTipiTurno(int value, int strutturaId, int id, string causale)
        { 
            return _periodoPrototipoResource.TranslateTipiTurno(value, strutturaId, id, causale);
        }

        public Task DeleteAllTipiTurno(int strutturaId, int id)
        {
            return _periodoPrototipoResource.DeleteAllTipiTurno(strutturaId, id);
        }
    }
}
