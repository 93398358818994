using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class NecessitaGiorniResource : AuthorizedResourceBase, INecessitaGiorniResource
    {
       

        public Task<NecessitaGiornoDto[]> GetTurnazioneMensileForDayForInfermiere(int strutturaId, int anno, int mese, int giorno, string da, string a, int idInfermiere)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/{0}/{1}/{2}/{3}/{4}/{5}/{6}",strutturaId,anno,mese,idInfermiere,giorno,da,a)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<NecessitaGiornoDto[]>(options);
        }

        public Task<NecessitaGiornoDto[]> GetTurnazioneMensileForInfermiere(int anno, int mese, int idInfermiere)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/{0}/{1}/{2}",anno,mese,idInfermiere)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<NecessitaGiornoDto[]>(options);
        }

        public Task<NecessitaGiornoDto> GetEntityById(int entityKey, int id)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/{0}/{1}",entityKey,id)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<NecessitaGiornoDto>(options);
        }

        public Task<NecessitaGiornoDto> GetById(int idNecessita)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/{0}",idNecessita)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<NecessitaGiornoDto>(options);
        }

        public Task<int> Put(int idNecessita, int idInfermiere, int idNecessitaRemove)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/{0}/{1}/{2}",idNecessita,idInfermiere,idNecessitaRemove)),
                Type = "PUT",
            };

            return this.MakeCall<int>(options);
        }

        public Task Delete(int idNecessita)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/{0}",idNecessita)),
                Type = "DELETE",
            };

            return this.MakeCall<object>(options);
        }

        public Task<NecessitaGiornoDto> AddNecessita(int idTurnazioneMensile, int idInfermiere, string date, string causale, string periodoDa, string periodoA)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/{0}/{1}/{2}/{3}/{4}/{5}",idTurnazioneMensile,idInfermiere,date,causale,periodoDa,periodoA)),
                Type = "POST",
                DataType = "json"
            };

            return base.MakeCall<NecessitaGiornoDto>(options);
        }

        public Task<NecessitaGiornoDto[]> GetNecessitaGiorno(int anno, int mese, int giorno, string periodoDa, string periodoA, int strutturaId, int infermiereId, string causale)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/GetNecessitaGiorno?StrutturaId=")+ strutturaId + "&InfermiereId=" + infermiereId + "&Causale=" + causale + "&Anno=" + anno + "&Mese=" + mese + "&Giorno=" + giorno + "&PeriodoDa=" + periodoDa + "&PeriodoA=" + periodoA),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<NecessitaGiornoDto[]>(options);
        }

        public Task<OverlapNecessitaDto[]> GetOverlappedNecessita(int anno, int mese, int giorno, string periodoDa,
            string periodoA, int infermiereId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/GetOverlappedNecessita?InfermiereId={0}&Anno={1}&Mese={2}&Giorno={3}&PeriodoDa={4}&PeriodoA={5}",infermiereId,anno,mese,giorno,periodoDa,periodoA)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<OverlapNecessitaDto[]>(options);
        }

        public Task<NightNecessitaGiornoDto[]> GetAlertNightNecessita(int anno, int mese, int giorno, int struttura)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/necessitaGiorno/GetAlertNightNecessita?StrutturaId={0}&Anno={1}&Mese={2}&Giorno={3}",struttura,anno,mese,giorno)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<NightNecessitaGiornoDto[]>(options);
        }

        public NecessitaGiorniResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
