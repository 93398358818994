using Bridge.Html5;
using System;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    class CookieService : ICookieService
    {
        public DateTime? GetDefaultDate()
        {
            DateTime? defaultDate = null;
            var cookie = Document.Cookie.Split(';');

            for (int i=0; i < cookie.Length; i++)
            {
                if (cookie[i].Trim().Substring(0, 11) == "defaultDate")
                {
                    defaultDate = DateTime.Parse(cookie[i].Substring(12));
                    break;
                }
            }
            return defaultDate;
        }
    }
}
