using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CrossFasceOrarieGiorniPerCalendarioDto
    {
        [JsonProperty("idindisponibilita")]
        public int IdIndisponibilita { get; set; }

        [JsonProperty("periododa")]
        public string PeriodoDa { get; set; }

        [JsonProperty("periodoa")]
        public string PeriodoA { get; set; }

        [JsonProperty("note")]
        public string Note { get; set; }

        [JsonProperty("struttura")]
        public string Struttura { get; set; }

        [JsonProperty("causale")]
        public string Causale { get; set; }

        [JsonProperty("idinfermiere")]
        public int IdInfermiere { get; set; }

        [JsonProperty("infermiere")]
        public string Infermiere { get; set; }

        [JsonProperty("idgiorno")]
        public int IdGiorno { get; set; }

        [JsonProperty("idfasciaoraria")]
        public int IdFasciaOraria { get; set; }

        [JsonProperty("stato")]
        public int Stato { get; set; }
        
        public CrossFasceOrarieGiorniPerCalendarioDto(int idIndisponibilita, string periodoDa, string periodoA, string note, int idInfermiere, string infermiere, int idGiorno, string struttura, string causale, int idFasciaOraria, int stato)
        {
            IdIndisponibilita = idIndisponibilita;
            PeriodoDa = periodoDa;
            PeriodoA = periodoA;
            Note = note;
            Struttura = struttura;
            Causale = causale;
            IdInfermiere = idInfermiere;
            Infermiere = infermiere;
            IdFasciaOraria = idFasciaOraria;
            IdGiorno = idGiorno;
            Stato = stato;
        }
    }
}
