using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class AnomalieService: IAnomalieService
    {
        private readonly IAnomalieResource _anomalieResource;

        public AnomalieService(IAnomalieResource AnomalieResource)
        {
            _anomalieResource = AnomalieResource;
        }

        public Task<AnomalieDto[]> GetAnamaliePerFasciaGiorno(int idFascia, int idGiorno)
        {
            return _anomalieResource.GetAnamaliePerFasciaGiorno(idFascia, idGiorno);
        }

        public Task<AnomalieDto[]> GetAnomalieByFilters(int anno, int mese, int giorno, string periodoDa, string periodoA, int strutturaId, string causale)
        {
            string fasciaA = null;
            if (periodoDa != null)
            {
                string[] split = periodoDa.Split(':');
                fasciaA = split[0] + ":59";
            }
            return _anomalieResource.GetAnomalieByFilters(anno, mese, giorno, periodoDa, fasciaA, strutturaId, causale);
        }

        public Task<ConflittoDto[]> LoadConflicts(int anno, int mese, int giorno, string periodoDa, string periodoA, int infermiereId)
        {
            return _anomalieResource.LoadConflicts(anno, mese, giorno, periodoDa, periodoA, infermiereId);
        }

        public Task<int> ResolveAnomalia(int idAnomaliaToResolve, ResolveAnomaliaDto data)
        {
            return _anomalieResource.ResolveAnomalia(idAnomaliaToResolve, data);
        }
    }
}
