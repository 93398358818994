using System.Collections.Generic;
using Bridge.Html5;
using Bridge.Navigation;
using Bridge.Spaf;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels
{
    public class LoginViewModel : LoadableViewModel
    {
        private readonly INavigator _navigator;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.LoginId;
}
        public Retyped.knockout.KnockoutObservable <string>Username { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Password { get; set; }
        public HTMLElement modal { get; set; }
        public HTMLElement closeModal { get; set; }

        public LoginViewModel(INavigator navigator)
        {
            _navigator = navigator;
            Username = Retyped.knockout.ko.observable.Self<string>();
            Password = Retyped.knockout.ko.observable.Self<string>();
            modal = Document.GetElementById<HTMLDivElement>("errorModal");
            closeModal = Document.GetElementsByClassName("close")[0]; 
        }

        public override void OnLoad(Dictionary<string, object> parameters)
        {
            base.OnLoad(parameters);

            //TODO: Remove JS code from login page and insert HERE
        }

        public void ExecuteLogin()
        {
            if (Username.Self() == "demo" && Password.Self() == "demo")
                _navigator.Navigate(Bridge.Spaf.SpafApp.HomeId);
            else
            {
                modal.Style.Display = "block";
            }
        }
    }
}
