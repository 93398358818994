using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using System;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    class ListaGiorni
    {
        public int Id;
        public string Giorno;

        public ListaGiorni(int id, string giorno)
        {
            Id = id;
            Giorno = giorno;
        }
    }

    public class GiorniResource: AuthorizedResourceBase, IGiorniResource
    {
       
        public GiorniResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }

        public Task<BaseDtoOutputModel<GiorniDto>> GetAllGiorni(int anno, int mese)
        {
            BaseDtoOutputModel<GiorniDto> giorni = new BaseDtoOutputModel<GiorniDto>();

            int giorniMese = DateTime.DaysInMonth(anno, mese);

            GiorniDto[] items = new GiorniDto[giorniMese];

            int index = 0;

            while (index < giorniMese)
            {
                items[index] = new GiorniDto();
                items[index].Id = index + 1;
                items[index].Giorno = Convert.ToString(index + 1);
                items[index].GiornoLabel = GetDayInitialLetter(new DateTime(anno, mese, index + 1));
                index++;
            }

            giorni.Items = items;

            return Task.FromResult<BaseDtoOutputModel<GiorniDto>>(giorni);
        }
        
        private string GetDayInitialLetter(DateTime day)
        {
            const string dn = "DLMMGVS";
            return dn.Substring((int)day.DayOfWeek, 1);
        }


        public Task<GiorniDto[]> GetAllGiorni(int idStruttura, int anno, int mese)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/calendari/giorni/{0}/{1}/{2}",idStruttura,anno,mese)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<GiorniDto[]>(options);
        }

        public Task<GiorniDto[]> AggiungiSettimana(int strutturaId, int periodoId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo/{1}/giorni/AddWeek",strutturaId,periodoId)),
                Type = "POST",
                DataType = "json"
            };

            return base.MakeCall<GiorniDto[]>(options);
        }

        public Task RimuoviSettimana(int strutturaId, int periodoId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo/{1}/giorni/RemoveWeek",strutturaId,periodoId)),
                Type = "POST",
            };

            return base.MakeCall<object>(options);
        }
    }
}
