using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class TipiTurniService : ITipiTurniService
    {
        private readonly ITipiTurniResource _tipiTurniResource;

        public TipiTurniService(ITipiTurniResource tipiturniResource)
        {
            _tipiTurniResource = tipiturniResource;
        }

        public Task<TipiTurniDto> CreateTipiTurni(int structId, TipiTurniDto dto)
        {
            return _tipiTurniResource.CreateTipiTurni(structId, dto);
        }

        public Task DeleteInfermiere(int structId, int editId)
        {
            return _tipiTurniResource.DeleteInfermiere(structId, editId);
        }

        public Task DeleteTipiTurni(int structId, int editId)
        {
            return _tipiTurniResource.DeleteTipiTurni(structId, editId);
        }

        public Task<TipiTurniDto> EditTipiTurni(int structId, TipiTurniDto dto)
        {
            return _tipiTurniResource.EditTipiTurni(structId, dto);
        }

        public Task<string[]> GetAllCausaliLabels(int structId)
        {
            return _tipiTurniResource.GetAllCausaliLabels(structId);
        }

        public Task<TipiTurniDto[]> GetAllTipiTurniByStructureId(int structId)
        {
            return _tipiTurniResource.GetAllTipiTurniByStructureId(structId);
        }

        public Task<TipiTurniDto> GetTipiTurniById(int structId, int editId)
        {
            return _tipiTurniResource.GetTipiTurniById(structId, editId);
        }
    }
}
