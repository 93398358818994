using System;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Html5;
using Bridge.jQuery2;
using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public abstract class ResourceBase
    {
        protected readonly ISettings Settings;

        protected ResourceBase(ISettings settings)
        {
            this.Settings = settings;
        }

        /// <summary>
        /// Generic Awaitable ajax call
        /// </summary>
        /// <param name="options"></param>
        /// <typeparam name="T"></typeparam>
        /// <returns></returns>
        protected virtual Task<T> MakeCall<T>(AjaxOptions options)
        {
            return Task.FromPromise<T>(jQuery.Ajax(options)
                , (Func<object, string, jqXHR, T>)((resObj, success, jqXhr) =>
                {
                    var json = JSON.Stringify(resObj);
                    var obj = JsonConvert.DeserializeObject<T>(json);

                    return obj;
                }));

        }
        
        
        /// <summary>
        /// Generic Awaitable ajax call
        /// </summary>
        /// <param name="options"></param>
        /// <typeparam name="T"></typeparam>
        /// <returns></returns>
        protected virtual Task MakeCall(AjaxOptions options)
        {
            return Task.FromPromise(jQuery.Ajax(options));

        }

        protected string UriBuild(string resource)
        {
            return string.Format("{0}{1}",this.Settings.ApiUrl,resource);
        }
    }
}