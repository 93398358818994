using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class ExportResource : AuthorizedResourceBase, IExportResource
    {
        public ExportResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }

        public string ExportStrutturePerInfermiere(int anno, int mese, int infermiereId)
        {
            return base.UriBuild(string.Format("/export/{0}/{1}/{2}",anno,mese,infermiereId));
        }

        public string ExportFoglioPresenzePerStruttura(int anno, int mese, int strutturaId)
        {
            return base.UriBuild(string.Format("/export/ExportFoglioPresenzePerStruttura/{0}/{1}/{2}",anno,mese,strutturaId));

        }

        public Task ExportReportPerMexal(int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/export/exportReportPerMexal/{0}/{1}",anno,mese)),
                Type = "POST",
                DataType = "json",
                ContentType = "application/json",
            };

            return base.MakeCall(options);
        }
    }
}
