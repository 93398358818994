using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CrossTipiTurnoPerCalendarioPeriodoPrototipoDto
    {
        [JsonProperty("id")]
        public int Id { get; set; }

        [JsonProperty("settimanaid")]
        public int? SettimanaId { get; set; }

        [JsonProperty("giornoid")]
        public int? GiornoId { get; set; }

        [JsonProperty("infermiereid")]
        public int? InfermiereId { get; set; }

        [JsonProperty("causale")]
        public string Causale { get; set; }

        [JsonProperty("periodo")]
        public PeriodoDto Periodo { get; set; }

        [JsonProperty("infermiereObbligatorio")]
        public bool InfermiereObbligatorio { get; set; }

        [JsonProperty("nomeInfermiere")]
        public string NomeInfermiere { get; set; }

        [JsonProperty("obbligatorio")]
        public string Obbligatorio { get; set; }

        public CrossTipiTurnoPerCalendarioPeriodoPrototipoDto(int id, int? settimanaId, int? giornoId, int? infermiereId, string causale, PeriodoDto periodo, bool infermiereObbligatorio, string nomeInfermiere, string obbligatorio)
        {
            Id = id;
            SettimanaId = settimanaId;
            GiornoId = giornoId;
            InfermiereId = infermiereId;
            Causale = causale;
            Periodo = periodo;
            InfermiereObbligatorio = infermiereObbligatorio;
            NomeInfermiere = nomeInfermiere;
            Obbligatorio = obbligatorio;
        }
    }
}
