using Aip.Florence.FrontEnd.SpafApp.Dto;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.KnockoutModels
{
    public class IndisponibilitaKo
    {
        public Retyped.knockout.KnockoutObservable <int>InfermiereId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Errore { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>VisualizzaErrore { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Data { get; set; }
        public Retyped.knockout.KnockoutObservable <string>PeriodoDa { get; set; }
        public Retyped.knockout.KnockoutObservable <string>PeriodoA { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Note { get; set; }
        public Retyped.knockout.KnockoutObservable <int>Id { get; set; }

        public IndisponibilitaKo(IndisponibilitaDto dto)
        {
            InfermiereId = Retyped.knockout.ko.observable.Self<int>(dto == null ? 0 : dto.InfermiereId);
            Errore = Retyped.knockout.ko.observable.Self<string>("");
            VisualizzaErrore = Retyped.knockout.ko.observable.Self<bool>(false);
            Data = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : dto.Data.ToString("yyyy-MM-dd"));
            PeriodoDa = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : (string)dto.Periodo.Da);
            PeriodoA = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : (string)dto.Periodo.A);
            Note = Retyped.knockout.ko.observable.Self<string>(dto == null ? string.Empty : (string)dto.Note);
            Id = Retyped.knockout.ko.observable.Self<int>(dto == null ? 0 : dto.Id);
        }

        public void Refresh(IndisponibilitaDto dto, string errore)
        {
            if (dto == null) return;

            Errore.Self(errore);
            if (errore != "")
                VisualizzaErrore.Self(true);
            else
                VisualizzaErrore.Self(false);
            InfermiereId.Self(dto.InfermiereId);
            Data.Self(dto.Data.ToString("yyyy-MM-dd"));
            PeriodoDa.Self((string)dto.Periodo.Da);
            PeriodoA.Self((string)dto.Periodo.A);
            Note.Self((string)dto.Note);
            Id.Self(dto.Id);
        }
    }
}
