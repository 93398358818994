using Bridge.Html5;
using Bridge.Spaf;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels
{
    public abstract class BlockableViewModel : LoadableViewModel
    {
        private readonly HTMLElement _blockDiv;

        protected BlockableViewModel()
        {
            _blockDiv = InitDiv();
        }

        /// <summary>
        /// Block page
        /// </summary>
        protected void Block()
        {
            var firstChildren = Document.GetElementById(this.ElementId()).Children[0];
            Document.GetElementById(this.ElementId()).InsertBefore(this._blockDiv, firstChildren);
        }

        /// <summary>
        /// Unblock page
        /// </summary>
        protected void UnBlock()
        {
            Document.GetElementById(this.ElementId()).RemoveChild(this._blockDiv);
        }

        /// <summary>
        /// InitDiv
        /// </summary>
        /// <returns></returns>
        private HTMLDivElement InitDiv()
        {
            return new HTMLDivElement()
            {
                Style =
                {
                    BackgroundColor = "transparent",
                    Height = "100%",
                    Width = "100%",
                    Position = "absolute",
                    ZIndex = "9999",
                    Cursor = "wait"
                }
            };
        }
    }
}