using System;
using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class NecessitaGiornoDto
    {
        [JsonProperty("idnecessita")]
        public int IdNecessita { get; set; }

        [JsonProperty("causale")]
        public string Causale { get; set; }

        [JsonProperty("data")]
        public DateTime Data { get; set; }

        [JsonProperty("periododa")]
        public string PeriodoDa { get; set; }

        [JsonProperty("periodoa")]
        public string PeriodoA { get; set; }

        [JsonProperty("idturnazioneMensile")]
        public int IdTurnazioneMensile { get; set; }

        [JsonProperty("idstruttura")]
        public int IdStruttura { get; set; }

        [JsonProperty("struttura")]
        public string Struttura { get; set; }

        [JsonProperty("idinfermiere")]
        public int? IdInfermiere { get; set; }

        [JsonProperty("infermiere")]
        public string Infermiere { get; set; }

        [JsonProperty("periodoLavoratoDa")]
        public string PeriodoLavoratoDa { get; set; }

        [JsonProperty("periodoLavoratoA")]
        public string PeriodoLavoratoA { get; set; }
        
        [JsonProperty("note")]
        public string Note { get; set; }
        public string DataString { get { return Data.Date.ToString("dd/MM/yyyy"); } }

        public NecessitaGiornoDto()
        {
        }
    }
}
