using Aip.Florence.FrontEnd.SpafApp.Dto;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.KnockoutModels
{
    public class AssociaInfermiereKo
    {
        public Retyped.knockout.KnockoutObservable <int>InfermiereId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Errore { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>Infermieri { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>InfermiereCorrente { get; set; }
        public Retyped.knockout.KnockoutObservable <int>InfermiereSelezionato { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::System.Collections.Generic.List<int>>InfermieriCorrenti { get; set; }
        public Retyped.knockout.KnockoutObservable <global::System.Collections.Generic.List<int>>InfermieriSelezionati { get; set; }

        public AssociaInfermiereKo()
        {
            Infermieri = Retyped.knockout.ko.observableArray.Self<InfermieriDto>();
            Errore = Retyped.knockout.ko.observable.Self<string>("");
            InfermiereCorrente = Retyped.knockout.ko.observableArray.Self<int>();
            InfermiereSelezionato = Retyped.knockout.ko.observable.Self<int>();

            InfermieriCorrenti = Retyped.knockout.ko.observableArray.Self<List<int>>();
            InfermieriSelezionati = Retyped.knockout.ko.observable.Self<List<int>>();
        }

        public void Refresh(int idInfermiere, InfermieriDto[] infermieriDto, string errore)
        {
            Errore.Self(errore);
            this.Infermieri.push(infermieriDto);
            this.InfermiereCorrente.push(idInfermiere);
            this.InfermiereSelezionato.Self(idInfermiere);

            InfermiereId.Self(idInfermiere);
        }

        public void Refresh(List<int> idInfermieri, InfermieriDto[] infermieriDto, string errore)
        {
            Errore.Self(errore);
            this.Infermieri.push(infermieriDto);
            this.InfermieriCorrenti.push(idInfermieri);
            this.InfermieriSelezionati.Self(idInfermieri);
        }
    }
}
