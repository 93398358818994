using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class TurnazioniMensiliResource: AuthorizedResourceBase, ITurnazioniMensiliResource
    {
       
        
        public Task<TurnazioniMensiliDto[]> ChangeStatoTurnazioneMensile(int anno, int mese, int stato, int strutturaId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/{0}/{1}/{2}/{3}",anno,mese,stato,strutturaId)),
                Type = "PUT",
                DataType = "json",
                ContentType = "application/json"
            };

            return base.MakeCall<TurnazioniMensiliDto[]>(options);
        }

        public Task<TurnazioniMensiliDto[]> GetAllTurnazioniMensili(int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/{0}/{1}",anno,mese)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<TurnazioniMensiliDto[]>(options);
        }

        public Task<TurnazioniMensiliDto> GetTurnazioniMensileForStruttura(int anno, int mese, int strutturaId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/{0}/{1}/{2}",anno,mese,strutturaId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<TurnazioniMensiliDto>(options);
        }

        public Task<TurnazioniMensiliDto> PrepareTurnazioneMensile(NewMonthRequestDto newMonthRequest)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/PrepareNewMonth")),
                Type = "POST",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(newMonthRequest)
            };

            return this.MakeCall<TurnazioniMensiliDto>(options); 
        }

        public Task<TurnazioniMensiliDto> PrepareTurnazioneMensile(int anno, int mese, int strutturaId)
        {
            NewMonthRequestDto newMonthRequest = new NewMonthRequestDto();
            newMonthRequest.Year = anno;
            newMonthRequest.Month = mese;
            newMonthRequest.StrutturaId = strutturaId;

            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/PrepareNewMonth")),
                Type = "POST",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(newMonthRequest)
            };

            return this.MakeCall<TurnazioniMensiliDto>(options);
        }

        public Task<bool> TurnazioniMensiliClosed(int anno, int mese, int strutturaId)
        {
            var chiuso = 1;
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/{0}/{1}/{2}/{3}",anno,mese,strutturaId,chiuso)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public Task<bool> CheckStato(int anno, int mese, int stato, int strutturaId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/CheckStato/{0}/{1}/{2}/{3}",anno,mese,stato,strutturaId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public Task<bool> CanPrepareTurnazioneMensileForStruttura(int strutturaId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/CanPrepare/{0}",strutturaId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public Task<bool> TurnazioniMensiliInvioReport(int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/InvioReport/{0}/{1}",anno,mese)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public Task RequireSendReport(int anno, int mese)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/{0}/{1}",anno,mese)),
                Type = "PUT",
            };

            return base.MakeCall<object>(options);
        }

        public Task<TurnazioniMensiliInfoStatoDto> TurnazioniMensiliInfoStato(int anno, int mese, int strutturaId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/InfoStato/{0}/{1}/{2}",anno,mese,strutturaId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<TurnazioniMensiliInfoStatoDto>(options);
        }

        public Task<bool> NotificaChiusuraMese(int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/NotificaChiusuraMese/{0}/{1}",anno,mese)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public Task<bool> VisualizzaSuApp(int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/VisualizzaSuApp/{0}/{1}",anno,mese)),
                Type = "POST",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public Task<bool> InvioTurniStrutture(int anno, int mese)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/InvioTurniStrutture/{0}/{1}",anno,mese)),
                Type = "POST",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public Task<bool> VisualizzaAggiungiNecessita(int anno, int mese, int strutturaId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/TurnazioniMensili/VisualizzaAggiungiNecessita/{0}/{1}/{2}",anno,mese,strutturaId)),
                Type = "POST",
                DataType = "json"
            };

            return base.MakeCall<bool>(options);
        }

        public TurnazioniMensiliResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
