using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class FasceOrarieService : IFasceOrarieService
    {
        private readonly IFasceOrarieResource _fasceOrarieResource;

        public FasceOrarieService(IFasceOrarieResource FasceResource)
        {
            _fasceOrarieResource = FasceResource;
        }

        public Task<BaseDtoOutputModel<FasceOrarieDto>> GetAllFasceOrarie()
        {
            return _fasceOrarieResource.GetAllFasceOrarie();
        }

        public Task<string> GetFasciaOrariaById(int id)
        {
            return _fasceOrarieResource.GetFasciaOrariaById(id);
        }
    }
}
