using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Necessita
{
    public class AlertNightNecessitaViewModel : BlockableViewModel
    {
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.AlertNightNecessita;
}
        private readonly IGiorniService _giornoService;
        private readonly ICookieService _cookieService;
        private readonly IIndisponibilitaService _indisponibilitaService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        private readonly IStruttureService _struttureService;

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.NightNecessitaGiornoDto>NightNecessita { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.AnniDto>Anni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>AnnoSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>AnnoFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.MesiDto>Mesi { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>MeseSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>MeseFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.GiorniDto>Giorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>GiornoSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>GiornoFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.StruttureDto>Strutture { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>StrutturaSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>StrutturaFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservable <bool>LoaderPage { get; set; }

        public AlertNightNecessitaViewModel(IGiorniService giornoService, ICookieService cookieService,
            IIndisponibilitaService indisponibilitaService, INecessitaGiorniService necessitaGiorniService,
            IStruttureService struttureService)
        {
            _giornoService = giornoService;
            _cookieService = cookieService;
            _indisponibilitaService = indisponibilitaService;
            _necessitaGiorniService = necessitaGiorniService;
            _struttureService = struttureService;

            NightNecessita = Retyped.knockout.ko.observableArray.Self<NightNecessitaGiornoDto>();
            Anni = Retyped.knockout.ko.observableArray.Self<AnniDto>();
            Mesi = Retyped.knockout.ko.observableArray.Self<MesiDto>();
            Giorni = Retyped.knockout.ko.observableArray.Self<GiorniDto>();
            Strutture = Retyped.knockout.ko.observableArray.Self<StruttureDto>();

            AnnoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            MeseSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            GiornoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            StrutturaSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            LoaderPage = Retyped.knockout.ko.observable.Self<bool>();

            AnnoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Anno...");
            MeseFilterCaption = Retyped.knockout.ko.observable.Self<string>("Mese...");
            GiornoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Giorno...");
            StrutturaFilterCaption = Retyped.knockout.ko.observable.Self<string>("Strutture...");
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            LoaderPage.Self(true);

            base.OnLoad(parameters);

            await Hydrate(parameters);

            LoaderPage.Self(false);
        }

        private async Task Hydrate(Dictionary<string, object> parameters)
        {
            var anni = _indisponibilitaService.GetAnni();
            var strutture = _struttureService.GetAllStrutture();
            var mesi = _indisponibilitaService.GetMesi();
            var giorni = _giornoService.GetAllGiorni(2018, 7); //Always load 31 days
            await Task.WhenAll(anni, mesi, giorni, strutture);

            Mesi.removeAll();
            Giorni.removeAll();

            Anni.push(anni.Result.Items);
            Strutture.push(strutture.Result.Items);
            Mesi.push(mesi.Result.Items);
            Giorni.push(giorni.Result.Items);
            if (parameters.Count == 0)
            {
                var year = DateTime.Now.Year;
                var month = DateTime.Now.Month;

                var defaultDate = _cookieService.GetDefaultDate();
                if (defaultDate.HasValue)
                {
                    year = defaultDate.Value.Year;
                    month = defaultDate.Value.Month;
                }

                AnnoSelectedId.push(year);
                MeseSelectedId.push(month);
                LoadFilters();
            }
            else
            {
                int anno = (parameters.GetParameter<int>("anno"));
                int mese = (parameters.GetParameter<int>("mese"));
                int giorno = (parameters.GetParameter<int>("giorno"));

                //Set Selected filter with current dto
                AnnoSelectedId.push(anno);
                MeseSelectedId.push(mese);
                GiornoSelectedId.push(giorno);
                RemoveCaptionGiorno();
                
                var night = await _necessitaGiorniService.GetAlertNightNecessita(anno, mese, giorno, 0);
                NightNecessita.removeAll();
                NightNecessita.push(night);
            }
        }

        public async void LoadFilters()
        {
            LoaderPage.Self(true);
            int anno = 0;
            int mese = 0;
            int giorno = 0;
            int struttura = 0;

            if (AnnoSelectedId.Self().Length > 0)
                anno = AnnoSelectedId.Self()[0];
            if (MeseSelectedId.Self().Length > 0)
                mese = MeseSelectedId.Self()[0];
            if (GiornoSelectedId.Self().Length > 0)
                giorno = GiornoSelectedId.Self()[0];
            if (StrutturaSelectedId.Self().Length > 0)
                struttura = StrutturaSelectedId.Self()[0];

            var night = await _necessitaGiorniService.GetAlertNightNecessita(anno, mese, giorno, struttura);
            NightNecessita.removeAll();
            NightNecessita.push(night);

            LoaderPage.Self(false);
        }

        private void ClearFilters()
        {
            AnnoFilterCaption.Self("Anno...");
            MeseFilterCaption.Self("Mese...");
            GiornoFilterCaption.Self("Giorno...");
            AnnoSelectedId.removeAll();
            MeseSelectedId.removeAll();
            GiornoSelectedId.removeAll();
            StrutturaFilterCaption.Self("Strutture...");
            StrutturaSelectedId.removeAll();
        }

        #region Remove caption

        private void RemoveCaptionAnno()
        {
            AnnoFilterCaption.Self(null);
        }

        private void RemoveCaptionMese()
        {
            MeseFilterCaption.Self(null);
        }

        private void RemoveCaptionGiorno()
        {
            GiornoFilterCaption.Self(null);
        }

        private void RemoveCaptionGiornoStruttura()
        {
            StrutturaFilterCaption.Self(null);
        }

        #endregion
    }
}