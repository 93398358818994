using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge;
using Bridge.jQuery2;
using Bridge.Messenger;
using Bridge.Navigation;
using Retyped;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels
{
    public class HomeViewModel : BlockableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IGiorniService _giornoService;
        private readonly IFasceOrarieService _fasceOrarieService;
        private readonly IDashboardService _dashboardService;
        private readonly ITurnazioniMensiliService _turnazioniMensiliService;
        private readonly IMessenger _messenger;
        private readonly ICookieService _cookieService;
        private readonly IExportService _exportService;
        private TurnazioniMensiliInfoStatoDto _turnazioniMensili;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.HomeId;
}
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.GiorniDto>Giorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FasceOrarieDto>FasceOrarie { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FascieOrarieGiorniDto>FasceOrarieGiorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>Dashboards { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.MesiDto>Mesi { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.AnniDto>Anni { get; set; }

        public Retyped.knockout.KnockoutObservable <int>MeseCorrente { get; set; }
        public Retyped.knockout.KnockoutObservable <int>AnnoCorrente { get; set; }

        public Retyped.knockout.KnockoutObservable <int>StatoMese { get; set; }
        public Retyped.knockout.KnockoutObservable <int>NuovaTurnazione { get; set; }
        public Retyped.knockout.KnockoutObservable <int>PreparazioneInCorso { get; set; }
        public Retyped.knockout.KnockoutObservable <int>TurnazioneMensilePreparata { get; set; }
        public Retyped.knockout.KnockoutObservable <int>AbilitaNotificaChiusuraMese { get; set; }
        public Retyped.knockout.KnockoutObservable <int>Chiuso { get; set; }
        public Retyped.knockout.KnockoutObservable <int>AnnoSelezionato { get; set; }
        public Retyped.knockout.KnockoutObservable <int>MeseSelezionato { get; set; }

        public Retyped.knockout.KnockoutObservable <int>InvioMese { get; set; }

        public Retyped.knockout.KnockoutObservable <bool>FirstLoadPageLoader { get; set; }

        public HomeViewModel(INavigator navigator, IGiorniService GiornoService,
            IFasceOrarieService FasciaOrariaService,
            IDashboardService DashboardService, ITurnazioniMensiliService TurnazioniMensiliService,
            IMessenger messenger, ICookieService CookieService, IExportService exportService)
        {
            _navigator = navigator;
            _fasceOrarieService = FasciaOrariaService;
            _giornoService = GiornoService;
            _dashboardService = DashboardService;
            _turnazioniMensiliService = TurnazioniMensiliService;
            _messenger = messenger;
            _cookieService = CookieService;
            this._exportService = exportService;
            Giorni = Retyped.knockout.ko.observableArray.Self<GiorniDto>();
            FasceOrarie = Retyped.knockout.ko.observableArray.Self<FasceOrarieDto>();
            FasceOrarieGiorni = Retyped.knockout.ko.observableArray.Self<FascieOrarieGiorniDto>();
            Dashboards = Retyped.knockout.ko.observableArray.Self<DashboardDto>();

            Mesi = Retyped.knockout.ko.observableArray.Self<MesiDto>();
            Anni = Retyped.knockout.ko.observableArray.Self<AnniDto>();

            NuovaTurnazione = Retyped.knockout.ko.observable.Self<int>();
            PreparazioneInCorso = Retyped.knockout.ko.observable.Self<int>();
            TurnazioneMensilePreparata = Retyped.knockout.ko.observable.Self<int>();
            AbilitaNotificaChiusuraMese = Retyped.knockout.ko.observable.Self<int>();
            StatoMese = Retyped.knockout.ko.observable.Self<int>();
            Chiuso = Retyped.knockout.ko.observable.Self<int>();
            InvioMese = Retyped.knockout.ko.observable.Self<int>();
            AnnoSelezionato = Retyped.knockout.ko.observable.Self<int>();
            MeseSelezionato = Retyped.knockout.ko.observable.Self<int>();

            MeseCorrente = Retyped.knockout.ko.observable.Self<int>();
            AnnoCorrente = Retyped.knockout.ko.observable.Self<int>();

            FirstLoadPageLoader = knockout.ko.observable.Self<bool>(true);
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            Script.Write("$(\".select2\").select2()");

            FirstLoadPageLoader.Self(true);

            base.OnLoad(parameters);

            await Hydrate();

            FirstLoadPageLoader.Self(false);

            // gestione anno/mese
            Select2.Render((global::System.Action<object>)(async o => { await CambiaAnnoMese(AnnoCorrente.Self(), MeseCorrente.Self()); }), "#mese");

            Select2.Render((global::System.Action<object>)(async o => { await CambiaAnnoMese(AnnoCorrente.Self(), MeseCorrente.Self()); }), "#anno");
            //-------------
        }

        #region VaiAMeseSuccessivo
        public async void VaiAMeseSuccessivo()
        {
            var actualMonth = this.MeseCorrente.Self();
            if (actualMonth == 12)
            {
                actualMonth = 1;
                var actualYear = this.AnnoCorrente.Self() + 1;
                AnnoCorrente.Self(actualYear);
                MeseCorrente.Self(actualMonth);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth)).Trigger("change");
                jQuery.Select("#anno").Val(Convert.ToString(actualYear)).Trigger("change");
            }
            else
            {
                this.MeseCorrente.Self(actualMonth + 1);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth + 1)).Trigger("change");
            }


            await CambiaAnnoMese(AnnoCorrente.Self(), MeseCorrente.Self());
        }
        #endregion

        #region VaiAMesePrecedente
        public async void VaiAMesePrecedente()
        {
            var actualMonth = this.MeseCorrente.Self();
            if (actualMonth == 1)
            {
                actualMonth = 12;
                var actualYear = this.AnnoCorrente.Self() - 1;
                AnnoCorrente.Self(actualYear);
                MeseCorrente.Self(actualMonth);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth)).Trigger("change");
                jQuery.Select("#anno").Val(Convert.ToString(actualYear)).Trigger("change");
            }
            else
            {
                this.MeseCorrente.Self(actualMonth - 1);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth - 1)).Trigger("change");
            }


            await CambiaAnnoMese(AnnoCorrente.Self(), MeseCorrente.Self());
        }
        #endregion

        #region RichiediChiusuraTurnazioneMensile
        public void RichiediChiusuraTurnazioneMensile()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma richiesta chiusura mese",
                Closable = false,
                Message = "Confermi la richiesta chiusura del mese?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                await _turnazioniMensiliService.ChangeStatoTurnazioneMensile(idAnno, idMese,
                                    (int) StatoTurnazioneMensile.Closing, 0);
                                await LoadFasceGiorni(idAnno, idMese);
                                e.ToDynamic().close();
                            }
                            catch (Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di richiesta chiusura mese");
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region RichiediInvioReport
        public void RichiediInvioReport()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma richiesta invio report turnazione mensile",
                Closable = false,
                Message = "Confermi la richiesta invio report turnazione mensile?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                await _turnazioniMensiliService.RequireSendReport(idAnno, idMese);
                                this.InvioMese.Self(0);
                                e.ToDynamic().close();
                            }
                            catch (Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert(
                                    "ATTENZIONE! Errore in fase di richiesta invio report turnazione mensile");
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion
        
        #region VisualizzaSuApp
        public void VisualizzaSuApp ()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma visualizzazione dei dati sulle App agli Infermieri?",
                Closable = false,
                Message = "Confermi la pubbliazione agli Infermieri del mese selezionato?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                var result = await _turnazioniMensiliService.VisualizzaSuApp(idAnno, idMese);
                                e.ToDynamic().close();
                                if (result)
                                {
                                    BootstrapDialog.Alert("Dati pubblicati correttamente !");
                                }
                                else
                                {
                                    BootstrapDialog.Alert("ATTENZIONE: Dati NON pubblicati correttamente, chiamare assistenza !");
                                }
                            }
                            catch (Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di pubblicazione su App");
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion
        
        #region InvioTurniStrutture
        public void InvioTurniStrutture ()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma l'invio dei turni alle strutture?",
                Closable = false,
                Message = "Confermi l'invio dei turni alle strutture per il mese selezionato?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                var result = await _turnazioniMensiliService.InvioTurniStrutture(idAnno, idMese);
                                e.ToDynamic().close();
                                if (result)
                                {
                                    BootstrapDialog.Alert("Processo invio mail avviato !");
                                }
                                else
                                {
                                    BootstrapDialog.Alert("ATTENZIONE: Dati NON inviati alle strutture, chiamare assistenza !");
                                }
                            }
                            catch (Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di invio dati alle strutture");
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region NotificaChiusuraMese
        public void NotificaChiusuraMese()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma notifica chiusura mese",
                Closable = false,
                Message = "Confermi la notifica chiusura mese?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                await _turnazioniMensiliService.NotificaChiusuraMese(idAnno, idMese);
                                e.ToDynamic().close();
                            }
                            catch (Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert(string.Format("ATTENZIONE! Errore in fase di notifica chiusura mese: {0}",err));
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region RichiediPreparazioneTurnazioneMensile
        public void RichiediPreparazioneTurnazioneMensile()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma preparazione turnazione mensile",
                Closable = false,
                Message = "Confermi la preparazione della turnazione mensile?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                await _turnazioniMensiliService.ChangeStatoTurnazioneMensile(idAnno, idMese,
                                    (int) StatoTurnazioneMensile.Prepared, 0);
                                await LoadFasceGiorni(idAnno, idMese);
                                e.ToDynamic().close();
                            }
                            catch (Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert(
                                    "ATTENZIONE! Errore in fase di preparazione della turnazione mensile");
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion
        
        #region InvioReportMexal
        public void InvioReportMexal()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma Invio Report per Mexal",
                Closable = false,
                Message = "Vuoi ricevere il report per il mese selezionato?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                await this._exportService.ExportReportPerMexal(idAnno, idMese);
                                e.ToDynamic().close();
                            }
                            catch (Exception ex)
                            {
                                BootstrapDialog.Alert(string.Format("ATTENZIONE! Errore in fase di conferma invio Report per Mexal: {0}",ex.Message));
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region NuovaTurnazioneMensile
        public void NuovaTurnazioneMensile()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma nuova turnazione mensile",
                Closable = false,
                Message = "Confermi la creazione della nuova turnazione mensile?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            TurnazioniMensiliDto[] turnazioniMensiliDto =
                                await _turnazioniMensiliService.GetAllTurnazioniMensili(idAnno, idMese);

                            if (turnazioniMensiliDto == null || turnazioniMensiliDto.Length == 0)
                            {
                                Block();
                                try
                                {
                                    await _turnazioniMensiliService.PrepareTurnazioneMensile(idAnno, idMese, 0);
                                    await LoadFasceGiorni(idAnno, idMese);
                                    e.ToDynamic().close();
                                }
                                catch (Exception ex)
                                {
                                    string err = ex.Message;
                                    BootstrapDialog.Alert(
                                        "ATTENZIONE! Errore in fase di creazione nuova turnazione mensile");
                                }

                                UnBlock();
                            }
                            else
                            {
                                BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                                {
                                    Title = "Conferma nuova turnazione mensile",
                                    Closable = false,
                                    Message =
                                        "ATTENZIONE! Sono già presenti una o più turnazioni mensili per il periodo indicato.\nProcedendo le turnazioni verranno sostituite dalle nuove turnazioni.\nConfermi la creazione della nuova turnazione mensile?",
                                    OnShown = () => { },
                                    Buttons = new BootstrapDialog.BootstrapDialogButton[]
                                    {
                                        new BootstrapDialog.BootstrapDialogButton()
                                        {
                                            Label = "Chiudi",
                                            Action = (self) => { self.ToDynamic().close(); }
                                        },
                                        new BootstrapDialog.BootstrapDialogButton()
                                        {
                                            Label = "Conferma",
                                            Action = async (v) =>
                                            {
                                                Block();
                                                try
                                                {
                                                    await _turnazioniMensiliService.PrepareTurnazioneMensile(idAnno,
                                                        idMese, 0);
                                                    await LoadFasceGiorni(idAnno, idMese);
                                                    e.ToDynamic().close();
                                                    v.ToDynamic().close();
                                                }
                                                catch (Exception ex)
                                                {
                                                    string err = ex.Message;
                                                    BootstrapDialog.Alert(
                                                        "ATTENZIONE! Errore in fase di creazione nuova turnazione mensile");
                                                }

                                                UnBlock();
                                            }
                                        }
                                    }
                                });
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region LoadFasceGiorni
        private async Task LoadFasceGiorni(int idAnno, int idMese)
        {
            int statoMese = 0;
            int chiuso = 1;
            int numeroAnomalie = 0;

            this.Giorni.removeAll();
            this.FasceOrarie.removeAll();
            this.FasceOrarieGiorni.removeAll();
            this.InvioMese.Self(0);
            this.NuovaTurnazione.Self(0);
            this.PreparazioneInCorso.Self(0);
            this.TurnazioneMensilePreparata.Self(0);
            this.AbilitaNotificaChiusuraMese.Self(0);

            var giorni = _giornoService.GetAllGiorni(0, idAnno, idMese);
            var fasce = _fasceOrarieService.GetAllFasceOrarie();
            var fasceGiorni = _dashboardService.GetDashboardPerMese(idAnno, idMese);
            this._turnazioniMensili = await _turnazioniMensiliService.TurnazioniMensiliInfoStato(idAnno, idMese, 0);

            await Task.WhenAll(fasce, giorni, fasceGiorni);

            DashboardDto[] dashboards = fasceGiorni.Result;


//            this.InvioMese.Self(0);
//            this.NuovaTurnazione.Self(0);
//            this.PreparazioneInCorso.Self(0);
//            this.TurnazioneMensilePreparata.Self(0);
//            this.AbilitaNotificaChiusuraMese.Self(0);this.Chiuso.Self(turnazioniMensili.Result.IsClosed ? 1 : 0);
//            // abilito il pulsante dell'invio Report solo se la turnazione mensile è chiusa e se ho da mandare qualche report che non ho ancora mandato...
//            if (turnazioniMensili.Result.InvioReport && turnazioniMensili.Result.IsClosed)
//                this.InvioMese.Self(1);
//            // abilito il pulsante della nuova turnazione globale solo se la turnazione mensile è tutta preparata e non è chiusa
//            if (turnazioniMensili.Result.TurnazioniMensiliPrepared && !turnazioniMensili.Result.IsClosed)
//                this.NuovaTurnazione.Self(1);
//            // abilito il pulsante della preparazione in corso se effettivamente ho qualcosa ce devo ancora fare e non è chiusa...
//            if (turnazioniMensili.Result.TurnazioniMensiliPreparing && !turnazioniMensili.Result.IsClosed)
//                this.PreparazioneInCorso.Self(1);
//            // abilito il pulsante della turnazione mensile preparata solo se la turnazione mensile è tutta preparata e non è chiusa
//            if (turnazioniMensili.Result.TurnazioniMensiliPrepared && !turnazioniMensili.Result.IsClosed)
//                this.TurnazioneMensilePreparata.Self(1);
//            // abilito il pulsante della notifica agli infermieri a cui non l'ho ancora inviata se non ho più anomalie ed il turno può essere confermato
//            var anomalieGlobali = dashboards.Any() ? dashboards.Sum(x => x.NumeroAnomalie) : 0;
//            if (anomalieGlobali==0 )
//                this.AbilitaNotificaChiusuraMese.Self(1);


            if (_turnazioniMensili.IsClosed)
            {
                chiuso = 1;
                if (_turnazioniMensili.InvioReport)
                    this.InvioMese.Self(1);
                else
                    this.InvioMese.Self(0);
            }
            else
            {
                chiuso = 0;
                this.InvioMese.Self(0);
            }

            try
            {
                foreach (FasceOrarieDto fascia in fasce.Result.Items)
                {
                    CrossFasceOrarieGiorniDto[] listaGiorni = new CrossFasceOrarieGiorniDto[giorni.Result.Length];
                    int index = 0;
                    foreach (GiorniDto giorno in giorni.Result)
                    {
                        CrossFasceOrarieGiorniDto crossFasciaGiorno = null;

                        DashboardDto item = System.Array.Find<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>(dashboards,
(global::System.Predicate<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>)(                            p => p.Giorno == giorno.Giorno && p.FasciaOraria == fascia.Fascia));

                        if (item != null)
                        {
                            crossFasciaGiorno = new CrossFasceOrarieGiorniDto(fascia.Id, giorno.Id, item.Anomalia,
                                item.NumeroAnomalie, item.FasciaOrariaDa, item.FasciaOrariaA);

                            if (item.NumeroAnomalie > 0)
                                this.AbilitaNotificaChiusuraMese.Self(0);

                            if (chiuso == 0)
                            {
                                if (item.NumeroAnomalie > 0)
                                    numeroAnomalie++;
                            }
                            else
                                statoMese = 0;
                        }
                        else
                        {
                            int fasciaDa = Convert.ToInt32(fascia.Fascia.Substring(0, 2));
                            crossFasciaGiorno =
                                new CrossFasceOrarieGiorniDto(fascia.Id, giorno.Id, 0, 0, fasciaDa, fasciaDa++);
                        }

                        listaGiorni[index] = crossFasciaGiorno;

                        index++;
                    }

                    FascieOrarieGiorniDto fasceOrarieGiorniDto = new FascieOrarieGiorniDto(fascia, listaGiorni);
                    this.FasceOrarieGiorni.push(fasceOrarieGiorniDto);
                }

                if (fasceGiorni.Result.Length > 0)
                {
                    if (numeroAnomalie > 0)
                        statoMese = 0;
                    else
                    {
                        if (chiuso == 1)
                            statoMese = -1;
                        else
                        {
                            if (_turnazioniMensili.TurnazioniMensiliPrepared &&
                                !_turnazioniMensili.TurnazioniMensiliPreparing)
                                this.TurnazioneMensilePreparata.Self(1);
                            else
                                this.TurnazioneMensilePreparata.Self(0);

                            if (_turnazioniMensili.TurnazioniMensiliClosing)
                                statoMese = 1;
                            else
                                statoMese = 0;
                        }
                    }
                }
                else
                    statoMese = 0;

                if (!_turnazioniMensili.IsClosed && !_turnazioniMensili.TurnazioniMensiliClosing)
                {
                    if (!_turnazioniMensili.TurnazioniMensiliPreparing)
                    {
                        this.NuovaTurnazione.Self(1);
                        this.PreparazioneInCorso.Self(0);
                    }
                    else
                    {
                        this.NuovaTurnazione.Self(0);
                        this.PreparazioneInCorso.Self(1);
                    }
                }
                else
                    this.NuovaTurnazione.Self(0);

                if (!_turnazioniMensili.TurnazioniMensiliPreparing &&
                    !_turnazioniMensili.TurnazioniMensiliPrepared &&
                    !_turnazioniMensili.TurnazioniMensiliClosing && !_turnazioniMensili.IsClosed)
                    this.NuovaTurnazione.Self(1);

                this.StatoMese.Self(Convert.ToInt32(statoMese));
                this.Chiuso.Self(Convert.ToInt32(chiuso));


                this.InvioMese.Self(0);
                this.NuovaTurnazione.Self(0);
                this.PreparazioneInCorso.Self(0);
                this.TurnazioneMensilePreparata.Self(0);
                this.AbilitaNotificaChiusuraMese.Self(0);

                // abilito il pulsante della nuova turnazione globale solo se la turnazione mensile è tutta preparata e non è chiusa
                if (_turnazioniMensili.TurnazioniMensiliPrepared && !_turnazioniMensili.IsClosed)
                    this.NuovaTurnazione.Self(1);
                // abilito il pulsante di chiusura mese
                this.Chiuso.Self(_turnazioniMensili.IsClosed ? 1 : 0);
                // abilito il pulsante dell'invio Report solo se la turnazione mensile è chiusa e se ho da mandare qualche report che non ho ancora mandato...
                if (_turnazioniMensili.InvioReport && _turnazioniMensili.IsClosed)
                    this.InvioMese.Self(1);
                // abilito il pulsante della preparazione in corso se effettivamente ho qualcosa ce devo ancora fare e non è chiusa...
                if (_turnazioniMensili.TurnazioniMensiliPreparing && !_turnazioniMensili.IsClosed)
                    this.PreparazioneInCorso.Self(1);
                // abilito il pulsante della turnazione mensile preparata solo se la turnazione mensile è tutta preparata e non è chiusa
                    if (_turnazioniMensili.TurnazioniMensiliPrepared && !_turnazioniMensili.IsClosed && System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>(dashboards) && System.Linq.Enumerable.Sum<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>(dashboards,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto, int>)(x => x.NumeroAnomalie)) == 0)
                    this.TurnazioneMensilePreparata.Self(1);
                // abilito il pulsante della notifica agli infermieri a cui non l'ho ancora inviata se non ho più anomalie ed il turno può essere confermato
                var anomalieGlobali = System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>(dashboards) ? System.Linq.Enumerable.Sum<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>(dashboards,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto, int>)(x => x.NumeroAnomalie)) : 0;
                if (anomalieGlobali == 0 && System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.DashboardDto>(dashboards))
                    this.AbilitaNotificaChiusuraMese.Self(1);
            }
            catch (Exception ex)
            {
                Console.WriteLine("Error: " + ex.Message);
            }

            this.AnnoCorrente.Self(Convert.ToInt32(idAnno));
            this.MeseCorrente.Self(Convert.ToInt32(idMese));
            this.Giorni.push(giorni.Result);
            this.FasceOrarie.push(fasce.Result.Items);
        }
        #endregion

        #region Hydrate
        private async Task Hydrate()
        {
            var mesi = _dashboardService.GetMesi();
            var anni = _dashboardService.GetAnni();
            await Task.WhenAll(mesi, anni);
            int idAnno = DateTime.Now.Year;
            int idMese = DateTime.Now.Month;


            var defaultDate = _cookieService.GetDefaultDate();
            if (defaultDate.HasValue)
            {
                idAnno = defaultDate.Value.Year;
                idMese = defaultDate.Value.Month;
            }

            AnnoSelezionato.Self(idAnno);
            MeseSelezionato.Self(idMese);
            this.Mesi.push(mesi.Result.Items);
            this.Anni.push(anni.Result.Items);
            await LoadFasceGiorni(idAnno, idMese);
        }
        #endregion

        #region CambiaAnnoMese
        public async Task CambiaAnnoMese(int idAnno, int idMese)
        {
            Block();
            AnnoSelezionato.Self(idAnno);
            MeseSelezionato.Self(idMese);
            await LoadFasceGiorni(idAnno, idMese);
            UnBlock();
        }
        #endregion

        #region GoToAnomalie
        public void GoToAnomalie(CrossFasceOrarieGiorniDto anomalia)
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("anno",AnnoSelezionato.Self());_o1.Add("mese",this.MeseSelezionato.Self());_o1.Add("giorno",anomalia.IdGiorno);_o1.Add("fasciaOraria",anomalia.IdFasciaOraria);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.AnomaliaId, parameters);
        }
        #endregion
        
        #region Info
        public void Info()
        {
            BootstrapDialog.Show(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Informazioni",
                Message = string.Format("<p>Turnazioni totali: <strong>{0}</strong></p>",_turnazioniMensili.CountAllTurnazioniMensili)+
string.Format("<p>Turnazioni ancora da preparare: <strong>{0}</strong></p>",_turnazioniMensili.CountTurnazioniMensiliPreparing)+
string.Format("<p>Turnazioni già preparate in attesa di richiesta chiusura: <strong>{0}</strong></p>",_turnazioniMensili.CountTurnazioniMensiliPrepared)+ 
string.Format("<p>Turnazioni da chiudere: <strong>{0}</strong></p>",_turnazioniMensili.CountTurnazioniMensiliClosing)+
string.Format("<p>Turnazioni chiuse: <strong>{0}</strong></p>",_turnazioniMensili.CountIsClosed)+
string.Format("<p>Report da inviare alle strutture: <strong>{0}</strong></p>",_turnazioniMensili.CountNumberReportDaInviare)+
string.Format("<p>Report già inviati alle strutture: <strong>{0}</strong></p>",_turnazioniMensili.CountInvioReport),
                Buttons = new[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                },
                Closable = false
            });
        }
        #endregion
    }
}