using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class FasceOrarieResource: AuthorizedResourceBase, IFasceOrarieResource
    {
      

        public Task<BaseDtoOutputModel<FasceOrarieDto>> GetAllFasceOrarie()
        {
            BaseDtoOutputModel<FasceOrarieDto> fasce = new BaseDtoOutputModel<FasceOrarieDto>();

            int numeroFasce = 24;

            FasceOrarieDto[] items = new FasceOrarieDto[numeroFasce];

            int index = 0;

            while (index < numeroFasce)
            {
                items[index] = new FasceOrarieDto();
                items[index].Id = index + 1;
                items[index].Fascia = index.ToString().PadLeft(2, '0') + ":00";
                //items[index].Fascia = index.ToString() + "-" +  Convert.ToString(index + 1);

                index++;
            }

            fasce.Items = items;

            return Task.FromResult<BaseDtoOutputModel<FasceOrarieDto>>(fasce);
        }

        public Task<string> GetFasciaOrariaById(int id)
        {
            int id1 = id - 1;
            return  Task.FromResult<string>(id1.ToString().PadLeft(2, '0') + ":00");
        }

        public FasceOrarieResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
