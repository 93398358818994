using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public enum StatoInfermiere { Indisponibile = 1, Allocato = 2 };

    public class CalendariDto
    {
        [JsonProperty("idindisponibilita")]
        public int IdIndisponibilita { get; set; }

        [JsonProperty("periododa")]
        public string PeriodoDa { get; set; }

        [JsonProperty("periodoa")]
        public string PeriodoA { get; set; }

        [JsonProperty("note")]
        public string Note { get; set; }

        [JsonProperty("struttura")]
        public string Struttura { get; set; }

        [JsonProperty("causale")]
        public string Causale { get; set; }

        [JsonProperty("giorno")]
        public string Giorno { get; set; }

        [JsonProperty("fasciaoraria")]
        public string FasciaOraria { get; set; }

        [JsonProperty("fasciaorariada")]
        public int FasciaOrariaDa { get; set; }

        [JsonProperty("fasciaorariaa")]
        public int FasciaOrariaA { get; set; }

        [JsonProperty("stato")]
        public StatoInfermiere Stato { get; set; }

        public CalendariDto(int idIndisponibilita, string struttura, string periodoDa, string periodoA, string note, string causale, string giorno, string fasciaOraria, int fasciaOrariaDa, int fasciaOrariaA, StatoInfermiere stato)
        {
            IdIndisponibilita = idIndisponibilita;
            PeriodoDa = periodoDa;
            PeriodoA = periodoA;
            Note = note;
            Struttura = struttura;
            Causale = causale;
            Giorno = giorno;
            FasciaOraria = fasciaOraria;
            FasciaOrariaDa = fasciaOrariaDa;
            FasciaOrariaA = fasciaOrariaA;
            Stato = stato;
        }
    }
}
