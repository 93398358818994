using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CrossStruttureGiorniDto
    {
        [JsonProperty("idgiorno")]
        public int IdGiorno { get; set; }

        [JsonProperty("idstruttura")]
        public int IdStruttura { get; set; }

        [JsonProperty("percentualelavoro")]
        public int PercentualeLavoro { get; set; }

        public CrossStruttureGiorniDto(int idStruttura, int idGiorno, int percentualeLavoro)
        {
            IdStruttura = idStruttura;
            IdGiorno = idGiorno;
            PercentualeLavoro = percentualeLavoro;
        }
    }
}
