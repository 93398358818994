using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class TipiTurniPerCalendarioPeriodoPrototipoDto
    {        
        [JsonProperty("causale")]
        public string Causale { get; set; }

        [JsonProperty("crosstipiturni")]
        public CrossTipiTurnoPerCalendarioPeriodoPrototipoDto[] CrossTipiTurni { get; set; }

        [JsonProperty("hasCrossTipiTurni")]
        public bool HasCrossTipiTurni { get; set; }

        public TipiTurniPerCalendarioPeriodoPrototipoDto(string causale, CrossTipiTurnoPerCalendarioPeriodoPrototipoDto[] crossTipiTurni)
        {
            Causale = causale;
            CrossTipiTurni = crossTipiTurni;
        }
    }
}
