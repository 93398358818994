using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class TimbratureService : ITimbratureService
    {
        private readonly ITimbratureResource _timbratureResource;

        public TimbratureService(ITimbratureResource timbratureResource)
        {
            _timbratureResource = timbratureResource;
        }

        public Task<NecessitaGiornoDto[]> GetTimbrature(int infermiereId, int strutturaId, string da, string a)
        {
            return _timbratureResource.GetTimbrature(infermiereId, strutturaId, da,a);
        }
    }
}
