using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using Bridge.Navigation;
using Bridge.Spaf;
using Bridge.Spaf.Attributes;
using Retyped;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels
{
    [SingleInstance]
    public class MainViewModel : LoadableViewModel
    {
        private readonly IUtentiResource _utentiResource;
public override string ElementId()
{
    return "sidebarnav";
}        private TestataViewModel _testataViewModel;

        public Retyped.knockout.KnockoutObservable <string>ActualPageId { get; set; }

        public MainViewModel(INavigator navigator, IUtentiResource utentiResource)
        {
            _utentiResource = utentiResource;
            this.ActualPageId = Retyped.knockout.ko.observable.Self<string>();
            this._testataViewModel = Bridge.Spaf.SpafApp.Container.Resolve<TestataViewModel>();

            navigator.OnNavigated += (sender, loadable) =>
            {
                var vm = (LoadableViewModel) loadable;
                this.ActualPageId.Self(vm.ElementId());
            };
        }

        public async Task Init()
        {
            var loggedUSer = await this._utentiResource.GetUser();
            this._testataViewModel.ImpostaUtente(loggedUSer);
            this._testataViewModel.ImpostaMesiDropDown();

            this.OnLoad(null);
            this._testataViewModel.OnLoad(null);
        }

        /// <summary>
        /// Apply binding to mainmodel
        /// try auto login
        /// </summary>
        public async void Start()
        {
            knockout.ko.applyBindings(this);
        }
    }
}