using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class StruttureDto
    {
        [JsonProperty("id")]
        public int Id { get; set; }

        [JsonProperty("ragionesociale")]
        public string RagioneSociale { get; set; }
        
        [JsonProperty("nomesemplice")]
        public string NomeSemplice { get; set; }

        [JsonProperty("prioritaStruttra")]
        public int PrioritaStruttra { get; set; }

        [JsonProperty("cantiere")]
        public bool Cantiere { get; set; }

        [JsonProperty("lavoraFestivi")]
        public bool LavoraFestivi { get; set; }

        [JsonProperty("oreMinime")]
        public int OreMinime { get; set; }

        [JsonProperty("mexalId")]
        public string MexalId { get; set; }

        [JsonProperty("gapDisponibilitaInfermiere")]
        public string GapDisponibilitaInfermiere { get; set; }

        [JsonProperty("email")]
        public string EMail { get; set; }

        [JsonProperty("patrono")]
        public string Patrono { get; set; }
public string Url
{
    get
    {
        return string.Format("#struttureEdit?strutturaId={0}",Id);
    }
}    }
}
