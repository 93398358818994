using System;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Bridge.Navigation;
using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class TipiTurniResource : AuthorizedResourceBase, ITipiTurniResource
    {
     

        public Task<TipiTurniDto> CreateTipiTurni(int structId, TipiTurniDto dto)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/tipiturni",structId)),
                Type = "POST",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(dto)
            };

            return base.MakeCall<TipiTurniDto>(options);
        }

        public Task<TipiTurniDto> EditTipiTurni(int structId, TipiTurniDto dto)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/tipiturni/{1}",structId,dto.Id)),
                Type = "PUT",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(dto)
            };

            return base.MakeCall<TipiTurniDto>(options);
        }

        public Task DeleteTipiTurni(int structId, int editId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/tipiturni/{1}",structId,editId)),
                Type = "DELETE",
            };

            return base.MakeCall<object>(options);
        }

        public Task<string[]> GetAllCausaliLabels(int structId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/tipiturni/getCausaliLabels",structId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<string[]>(options);
        }

        public Task<TipiTurniDto[]> GetAllTipiTurniByStructureId(int structId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/tipiturni",structId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<TipiTurniDto[]>(options);
        }

        public Task<TipiTurniDto> GetTipiTurniById(int structId, int editId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/tipiturni/{1}",structId,editId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<TipiTurniDto>(options);
        }

        public Task DeleteInfermiere(int structId, int editId)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/tipiturni/{1}/DeleteInfermiere",structId,editId)),
                Type = "POST",
            };

            return base.MakeCall<object>(options);
        }

        public TipiTurniResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
