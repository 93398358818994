using Aip.Florence.FrontEnd.SpafApp.Classes;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge;
using Bridge.Html5;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Clienti
{
    public class EditClientiViewModel: LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IClientiService _clientiService;
        private readonly IStruttureService _struttureService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.EditClientiId;
}
        public int EditId { get; set; }
        public ClientiKo clientiKo { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.StruttureDto>Strutture { get; set; }

        public EditClientiViewModel(INavigator navigator, IClientiService ClientiService, IStruttureService struttureService)
        {
            _navigator = navigator;
            _clientiService = ClientiService;
            _struttureService = struttureService;

            clientiKo = new ClientiKo(null);
            Strutture = Retyped.knockout.ko.observableArray.Self<StruttureDto>();
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            EditId = parameters.GetParameter<int>("id");

            if (EditId != 0)
            {
                //var clientiDtoTask = _clientiService.GetClientiById(EditId);
                //var struttureListTask = _struttureService.GetAllStruttureByClientId(EditId);
                var struttureListTask = await _struttureService.GetAllStrutture();
                //await Task.WhenAll(clientiDtoTask, struttureListTask);

                var temp = new ClientiDto
                {
                    Id = 1,
                    Nome = "Pippo",
                    MexalId = "123"
                };

                clientiKo = new ClientiKo(temp);
                Strutture.push(struttureListTask.Items);
            }

            base.OnLoad(parameters);
        }

        public void EditStruttura(StruttureDto struttura)
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",struttura.Id);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.EditStruttureId, parameters);
        }

        public class ClientiKo
        {
            public int ClientiId { get; set; }
            public Retyped.knockout.KnockoutObservable <string>Nome { get; set; }
            public Retyped.knockout.KnockoutObservable <string>MexalId { get; set; }

            public ClientiKo(ClientiDto dto)
            {
                this.ClientiId = ((dto!=null?dto.Id:(int?)null) ?? 0);
                this.Nome = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.Nome);
                this.MexalId = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.MexalId);
            }
        }
    }
}
