using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class TurnazioniMensiliInfoStatoDto
    {
        [JsonProperty("isclosed")]
        public bool IsClosed { get; set; }

        [JsonProperty("turnazionimensilipreparing")]
        public bool TurnazioniMensiliPreparing { get; set; }

        [JsonProperty("turnazionimensiliprepared")]
        public bool TurnazioniMensiliPrepared { get; set; }

        [JsonProperty("turnazionimensiliclosing")]
        public bool TurnazioniMensiliClosing { get; set; }

        [JsonProperty("invioreport")]
        public bool InvioReport { get; set; }
        
        [JsonProperty("invioreport")]
        public int CountIsClosed { get; set; }
        
        [JsonProperty("countTurnazioniMensiliPreparing")]
        public int CountTurnazioniMensiliPreparing { get; set; }
        
        [JsonProperty("countTurnazioniMensiliPrepared")]
        public int CountTurnazioniMensiliPrepared { get; set; }
        
        [JsonProperty("countTurnazioniMensiliClosing")]
        public int CountTurnazioniMensiliClosing { get; set; }
        
        [JsonProperty("countInvioReport")]
        public int CountInvioReport { get; set; }
        
        [JsonProperty("countAllTurnazioniMensili")]
        public int CountAllTurnazioniMensili { get; set; }

        [JsonProperty("countNumberReportDaInviare")]
        public int CountNumberReportDaInviare { get; set; }

        public TurnazioniMensiliInfoStatoDto(bool isClosed, bool turnazioniMensiliPreparing, bool turnazioniMensiliPrepared, bool turnazioniMensiliClosing, bool invioReport)
        {
            IsClosed = isClosed;
            TurnazioniMensiliPreparing = turnazioniMensiliPreparing;
            TurnazioniMensiliPrepared = turnazioniMensiliPrepared;
            TurnazioniMensiliClosing = turnazioniMensiliClosing;
            InvioReport = invioReport;
        }
    }
}
