using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Html5;
using Bridge.Navigation;
using Bridge.Spaf;
using System.Collections.Generic;
using System.Threading.Tasks;
using Retyped;
using System;
using System.Linq;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge;
using Bridge.jQuery2;
using System.Globalization;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Indisponibilita
{
    public class ListaIndisponibilitaViewModel: LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IInfermieriService _infermieriService;
        private readonly IIndisponibilitaService _indisponibilitaService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.Indisponibilita;
}
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaGroupByInfermiereDto>Indisponibilita { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>Loader { get; set; }

        public int InfermiereId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>DataInizio { get; set; }
        public Retyped.knockout.KnockoutObservable <string>DataFine { get; set; }

        public Retyped.knockout.KnockoutObservable <int>InfermieriCorrente { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>Infermieri { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>InfermieriIndisponibili { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>EnableInfermieri { get; set; }

        public ListaIndisponibilitaViewModel(INavigator navigator, IInfermieriService infermieriService, IIndisponibilitaService indisponibilitaService)
        {
            _navigator = navigator;
            _indisponibilitaService = indisponibilitaService;
            _infermieriService = infermieriService;
            Indisponibilita = Retyped.knockout.ko.observableArray.Self<IndisponibilitaGroupByInfermiereDto>();
            Loader = Retyped.knockout.ko.observable.Self<bool>();

            this.InfermiereId = 0;
            string dataInizio = DateTime.Now.Year.ToString() + "-" + DateTime.Now.Month.ToString().PadLeft(2, '0') + "-01";
            string dataFine = DateTime.Now.Year.ToString() + "-" + DateTime.Now.Month.ToString().PadLeft(2, '0') + "-" + DateTime.Now.Day.ToString().PadLeft(2, '0');

            DataInizio = Retyped.knockout.ko.observable.Self<string>(dataInizio);
            DataFine = Retyped.knockout.ko.observable.Self<string>(dataFine);
            EnableInfermieri = Retyped.knockout.ko.observable.Self<bool>();
            InfermieriCorrente = Retyped.knockout.ko.observable.Self<int>(0);
            Infermieri = Retyped.knockout.ko.observableArray.Self<InfermieriDto>();
            InfermieriIndisponibili = Retyped.knockout.ko.observableArray.Self<InfermieriDto>();
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            try
            {
                Script.Write("$(\".select2\").select2()");

                Loader.Self(true);

                if (parameters != null && parameters.Count > 0)
                {
                    this.InfermiereId = parameters.GetParameter<int>("infermiereId");
                    this.InfermieriCorrente.Self(this.InfermiereId);
                    EnableInfermieri.Self(false);
                }
                else
                    EnableInfermieri.Self(true);

                base.OnLoad(parameters);

                var infermieri = await _infermieriService.GetAllInfermieri();
                Infermieri.push(infermieri.Items);

                if (parameters != null && parameters.Count > 0)
                    jQuery.Select("#infermiere").Val(Convert.ToString(this.InfermiereId)).Trigger("change");

                BootstrapMaterialDatePicker.Render(new BootstrapMaterialDatePickerOption()
                {
                    Time = false,
                    WeekStart = 0
                }, ".dateTimePicker");

                Select2.Render((global::System.Action<object>)(o =>
                {

                }), "#infermiere");

                await HydrateIndisponibilita();

                Loader.Self(false);
            }
            catch (Exception e)
            {
                Console.WriteLine(e);
                throw;
            }
        }

        public override void OnLeave()
        {
            Script.Call("RemoveDateTimePickerListaIndisponibilita");

            base.OnLeave();
        }

        public async void CalcolaLista()
        {
            Loader.Self(true);
            
            await HydrateIndisponibilita();

            Loader.Self(false);
        }

        private async Task HydrateIndisponibilita()
        {
            int infermiereId = 0;
            
            this.Indisponibilita.removeAll(); // clear list
            //var ddt = Script.Call<dynamic>("getDataTable", jQuery.Select("#tblIndisponibilita")); // clear dt
            //ddt.clear();
        
            if (this.InfermieriCorrente.Self() > 0)
                infermiereId = this.InfermieriCorrente.Self();
            else
                infermiereId = 0;

            var indisponibilita = await _indisponibilitaService.GetAllByInfermiere(infermiereId, this.DataInizio.Self(), this.DataFine.Self());

            IEnumerable<IndisponibilitaDto> orderedList = System.Linq.Enumerable.OrderBy<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto,string>(indisponibilita,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto, string>)(x => x.Infermiere.NomeCompleto)).ThenBy<global::System.DateTime>((global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto, global::System.DateTime>)(n => n.Data)); 

            List<IndisponibilitaGroupByInfermiereDto> indisponibilitaGroupByInfermiereDto = new List<IndisponibilitaGroupByInfermiereDto>();
            
            int count = System.Linq.Enumerable.Count<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto>(orderedList);
            int indice = 0;
            int Id = 1;
            foreach (IndisponibilitaDto ind in orderedList)
            {
                double totalHours = 0;
                IEnumerable<IndisponibilitaDto> findTimes = System.Linq.Enumerable.Where<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto>(System.Linq.Enumerable.ToList<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto>(orderedList),(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto, bool>)(z => z.InfermiereId == ind.InfermiereId));

                try
                {
                    foreach (IndisponibilitaDto tick in findTimes)
                    {
                        DateTime periodoDa = DateTime.ParseExact(tick.Periodo.Da, "HH:mm", new DateTimeFormatInfo());
                        DateTime periodoA = DateTime.ParseExact(tick.Periodo.A, "HH:mm", new DateTimeFormatInfo());

                        TimeSpan diff = periodoA - periodoDa;

                        totalHours += diff.TotalHours;
                    }
                }
                catch { }

                IndisponibilitaGroupByInfermiereDto indisponibilitaInfermiere = new IndisponibilitaGroupByInfermiereDto();
                indisponibilitaInfermiere.InfermiereId = ind.InfermiereId;
                indisponibilitaInfermiere.Data = ind.Data.Year.ToString() + "-" + ind.Data.Month.ToString().PadLeft(2, '0') + "-" + ind.Data.Day.ToString().PadLeft(2, '0');
                indisponibilitaInfermiere.Da = ind.Periodo.Da;
                indisponibilitaInfermiere.A = ind.Periodo.A;
                indisponibilitaInfermiere.Infermiere = ind.Infermiere.NomeCompleto;
                indisponibilitaInfermiere.DataIndisponibilita = ind.DataIndisponibilita;
                indisponibilitaInfermiere.TotaleOre = totalHours;
                indisponibilitaInfermiere.Id = Id;
                Id++;

                indisponibilitaGroupByInfermiereDto.Add(indisponibilitaInfermiere);

                if(indice + 1 < count)
                {
                    if(ind.InfermiereId != System.Linq.Enumerable.ToArray<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto>(orderedList)[indice + 1].InfermiereId)
                    {
                        IndisponibilitaGroupByInfermiereDto totale = new IndisponibilitaGroupByInfermiereDto();
                        totale.InfermiereId = ind.InfermiereId;
                        totale.Data = "";
                        totale.Da = "";
                        totale.A = "";
                        totale.Infermiere = "Totale ore " + totalHours.ToString();
                        totale.DataIndisponibilita = "";
                        totale.TotaleOre = totalHours;
                        totale.Id = Id;
                        Id++;

                        indisponibilitaGroupByInfermiereDto.Add(totale);
                    }
                }
                else
                {
                    IndisponibilitaGroupByInfermiereDto totale = new IndisponibilitaGroupByInfermiereDto();
                    totale.InfermiereId = ind.InfermiereId;
                    totale.Data = "";
                    totale.Da = "";
                    totale.A = "";
                    totale.Infermiere = "Totale ore " + totalHours.ToString();
                    totale.DataIndisponibilita = "";
                    totale.TotaleOre = totalHours;
                    totale.Id = Id;
                    Id++;

                    indisponibilitaGroupByInfermiereDto.Add(totale);
                }

                indice++;
            }


            this.Indisponibilita.push(indisponibilitaGroupByInfermiereDto.ToArray());
        }
    }
}
