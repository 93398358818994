using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Html5;
using Bridge.Navigation;
using Bridge.Spaf;
using System.Collections.Generic;
using System.Threading.Tasks;
using Retyped;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Clienti
{
    public class ClientiViewModel: LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IClientiService _clientiService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.ClientiId;
}
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.ClientiDto>Clienti { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>Loader { get; set; }

        public ClientiViewModel(INavigator navigator, IClientiService ClientiService)
        {
            _navigator = navigator;
            _clientiService = ClientiService;
            Clienti = Retyped.knockout.ko.observableArray.Self<ClientiDto>();
            Loader = knockout.ko.observable.Self<bool>();
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            Loader.Self(true);
            base.OnLoad(parameters);

            await HydrateClienti();
            Loader.Self(false);
        }

        private async Task HydrateClienti()
        {
            //var clienti = await _clientiService.GetAllClienti();

            var collection = new ClientiDto[1];
            collection[0] = new ClientiDto {
                Id = 1,
                Nome = "Pippo",
                MexalId = "123"
            };

            Clienti.push(collection);
        }

        public void EditCliente(ClientiDto cliDto)
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("id",cliDto.Id);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.EditClientiId, parameters);
        }
    }
}
