using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class UtentiResource : AuthorizedResourceBase, IUtentiResource
    {
        public UtentiResource(ISettings settings, INavigator navigator) : base(settings, navigator)
        {
        }

        public Task<UtenteDto> GetUser()
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild("/utenti"),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<UtenteDto>(options);
        }
        
    }
}