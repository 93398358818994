using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class FascieOrarieGiorniPerIndisponibilitaDto
    {
        [JsonProperty("fasciaoraria")]
        public FasceOrarieDto FasceOrarie { get; set; }

        [JsonProperty("crossgiorni")]
        public CrossFasceOrarieGiorniPerIndisponibilitaDto[] CrossGiorni { get; set; }

        public FascieOrarieGiorniPerIndisponibilitaDto(FasceOrarieDto fasciaOrariaDto, CrossFasceOrarieGiorniPerIndisponibilitaDto[] crossFasciaOrariaGiornoDto)
        {
            FasceOrarie = fasciaOrariaDto;
            CrossGiorni = crossFasciaOrariaGiornoDto;
        }
    }
}
