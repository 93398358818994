using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Services.Impl;
using Bridge.Html5;
using Bridge.Spaf;
using System;
using System.Globalization;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels
{
    public class TestataViewModel : LoadableViewModel
    {
        private readonly IKeyValueRepository _keyValueRepository;
        private readonly ISettings _settings;
        private readonly ICookieService _cookieService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.HeaderId;
}        
        public Retyped.knockout.KnockoutObservable <string>Email { get; set; }
        public Retyped.knockout.KnockoutObservable <string>Nominativo { get; set; }
        public Retyped.knockout.KnockoutObservable <string>SelectedDate { get; set; }
        public Retyped.knockout.KnockoutObservable <string>PreviousDate { get; set; }
        public Retyped.knockout.KnockoutObservable <string>CurrentDate { get; set; }
        public Retyped.knockout.KnockoutObservable <string>NextDate { get; set; }

        public TestataViewModel(IKeyValueRepository keyValueRepository, ISettings settings, ICookieService cookieService)
        {
            _keyValueRepository = keyValueRepository;
            _settings = settings;
            _cookieService = cookieService;
            this.Email = Retyped.knockout.ko.observable.Self<string>();
            this.Nominativo = Retyped.knockout.ko.observable.Self<string>();
            this.SelectedDate = Retyped.knockout.ko.observable.Self<string>();
            this.PreviousDate = Retyped.knockout.ko.observable.Self<string>();
            this.CurrentDate = Retyped.knockout.ko.observable.Self<string>();
            this.NextDate = Retyped.knockout.ko.observable.Self<string>();
        }
        
        public void ImpostaUtente(UtenteDto utenteDto)
        {
            this.Email.Self(utenteDto.Username);
            this.Nominativo.Self(string.Format("{0} {1}",utenteDto.Nome,utenteDto.Cognome));
        }

        public void ImpostaMesiDropDown() {
            //set year
            string previousDateYear;
            if (DateTime.Now.Month.Equals(1))
            {
                previousDateYear = DateTime.Now.AddYears(-1).Year.ToString();
            } else
            {
                previousDateYear = DateTime.Now.Year.ToString();
            }

            var currentDateYear = DateTime.Now.Year.ToString();

            string nextDateYear;
            if (DateTime.Now.Month.Equals(12))
            {
                nextDateYear = DateTime.Now.AddYears(+1).Year.ToString();
            } else
            {
                nextDateYear = DateTime.Now.Year.ToString();
            }

            //set month
            var previousDateMonth = DateTime.Now.AddMonths(-1).Month;
            var currentDateMonth = DateTime.Now.Month;
            var nextDateMonth = DateTime.Now.AddMonths(+1).Month;


            var year = DateTime.Now.Year;
            var month = DateTime.Now.Month;
            var defaultDate = _cookieService.GetDefaultDate();
            if (defaultDate.HasValue)
            {
                year = defaultDate.Value.Year;
                month = defaultDate.Value.Month;
            }

            SelectedDate.Self(string.Format("{0}/{1}",year,month.ToString().PadLeft(2, '0')));

            this.PreviousDate.Self(string.Format("{0}/{1}",previousDateYear,previousDateMonth.ToString().PadLeft(2, '0')));
            this.CurrentDate.Self(string.Format("{0}/{1}",currentDateYear,currentDateMonth.ToString().PadLeft(2, '0')));
            this.NextDate.Self(string.Format("{0}/{1}",nextDateYear,nextDateMonth.ToString().PadLeft(2, '0')));
        }

        public void LogOut()
        {
            _keyValueRepository.RemoveItem("token");
            Window.Location.Href = _settings.LogoutUrl;
        }
        
    }
}