using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CalendarioPeriodoPrototipoDto
    {
        [JsonProperty("tipiturnopersettimana")]
        public List<TipiTurniDto> TipoTurnoPerSettimana { get; set; }

        [JsonProperty("tipiturnopersettimana")]
        public List<TipiTurniDto> TipoTurnoTemplate { get; set; }

        public CalendarioPeriodoPrototipoDto(List<TipiTurniDto> tipoTurnoPerSettimana, List<TipiTurniDto> tipoTurnoTemplate)
        {
            TipoTurnoPerSettimana = tipoTurnoPerSettimana;
            TipoTurnoTemplate = tipoTurnoTemplate;
        }
    }
}
