using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Classes;
using Bridge.Html5;
using Retyped;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.TipiTurni
{
    public class EditTipiTurniViewModel : LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly ITipiTurniService _tipiTurniService;
        private readonly IMessenger _messenger;

        public int EditId { get; set; }
        public int StrutturaId { get; set; }
        public TipiTurniKo tipiTurniKo { get; set; }
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.TipiTurniId;
}
        public Retyped.knockout.KnockoutObservable <bool>FirstLoadPageLoader { get; set; }

        public EditTipiTurniViewModel(INavigator navigator, ITipiTurniService tipiTurniService, IMessenger messenger)
        {
            _navigator = navigator;
            _tipiTurniService = tipiTurniService;
            _messenger = messenger;
            this.tipiTurniKo = new TipiTurniKo(null);

            FirstLoadPageLoader = Retyped.knockout.ko.observable.Self<bool>(false);
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            EditId = parameters.GetParameter<int>("turnoId");
            StrutturaId = parameters.GetParameter<int>("strutturaId"); 

            if(EditId == 0)
                FirstLoadPageLoader = Retyped.knockout.ko.observable.Self<bool>(false);
            else
                FirstLoadPageLoader = Retyped.knockout.ko.observable.Self<bool>(true);

            if (EditId != 0 && StrutturaId != 0)
            {
                var tipiTurniDto = await _tipiTurniService.GetTipiTurniById(StrutturaId,EditId);
                tipiTurniKo = new TipiTurniKo(tipiTurniDto);
            }
            if (StrutturaId != 0)
                tipiTurniKo.StrutturaId = StrutturaId;

            base.OnLoad(parameters);
        }

        public void EditStruttura()
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",this.StrutturaId);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.EditStruttureId, parameters);
        }

        public void RimuoviTipoTurno()
        {
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma rimozione tipo turno",
                Closable = false,
                Message = "Confermi la rimozione del tipo turno?",
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            try {
                                await _tipiTurniService.DeleteTipiTurni(this.StrutturaId, this.EditId);

                                var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",this.StrutturaId);return _o1;});
                                _navigator.Navigate(Bridge.Spaf.SpafApp.EditStruttureId, parameters);

                                e.ToDynamic().close();
                            }
                            catch(Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di rimozione tipo turno");
                            }
                        }
                    }
                }
            });
        }

        public async void EditCreateConfirm()
        {
            try
            {
                var current = new TipiTurniDto
                {
                    Id = tipiTurniKo.Id,
                    StrutturaId = tipiTurniKo.StrutturaId,
                    Causale = tipiTurniKo.Causale.Self(),
                    Periodo = new PeriodoDto {
                        Da = tipiTurniKo.PeriodoDa.Self(),
                        A = tipiTurniKo.PeriodoA.Self()
                    }
                };

                if (current.Id == 0)
                    await _tipiTurniService.CreateTipiTurni(StrutturaId, current);
                else
                    await _tipiTurniService.EditTipiTurni(StrutturaId, current);

                _navigator.Navigate(Bridge.Spaf.SpafApp.EditStruttureId, global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",this.StrutturaId);return _o1;}));
            }            
            catch (PromiseException e)
            {
                tipiTurniKo.Causale.Self(string.Empty);
                Global.Alert(e.GetErrorMessage());
            }
        }

        public void SetFineTurno()
        {
            tipiTurniKo.PeriodoA.Self("23:59");
        }

        public class TipiTurniKo
        {
            public int Id { get; set; }
            public int StrutturaId { get; set; }
            public Retyped.knockout.KnockoutObservable <string>Causale { get; set; }
            public Retyped.knockout.KnockoutObservable <string>PeriodoDa { get; set; }
            public Retyped.knockout.KnockoutObservable <string>PeriodoA { get; set; }

            public TipiTurniKo(TipiTurniDto dto)
            {
                this.Id = ((dto!=null?dto.Id:(int?)null) ?? 0);
                this.StrutturaId = ((dto!=null?dto.StrutturaId:(int?)null) ?? 0);
                this.Causale = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.Causale);
                this.PeriodoDa = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.Periodo.Da);
                this.PeriodoA = Retyped.knockout.ko.observable.Self<string>((dto == null) ? string.Empty : dto.Periodo.A);
            }
        }
    }

}
