using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class StruttureGiorniService: IStruttureGiorniService
    {
        private readonly IStruttureGiorniResource _struttureGiorniResource;

        public StruttureGiorniService(IStruttureGiorniResource StruttureGiorniResource)
        {
            _struttureGiorniResource = StruttureGiorniResource;
        }

        public Task<BaseDtoOutputModel<StruttureGiorniErroriDto>> GetErrori(int idStruttura, int idGiorno)
        {
            return _struttureGiorniResource.GetErrori(idStruttura, idGiorno);
        }
    }
}
