using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CrossFasceOrarieGiorniDto
    {
        [JsonProperty("idgiorno")]
        public int IdGiorno { get; set; }

        [JsonProperty("idfasciaoraria")]
        public int IdFasciaOraria { get; set; }

        [JsonProperty("numeroanomalie")]
        public int NumeroAnomalie { get; set; }

        [JsonProperty("anomalia")]
        public int Anomalia { get; set; }

        [JsonProperty("fasciaorariada")]
        public int FasciaOrariaDa { get; set; }

        [JsonProperty("fasciaorariaa")]
        public int FasciaOrariaA { get; set; }

        public CrossFasceOrarieGiorniDto(int idFasciaOraria, int idGiorno, int anomalia, int numeroAnomalie, int fasciaOrariaDa, int fasciaOrariaA)
        {
            IdFasciaOraria = idFasciaOraria;
            IdGiorno = idGiorno;
            Anomalia = anomalia;
            NumeroAnomalie = numeroAnomalie;
            FasciaOrariaDa = fasciaOrariaDa;
            FasciaOrariaA = fasciaOrariaA;
        }
    }
}
