using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class InfermieriResource: AuthorizedResourceBase, IInfermieriResource
    {
      

        public Task<BaseDtoOutputModel<InfermieriDto>> GetAllInfermieri()
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild("/Infermieri"),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<BaseDtoOutputModel<InfermieriDto>>(options);
        }
        
        public Task<BaseDtoOutputModel<InfermieriDto>> GetAllInfermieriForHomePage()
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild("/Infermieri/GetForHomePage"),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<BaseDtoOutputModel<InfermieriDto>>(options);
        }

        public Task<BaseDtoOutputModel<InfermieriDto>> GetAllInfermieriDisponibili(int idNecessita)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/Infermieri/Disponibili/{0}",idNecessita)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<BaseDtoOutputModel<InfermieriDto>>(options);
        }

        public Task<InfermieriDto> GetInfermiereById(int editId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/Infermieri/{0}",editId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<InfermieriDto>(options);
        }

        public Task<InfermieriDto> CreateNewInfermiere(InfermieriDto currentInfermiere)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild("/Infermieri"),
                Type = "POST",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(currentInfermiere)
            };

            return base.MakeCall<InfermieriDto>(options);
        }

        public Task<InfermieriDto> EditInfermiere(InfermieriDto currentInfermiere)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/Infermieri/{0}",currentInfermiere.Id)),
                Type = "PUT",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(currentInfermiere)
            };

            return base.MakeCall<InfermieriDto>(options);
        }

        public Task<InfermieriDto> Infermiere(int id)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/Infermieri/{0}",id)),
                Type = "GET",
            };

            return this.MakeCall<InfermieriDto>(options);
        }

        public Task DeleteInfermiere(int id)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/Infermieri/{0}",id)),
                Type = "DELETE",
            };

            return this.MakeCall<object>(options);
        }

        public InfermieriResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
