using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge;
using Bridge.Html5;
using Bridge.Messenger;
using Bridge.Navigation;
using Retyped;
using System;
using System.Collections.Generic;
using System.Globalization;
using System.Linq;
using System.Threading.Tasks;
using Bridge.jQuery2;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture
{
    public class EditStrutturaViewModel : BlockableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IStruttureResource _struttureResource;
        private readonly IStruttureService _struttureService;
        private readonly IMessenger _messenger;
        private readonly ITipiTurniService _tipiTurniService;
        private readonly IGiorniService _giornoService;
        private readonly IFasceOrarieService _fasceOrarieService;
        private readonly ICalendariService _calendariService;
        private readonly ITurnazioniMensiliService _turnazioniMensiliService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        private readonly ICookieService _cookieService;
        private readonly IExportService _exportService;
        private readonly IIndisponibilitaService _indisponibilitaService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.EditStruttureId;
}        

        public int EditId { get; set; }
        public StrutturaKo strutturaKo { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>TipiTurnoList { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.GiorniDto>Giorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FasceOrarieDto>FasceOrarie { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FascieOrarieGiorniPerCalendarioStrutturaDto>FasceOrarieGiorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariStrutturaDto>CalendarioStruttura { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>InfermieriOrdinati { get; set; }
        public AssociaInfermiereViewModel associaInfermiereViewModel { get; set; }
        public CrossFasceOrarieGiorniPerCalendarioStrutturaDto infermiereStruttura { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.MesiDto>Mesi { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.AnniDto>Anni { get; set; }

        public Retyped.knockout.KnockoutObservable <int>MeseCorrente { get; set; }
        public Retyped.knockout.KnockoutObservable <int>AnnoCorrente { get; set; }

        public Retyped.knockout.KnockoutObservable <bool>FirstLoadPageLoader { get; set; }

        public Retyped.knockout.KnockoutObservable <bool>PreparaTurnazioneMensile { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>AbilitaNuovaTurnazioneMensile { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>TipiTurnoStruttura { get; set; }

        public Retyped.knockout.KnockoutObservable <int>TurnazioneMensileId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>ExportLink { get; set; }

        public EditStrutturaViewModel(INavigator navigator, IStruttureResource struttureResource,
            IGiorniService giornoService, IFasceOrarieService fasciaOrariaService, ICalendariService calendariService,
            IStruttureService struttureService, IMessenger messenger, ITipiTurniService tipiTurniService, 
            ITurnazioniMensiliService TurnazioniMensiliService, INecessitaGiorniService necessitaGiorniService,
            IIndisponibilitaService indisponibilitaService, ICookieService cookieService, IExportService exportService)
        {
            _navigator = navigator;
            _struttureResource = struttureResource;
            _struttureService = struttureService;
            _messenger = messenger;
            _tipiTurniService = tipiTurniService;
            _fasceOrarieService = fasciaOrariaService;
            _giornoService = giornoService;
            _calendariService = calendariService;
            _turnazioniMensiliService = TurnazioniMensiliService;
            _necessitaGiorniService = necessitaGiorniService;
            _indisponibilitaService = indisponibilitaService;
            _cookieService = cookieService;
            _exportService = exportService;

            Giorni = Retyped.knockout.ko.observableArray.Self<GiorniDto>();
            FasceOrarie = Retyped.knockout.ko.observableArray.Self<FasceOrarieDto>();
            FasceOrarieGiorni = Retyped.knockout.ko.observableArray.Self<FascieOrarieGiorniPerCalendarioStrutturaDto>();
            CalendarioStruttura = Retyped.knockout.ko.observableArray.Self<CalendariStrutturaDto>();

            InfermieriOrdinati = Retyped.knockout.ko.observableArray.Self<InfermieriDto>();

            Mesi = Retyped.knockout.ko.observableArray.Self<MesiDto>();
            Anni = Retyped.knockout.ko.observableArray.Self<AnniDto>();
            
            MeseCorrente = Retyped.knockout.ko.observable.Self<int>();
            AnnoCorrente = Retyped.knockout.ko.observable.Self<int>();

            FirstLoadPageLoader = Retyped.knockout.ko.observable.Self<bool>(true);

            PreparaTurnazioneMensile = Retyped.knockout.ko.observable.Self<bool>(true);
            AbilitaNuovaTurnazioneMensile = Retyped.knockout.ko.observable.Self<bool>(true);

            strutturaKo = new StrutturaKo(null);
            TipiTurnoList = Retyped.knockout.ko.observableArray.Self<TipiTurniDto>();
            TipiTurnoStruttura = Retyped.knockout.ko.observableArray.Self<TipiTurniDto>();
            TurnazioneMensileId = Retyped.knockout.ko.observable.Self<int>(0);
            ExportLink = Retyped.knockout.ko.observable.Self<string>();
        }

        #region OnLoad 
        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            EditId = parameters.GetParameter<int>("strutturaId");

            FirstLoadPageLoader.Self(true);
            
            base.OnLoad(parameters);
            if (EditId != 0)
            {
                var strutturaDtoTask = _struttureResource.GetStrutturaById(EditId);
                var tipiTurniListTask = _tipiTurniService.GetAllTipiTurniByStructureId(EditId);
                await Task.WhenAll(strutturaDtoTask, tipiTurniListTask);

                strutturaKo.Init(strutturaDtoTask.Result);

                IEnumerable<TipiTurniDto> tipiTurniDto = System.Linq.Enumerable.Where<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(tipiTurniListTask.Result,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto, bool>)(x => x.GiornoId == null));

                TipiTurnoList.push(System.Linq.Enumerable.ToArray<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(tipiTurniDto));
            }

            var mesi = _calendariService.GetMesi();
            var anni = _calendariService.GetAnni();
            await Task.WhenAll(mesi, anni);

            this.Mesi.push(mesi.Result.Items);
            this.Anni.push(anni.Result.Items);

            var idAnno = DateTime.Now.Year;
            var idMese = DateTime.Now.Month;

            var defaultDate = _cookieService.GetDefaultDate();
            if (defaultDate.HasValue)
            {
                idAnno = defaultDate.Value.Year;
                idMese = defaultDate.Value.Month;
            }

            await LoadCalendario(idAnno, idMese, this.EditId);

            FirstLoadPageLoader.Self(false);
            
            // gestione anno/mese
            Select2.Render((global::System.Action<object>)(async o =>
            {
                await CambiaAnnoMeseStruttura(AnnoCorrente.Self(), MeseCorrente.Self());
                
                
            }),"#mese");
            
            Select2.Render((global::System.Action<object>)(async o =>
            {
                await CambiaAnnoMeseStruttura(AnnoCorrente.Self(), MeseCorrente.Self());

            }),"#anno");
            //-------------

            Script.Call("RefreshTableSawStruttura");
        }
        #endregion

        #region Salva
        public async void Salva()
        {
            if (((HTMLInputElement)jQuery.Select("#Cantiere").Get(0)).Checked)
                strutturaKo.Cantiere.Self(true);
            else
                strutturaKo.Cantiere.Self(false);

            if (((HTMLInputElement)jQuery.Select("#LavoraFestivi").Get(0)).Checked)
                strutturaKo.LavoraFestivi.Self(true);
            else
                strutturaKo.LavoraFestivi.Self(false);

            if (strutturaKo.PrioritaStruttra.Self().ToString() == "")
                strutturaKo.PrioritaStruttra.Self(0);

            if (strutturaKo.OreMinime.Self().ToString() == "")
                strutturaKo.OreMinime.Self(0);

            var current = new StruttureDto
            {
                Cantiere = strutturaKo.Cantiere.Self(),
                LavoraFestivi = strutturaKo.LavoraFestivi.Self(),
                Id = strutturaKo.StrutturaId.Self(),
                RagioneSociale = strutturaKo.RagioneSociale.Self(),
                NomeSemplice = strutturaKo.NomeSemplice.Self(),
                MexalId = strutturaKo.MexalId.Self(),
                GapDisponibilitaInfermiere = strutturaKo.GapDisponibilitaInfermiere.Self().ToString(),
                EMail = strutturaKo.EMail.Self(),
                Patrono = strutturaKo.Patrono.Self(),
                PrioritaStruttra = strutturaKo.PrioritaStruttra.Self(),
                OreMinime = strutturaKo.OreMinime.Self()
            };

            await _struttureService.EditStruttura(strutturaKo.StrutturaId.Self(), current);
        }
        #endregion


        //TODO: GO TO CLIENTE

        #region VaiAMesePrecedente
        public async void VaiAMesePrecedente()
        {
            var actualMonth = this.MeseCorrente.Self();
            if (actualMonth == 1)
            {
                actualMonth = 12;
                var actualYear = this.AnnoCorrente.Self() - 1;
                AnnoCorrente.Self(actualYear);
                MeseCorrente.Self(actualMonth);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth)).Trigger("change");
                jQuery.Select("#anno").Val(Convert.ToString(actualYear)).Trigger("change");
            }
            else
            {
                this.MeseCorrente.Self(actualMonth - 1);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth - 1)).Trigger("change");
            }


            await CambiaAnnoMeseStruttura(AnnoCorrente.Self(), MeseCorrente.Self());
        }
        #endregion

        #region VaiAMeseSuccessivo
        public async void VaiAMeseSuccessivo()
        {
            var actualMonth = this.MeseCorrente.Self();
            if (actualMonth == 12)
            {
                actualMonth = 1;
                var actualYear = this.AnnoCorrente.Self() + 1;
                AnnoCorrente.Self(actualYear);
                MeseCorrente.Self(actualMonth);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth)).Trigger("change");
                jQuery.Select("#anno").Val(Convert.ToString(actualYear)).Trigger("change");
            }
            else
            {
                this.MeseCorrente.Self(actualMonth + 1);
                jQuery.Select("#mese").Val(Convert.ToString(actualMonth + 1)).Trigger("change");
            }


            await CambiaAnnoMeseStruttura(AnnoCorrente.Self(), MeseCorrente.Self());
        }
        #endregion

        #region AddTurno
        public void AddTurno()
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",strutturaKo.StrutturaId.Self());_o1.Add("turnoId",0);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.TipiTurniId, parameters);
        }
        #endregion

        #region EditTurno
        public void EditTurno(TipiTurniDto turno)
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",strutturaKo.StrutturaId.Self());_o1.Add("turnoId",turno.Id);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.TipiTurniId, parameters);
        }
        #endregion

        #region EditInfermiere
        public void EditInfermiere(InfermieriDto Infermiere)
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("id",Infermiere.Id);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.EditCreateInfermieriId, parameters);
        }
        #endregion

        #region PeriodoPrototipo
        public void PeriodoPrototipo()
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("strutturaId",this.EditId);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.PeriodoPrototipoId, parameters);
        }
        #endregion

        public class StrutturaKo
        {
            public Retyped.knockout.KnockoutObservable <int>StrutturaId { get; set; }
            public Retyped.knockout.KnockoutObservable <string>RagioneSociale { get; set; }
            public Retyped.knockout.KnockoutObservable <string>NomeSemplice { get; set; }
            public Retyped.knockout.KnockoutObservable <int>PrioritaStruttra { get; set; }
            public Retyped.knockout.KnockoutObservable <bool>Cantiere { get; set; }
            public Retyped.knockout.KnockoutObservable <bool>LavoraFestivi { get; set; }
            public Retyped.knockout.KnockoutObservable <int>OreMinime { get; set; }
            public Retyped.knockout.KnockoutObservable <string>MexalId { get; set; }
            public Retyped.knockout.KnockoutObservable <int>GapDisponibilitaInfermiere { get; set; }
            public Retyped.knockout.KnockoutObservable <string>EMail { get; set; }
            public Retyped.knockout.KnockoutObservable <string>Patrono { get; set; }

            public StrutturaKo(StruttureDto dto)
            {
                this.StrutturaId = Retyped.knockout.ko.observable.Self<int>();
                this.RagioneSociale = Retyped.knockout.ko.observable.Self<string>();
                this.NomeSemplice = Retyped.knockout.ko.observable.Self<string>();
                this.PrioritaStruttra = Retyped.knockout.ko.observable.Self<int>();
                this.Cantiere = Retyped.knockout.ko.observable.Self<bool>();
                this.LavoraFestivi = Retyped.knockout.ko.observable.Self<bool>();
                this.OreMinime = Retyped.knockout.ko.observable.Self<int>();
                this.MexalId = Retyped.knockout.ko.observable.Self<string>();
                this.GapDisponibilitaInfermiere = Retyped.knockout.ko.observable.Self<int>();
                this.EMail = Retyped.knockout.ko.observable.Self<string>();
                this.Patrono = Retyped.knockout.ko.observable.Self<string>();

            }

            public void Init(StruttureDto dto)
            {
                this.StrutturaId.Self(dto == null ? 0 : Convert.ToInt32(dto.Id));
                this.RagioneSociale.Self((dto == null) ? string.Empty : dto.RagioneSociale);
                this.NomeSemplice.Self((dto == null) ? string.Empty : dto.NomeSemplice);
                this.PrioritaStruttra.Self(dto == null ? 0 : Convert.ToInt32(dto.PrioritaStruttra));
                this.Cantiere.Self((dto != null) && dto.Cantiere);
                this.LavoraFestivi.Self((dto != null) && dto.LavoraFestivi);
                this.OreMinime.Self(dto == null ? 0 : Convert.ToInt32(dto.OreMinime));
                this.MexalId.Self((dto == null) ? string.Empty : dto.MexalId);
                this.GapDisponibilitaInfermiere.Self(dto == null ? 0 : Convert.ToInt32(dto.GapDisponibilitaInfermiere));
                this.EMail.Self((dto == null) ? string.Empty : dto.EMail);
                this.Patrono.Self((dto == null) ? string.Empty : dto.Patrono);
            }
        }

        #region CambiaAnnoMeseStruttura
        public async Task CambiaAnnoMeseStruttura(int idAnno, int idMese)
        {
            try
            {
                Block();
                Script.Call("DestroyTableSawStruttura");
                await LoadCalendario(idAnno, idMese, this.EditId);
                Script.Call("RefreshTableSawStruttura");
                UnBlock();
            }
            catch(Exception ex)
            {
                Console.Write(ex.Message);
            }
        }
        #endregion

        #region RichiediPreparazioneTurnazioneMensile
        public void RichiediPreparazioneTurnazioneMensile()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma preparazione turnazione mensile",
                Closable = false,
                Message = "Confermi la preparazione della turnazione mensile?",
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            Block();
                            try {
                                await _turnazioniMensiliService.ChangeStatoTurnazioneMensile(idAnno, idMese, (int)StatoTurnazioneMensile.Prepared, this.EditId);
                                this.PreparaTurnazioneMensile.Self(false);
                                e.ToDynamic().close();
                            }
                            catch(Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di preparazione della turnazione mensile");
                            }
                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region LoadCalendario
        private async Task LoadCalendario(int idAnno, int idMese, int idStruttura)
        {
            //this.AnnoCorrente.removeAll();
            //this.MeseCorrente.removeAll();
            this.CalendarioStruttura.removeAll();
            this.Giorni.removeAll();
            this.FasceOrarie.removeAll();
            this.FasceOrarieGiorni.removeAll();
            this.InfermieriOrdinati.removeAll();
            this.TipiTurnoStruttura.removeAll();
            this.TurnazioneMensileId.Self(0);

            this.PreparaTurnazioneMensile.Self(false);
            this.AbilitaNuovaTurnazioneMensile.Self(false);

            var giorni = _giornoService.GetAllGiorni(idStruttura, idAnno, idMese);
            var fasce = _fasceOrarieService.GetAllFasceOrarie();
            var calendarioStruttura = _calendariService.GetCalendariPerStrutturaPerMese(idStruttura, idAnno, idMese);

            var infermieriOrdinati = _struttureService.GetOrderedAssociatedInfermieriForStruttura(idStruttura, 1);

            var turnazioniMensiliInfoStato = _turnazioniMensiliService.TurnazioniMensiliInfoStato(idAnno, idMese, this.EditId);

            var turnazioniMensili = _turnazioniMensiliService.GetTurnazioniMensileForStruttura(idAnno, idMese, this.EditId);

            var tipiTurni = _tipiTurniService.GetAllTipiTurniByStructureId(this.EditId);

            await Task.WhenAll(fasce, giorni, calendarioStruttura, infermieriOrdinati, turnazioniMensiliInfoStato, turnazioniMensili, tipiTurni);
            
            if (turnazioniMensili.Result != null)
                this.TurnazioneMensileId.Self(turnazioniMensili.Result.Id);

            if (turnazioniMensiliInfoStato.Result.TurnazioniMensiliPreparing)
                this.PreparaTurnazioneMensile.Self(true);
            else
                this.PreparaTurnazioneMensile.Self(false);

            if (turnazioniMensiliInfoStato.Result.IsClosed || turnazioniMensiliInfoStato.Result.TurnazioniMensiliClosing)
                this.AbilitaNuovaTurnazioneMensile.Self(false);
            else
            {
                if(turnazioniMensiliInfoStato.Result.TurnazioniMensiliPrepared)
                    this.AbilitaNuovaTurnazioneMensile.Self(true);
            }

            if(!turnazioniMensiliInfoStato.Result.TurnazioniMensiliPreparing && !turnazioniMensiliInfoStato.Result.TurnazioniMensiliPrepared &&
                !turnazioniMensiliInfoStato.Result.IsClosed && !turnazioniMensiliInfoStato.Result.TurnazioniMensiliClosing)
                this.AbilitaNuovaTurnazioneMensile.Self(true);

            CalendariStrutturaDto[] calendari = calendarioStruttura.Result;

            foreach (FasceOrarieDto fascia in fasce.Result.Items)
            {
                CrossFasceOrarieGiorniPerCalendarioStrutturaDto[] listaGiorni = new CrossFasceOrarieGiorniPerCalendarioStrutturaDto[giorni.Result.Length];
                int index = 0;
                foreach (GiorniDto giorno in giorni.Result)
                {
                    int numeroInfermieri = 0;
                    string tooltip = "";

                    CrossFasceOrarieGiorniPerCalendarioStrutturaDto crossFasciaGiorno = null;

                    var calendariStrutturaDto = System.Linq.Enumerable.Where<global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariStrutturaDto>(calendari,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariStrutturaDto, bool>)(p => p.Giorno == giorno.Giorno && p.FasciaOraria == fascia.Fascia));

                    CalendariStrutturaDto item = System.Array.Find<global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariStrutturaDto>(calendari, (global::System.Predicate<global::Aip.Florence.FrontEnd.SpafApp.Dto.CalendariStrutturaDto>)(p => p.Giorno == giorno.Giorno && p.FasciaOraria == fascia.Fascia));

                    List<int> idInfermieri = new List<int>();
                    List<string> infermieri = new List<string>();
                    List<string> causali = new List<string>();
                    List<string> periodiDa = new List<string>();
                    List<string> periodiA = new List<string>();
                    List<int> idListaNecessita = new List<int>();
                    int idInfermiere = 0;
                    if (calendariStrutturaDto.Count() > 0)
                    {
                        if (calendariStrutturaDto.Count() > 1)
                            tooltip = "Infermieri associati: ";
                        else if (calendariStrutturaDto.Count() == 1)
                            tooltip = "Infermiere associato: ";
                        foreach (CalendariStrutturaDto elemento in calendariStrutturaDto)
                        {
                            idInfermiere = elemento.IdInfermiere;
                            idInfermieri.Add(elemento.IdInfermiere);
                            infermieri.Add(elemento.Infermiere);
                            causali.Add(elemento.Causale);
                            periodiDa.Add(elemento.FasciaOrariaDa);
                            periodiA.Add(elemento.FasciaOrariaA);
                            idListaNecessita.Add(elemento.IdNecessita);

                            tooltip += elemento.Infermiere + " ";
                        }

                        numeroInfermieri = System.Linq.Enumerable.Count<int>(idInfermieri,(global::System.Func<int, bool>)(i => i > 0));

                        if (idInfermiere != 0)
                            crossFasciaGiorno = new CrossFasceOrarieGiorniPerCalendarioStrutturaDto(item.IdNecessita, idListaNecessita, idInfermieri, infermieri, giorno.Id, item.Struttura, causali, fascia.Id, periodiDa, periodiA, 2, tooltip, 
                                                    numeroInfermieri, item.GiorniFestivi, item.GiorniPreFestivi, item.Patrono);
                        else
                            crossFasciaGiorno = new CrossFasceOrarieGiorniPerCalendarioStrutturaDto(item.IdNecessita, idListaNecessita, idInfermieri, infermieri, giorno.Id, item.Struttura, causali, fascia.Id, periodiDa, periodiA, 1, tooltip, numeroInfermieri,
                                item.GiorniFestivi, item.GiorniPreFestivi, item.Patrono);
                    }
                    else
                        crossFasciaGiorno = new CrossFasceOrarieGiorniPerCalendarioStrutturaDto(0, idListaNecessita, idInfermieri, infermieri, giorno.Id, "", causali, fascia.Id, periodiDa, periodiA, 0, tooltip, numeroInfermieri, "", "", "");

                    listaGiorni[index] = crossFasciaGiorno;
                    index++;


                }
                FascieOrarieGiorniPerCalendarioStrutturaDto fasceOrarieGiorniDto = new FascieOrarieGiorniPerCalendarioStrutturaDto(fascia, listaGiorni);
                this.FasceOrarieGiorni.push(fasceOrarieGiorniDto);
            }

            this.InfermieriOrdinati.push(infermieriOrdinati.Result);
            this.TipiTurnoStruttura.push(System.Linq.Enumerable.Where<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto>(tipiTurni.Result,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.TipiTurniDto, bool>)(x => x.GiornoId == null)).ToArray());
            
            idAnno = Convert.ToInt32(idAnno);
            idMese = Convert.ToInt32(idMese);

            this.FasceOrarie.push(fasce.Result.Items);
            this.AnnoCorrente.Self(idAnno);
            this.MeseCorrente.Self(idMese);
            this.Giorni.push(giorni.Result);
        }
        #endregion

        /// <summary>
        /// Nuova turnazione mensile in caso di turnazione già esistente
        /// </summary>
        /// <param name="anno"></param>
        /// <param name="mese"></param>
        /// <returns></returns>
        public async Task<bool> TurnazioneMensileEsistente(int anno, int mese)
        {
            bool result = true;

            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma nuova turnazione mensile",
                Closable = false,
                Message = "ATTENZIONE! E' già presente una turnazione mensile per il periodo indicato.\nProcedendo la turnazione verrà sostituita dalla nuova turnazione.\nConfermi la creazione della nuova turnazione mensile?",
                OnShown = () =>
                {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(v) => {
                            v.ToDynamic().close();
                            try {
                                await _turnazioniMensiliService.PrepareTurnazioneMensile(anno, mese, this.EditId);
                                await LoadCalendario(anno, mese, this.EditId);
                            }
                            catch(Exception ex)
                            {
                                result = false;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di creazione nuova turnazione mensile");
                            }
                        }
                    }
                }
            });

            return result;
        }

        #region NuovaTurnazioneCommand
        public void NuovaTurnazioneCommand()
        {
            this.NuovaTurnazioneMensile(this.AnnoCorrente.Self(),this.MeseCorrente.Self());
        }
        #endregion

        /// <summary>
        /// Crea una nuova turnazione mensile
        /// </summary>
        /// <param name="idAnno"></param>
        /// <param name="idMese"></param>
        public void NuovaTurnazioneMensile(int idAnno, int idMese)
        {
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma nuova turnazione mensile",
                Closable = false,
                Message = "Confermi la creazione della nuova turnazione mensile?",
                OnShown = () => {
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(e) => {
                            e.ToDynamic().close();
                            Block();
                            TurnazioniMensiliDto turnazioniMensiliDto = await _turnazioniMensiliService.GetTurnazioniMensileForStruttura(idAnno, idMese, EditId);

                            if(turnazioniMensiliDto == null)
                            {
                                try {
                                    await _turnazioniMensiliService.PrepareTurnazioneMensile(idAnno, idMese, this.EditId);
                                    await LoadCalendario(idAnno, idMese, this.EditId);
                                }
                                catch(Exception ex)
                                {
                                    string err = ex.Message;
                                    BootstrapDialog.Alert("ATTENZIONE! Errore in fase di creazione nuova turnazione mensile");
                                }
                            }
                            else
                            {
                               await TurnazioneMensileEsistente(idAnno, idMese); 
                            }
                            UnBlock();
                        }
                    }
                }
            });
        }

        #region Edit
        public async void Edit(CrossFasceOrarieGiorniPerCalendarioStrutturaDto infermiere)
        {
            int alert = 0;
            var turnazioniMensili = _turnazioniMensiliService.TurnazioniMensiliClosed(this.AnnoCorrente.Self(), this.MeseCorrente.Self(), this.EditId);

            await Task.WhenAll<bool>(turnazioniMensili);

            if (turnazioniMensili.Result)
            {
                Bridge.Utils.Console.Log(string.Format("AnnoCorrente: '{0}'",this.AnnoCorrente.Self()));
                Bridge.Utils.Console.Log(string.Format("MeseCorrente: '{0}'",this.MeseCorrente.Self()));
                if ((this.AnnoCorrente.Self() == DateTime.Now.Year && this.MeseCorrente.Self() >= DateTime.Now.Month) || 
                    (this.AnnoCorrente.Self() > DateTime.Now.Year && this.MeseCorrente.Self() < DateTime.Now.Month))
                    alert = 1;
                else
                    alert = 2;
            }
            else
                alert = 0;

            if (alert == 2)
                BootstrapDialog.Alert("ATTENZIONE! La turnazione mensile per questa struttura è chiusa\nPer i mesi precedenti al mese corrente non è possibile modificare gli infermieri allocati");
            else
                AllocazioneInfermiere(infermiere, alert);
        }
        #endregion

        /// <summary>
        /// Cancella la necessità selezionata
        /// </summary>
        /// <param name="nomeCombo"></param>
        public async void CancellaNecessita(string nomeCombo)
        {
            int id = Convert.ToInt32(nomeCombo.ToString().Replace("#infermiere", ""));
            string[] elementi = jQuery.Select("#hidden" + id).Val().Split('|');

            int idNecessita = Convert.ToInt32(elementi[2]);

            string necessita = "Confermi cancellazione della necessità selezionata?";

            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma eliminazione mecessità",
                Closable = false,
                Message = necessita,
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (o) =>
                        {
                            o.ToDynamic().close();

                            await _necessitaGiorniService.Delete(idNecessita);

                            Script.Call("CancellaRigaStruttura", id.ToString());

                            Script.Call("DestroyTableSawStruttura");
                            await LoadCalendario(this.AnnoCorrente.Self(), this.MeseCorrente.Self(),this.EditId);
                            Script.Call("RefreshTableSawStruttura");
                        }
                    }
                }
            });
        }

        /// <summary>
        /// Aggiorna il valore della combo dell'infermiere
        /// </summary>
        /// <param name="id"></param>
        /// <param name="idInfermiere"></param>
        /// <param name="alert"></param>
        private void SetInfermiereStruttura(int id, int idInfermiere, int alert)
        {
            jQuery.Select("#Errore").Text("").Trigger("change");

            var infermiereId = string.Format("#infermiere{0}",id);
            jQuery.Select(infermiereId).Val(idInfermiere.ToString()).Trigger("change");
        }

        #region CambiaInfermiereStruttura
        public async void CambiaInfermiereStruttura(string nomeCombo, string idInfermiere, int alert)
        {
            int id = Convert.ToInt32(nomeCombo.ToString().Replace("#infermiere", ""));
            string[] elementi = jQuery.Select("#hidden" + id).Val().Split('|');

            int idGiornoLista = Convert.ToInt32(elementi[0]);
            int idInfermiereLista = Convert.ToInt32(elementi[1]);
            int idNecessitaInfermiereLista = Convert.ToInt32(elementi[2]);


            if (Convert.ToInt32(idInfermiere) == 0)
            {
                string necessita = "ATTENZIONE! La eliminazione dell'infermiere potrebbe causare la generazione di una anomalia\nConfermi?";
                
                BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                {
                    Title = "Conferma modifica allocazione",
                    Closable = false,
                    Message = necessita,
                    OnShown = () => { },
                    Buttons = new BootstrapDialog.BootstrapDialogButton[]
                    {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => {
                            self.ToDynamic().close();

                            //Script.Call("SetInfermiereStruttura", id.ToString(), infermiereStruttura.IdInfermieri[id].ToString(), alert.ToString());
                            //SetInfermiereStruttura(id, infermiereStruttura.IdInfermieri[id], alert);
                        }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (o) =>
                        {
                            o.ToDynamic().close();
                            bool result = true;
                            if (Convert.ToInt32(alert) == 0)
                                result = await AllocazioneInfermiereTurnazioneMensileAperta(id, Convert.ToInt32(idInfermiere), idGiornoLista, idInfermiereLista, idNecessitaInfermiereLista);
                            else if (Convert.ToInt32(alert) == 1)
                                result = await AllocazioneInfermiereTurnazioneMensileChiusa(id, Convert.ToInt32(idInfermiere), idGiornoLista, idInfermiereLista, idNecessitaInfermiereLista);
                            }
                        }
                    }
                });
            }
            else
            {
                bool result = true;
                if (Convert.ToInt32(alert) == 0)
                    result = await AllocazioneInfermiereTurnazioneMensileAperta(id, Convert.ToInt32(idInfermiere), idGiornoLista, idInfermiereLista, idNecessitaInfermiereLista);
                else if (Convert.ToInt32(alert) == 1)
                    result = await AllocazioneInfermiereTurnazioneMensileChiusa(id, Convert.ToInt32(idInfermiere), idGiornoLista, idInfermiereLista, idNecessitaInfermiereLista);
            }
        }
        #endregion

        /// <summary>
        /// Allocazione infermiere su sovrapposizione
        /// </summary>
        /// <param name="id"></param>
        /// <param name="idNecessitaInfermiere"></param>
        /// <param name="associaInfermiereViewModel"></param>
        /// <param name="necessitaGiorno"></param>
        /// <param name="dateTime"></param>
        /// <param name="idInfermiereSelezionato"></param>
        /// <returns></returns>
        public async Task<bool> AllocazioneInfermiereTurnazioneSovrapposizione(int id, int idNecessitaInfermiere, AssociaInfermiereViewModel associaInfermiereViewModel,
                                                                               NecessitaGiornoDto[] necessitaGiorno, DateTime dateTime, int idInfermiereSelezionato)
        {
            bool result = true;

            string necessita = "ATTENZIONE! Nel periodo indicato esiste già una allocazione presso la struttura: " + necessitaGiorno[0].Struttura + "\nSe si procede la allocazione verrà eliminata. Confermi?";
            var idNecessita = necessitaGiorno[0].IdNecessita;
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma modifica allocazione",
                Closable = false,
                Message = necessita,
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            e.ToDynamic().close();
                            try
                            {
                                jQuery.Select("#Errore").Text("").Trigger("change");
                                var nuovoInfermiereId = await _necessitaGiorniService.Put(idNecessitaInfermiere, idInfermiereSelezionato, necessitaGiorno[0].IdNecessita);

                                //Script.Call("SetInfermiereStruttura", id.ToString(), nuovoInfermiereId.ToString(), "1");
                                SetInfermiereStruttura(id, nuovoInfermiereId, 1);

                                Script.Call("DestroyTableSawStruttura");
                                await LoadCalendario(dateTime.Year, dateTime.Month,this.EditId);
                                Script.Call("RefreshTableSawStruttura");
                            }
                            catch
                            {
                                //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! Errore in fase di modifica allocazione infermiere");
                                jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di modifica allocazione infermiere").Trigger("change");
                            }
                        }
                    }
                }
            });

            return result;
        }

        /// <summary>
        /// Allocazione infermiere su turnazione mensile chiusa
        /// </summary>
        /// <param name="id"></param>
        /// <param name="idInfermiereSelezionato"></param>
        /// <returns></returns>
        public async Task<bool> AllocazioneInfermiereTurnazioneMensileChiusa(int id, int idInfermiereSelezionato, int idGiorno, int idInfermiere,  int idNecessitaInfermiere)
        {
            bool result = true;
            //int idInfermiere = infermiereStruttura.IdInfermieri[id];
            //int idGiorno = infermiereStruttura.IdGiorno;
            //int idNecessitaInfermiere = infermiereStruttura.IdListaNecessita[id];
            int alert = 1;

            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma allocazione infermiere",
                Closable = false,
                Message = "ATTENZIONE! La turnazione mensile per questa struttura è chiusa.\nConfermi di voler modificare la allocazione dell'infermiere?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => {
                            self.ToDynamic().close();
                            //Script.Call("SetInfermiereStruttura", id.ToString(), infermiereStruttura.IdInfermieri[id].ToString(), alert);
                            SetInfermiereStruttura(id, infermiereStruttura.IdInfermieri[id], alert);
                        }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async(v) => {
                            v.ToDynamic().close();
                            jQuery.Select("#Errore").Text("").Trigger("change");
                            Block();
                            if(idInfermiere != idInfermiereSelezionato)
                            {
                                string data = this.AnnoCorrente.Self().ToString() + "-" + this.MeseCorrente.Self().ToString().PadLeft(2, '0') + "-" + idGiorno.ToString().PadLeft(2, '0');
                                DateTime dateTime = DateTime.ParseExact(data, "yyyy-MM-dd", CultureInfo.InvariantCulture);

                                NecessitaGiornoDto necessitaGiornoDto = await _necessitaGiorniService.GetById(idNecessitaInfermiere);

                                NecessitaGiornoDto[] necessitaGiorno = null;
                                IndisponibilitaDto[] indisponibilita = null;

                                if(idInfermiereSelezionato != 0)
                                {
                                    if(necessitaGiornoDto != null)
                                    {
                                        necessitaGiorno = await _necessitaGiorniService.GetTurnazioneMensileForDayForInfermiere(this.EditId, dateTime.Year,
                                            dateTime.Month, dateTime.Day, necessitaGiornoDto.PeriodoDa, necessitaGiornoDto.PeriodoA,
                                            idInfermiereSelezionato);

                                        indisponibilita = await _indisponibilitaService.GetIndisponibilitaPerInfermierePerData(idInfermiereSelezionato, data, necessitaGiornoDto.PeriodoDa, necessitaGiornoDto.PeriodoA);
                                    }
                                }

                                if (System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(necessitaGiorno))
                                    await AllocazioneInfermiereTurnazioneSovrapposizione(id, idNecessitaInfermiere, associaInfermiereViewModel, necessitaGiorno, dateTime, 
                                                                                         idInfermiereSelezionato);
                                else
                                {
                                    try
                                    {
                                        if(!System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto>(indisponibilita))
                                        {
                                            jQuery.Select("#Errore").Text("").Trigger("change");
                                            var nuovoInfermiereId =  await _necessitaGiorniService.Put(idNecessitaInfermiere, idInfermiereSelezionato, 0);

                                            //Script.Call("SetInfermiereStruttura", id.ToString(), nuovoInfermiereId.ToString(), alert);
                                            SetInfermiereStruttura(id, nuovoInfermiereId, alert);

                                            Script.Call("DestroyTableSawStruttura");
                                            await LoadCalendario(dateTime.Year, dateTime.Month,this.EditId);
                                            Script.Call("RefreshTableSawStruttura");
                                        }
                                        else
                                        {
                                            result = false;
                                            //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! L'infermiere risulta indisponibile nel periodo selezionato.\nSelezionare un altro infermiere");
                                            jQuery.Select("#Errore").Text("ATTENZIONE! L'infermiere risulta indisponibile nel periodo selezionato.\nSelezionare un altro infermiere").Trigger("change");
                                        }
                                    }
                                    catch
                                    {
                                        result = false;
                                        //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! Errore in fase di modifica allocazione infermiere");
                                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di modifica allocazione infermiere").Trigger("change");
                                    }
                                }
                            }
                            else
                            {
                                try
                                {
                                    jQuery.Select("#Errore").Text("").Trigger("change");
                                    var nuovoInfermiereId = await _necessitaGiorniService.Put(idNecessitaInfermiere, idInfermiere, 0);

                                    //Script.Call("SetInfermiereStruttura", id.ToString(), nuovoInfermiereId.ToString(), alert);
                                    SetInfermiereStruttura(id, nuovoInfermiereId, alert);

                                    Script.Call("DestroyTableSawStruttura");
                                    await LoadCalendario(this.AnnoCorrente.Self(), this.MeseCorrente.Self(), this.EditId);
                                    Script.Call("RefreshTableSawStruttura");
                                }
                                catch
                                {
                                    result = false;
                                    //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! Errore in fase di modifica allocazione infermiere");
                                    jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di modifica allocazione infermiere").Trigger("change");
                                }
                            }

                            UnBlock();
                        }
                    }
                }
            });

            return result;
        }

        /// <summary>
        /// Allocazione infermiere su turnazione mensile aperta
        /// </summary>
        /// <param name="id"></param>
        /// <param name="idInfermiereSelezionato"></param>
        /// <returns></returns>
        public async Task<bool> AllocazioneInfermiereTurnazioneMensileAperta(int id, int idInfermiereSelezionato, int idGiorno, int idInfermiere, int idNecessitaInfermiere)
        {
            bool result = true;
            //int idInfermiere = infermiereStruttura.IdInfermieri[id];
            //int idGiorno = infermiereStruttura.IdGiorno;
            //int idNecessitaInfermiere = infermiereStruttura.IdListaNecessita[id];
            int alert = 0;

            Block();
            if (idInfermiere != idInfermiereSelezionato)
            {
                string data = this.AnnoCorrente.Self().ToString() + "-" + this.MeseCorrente.Self().ToString().PadLeft(2, '0') + "-" + idGiorno.ToString().PadLeft(2, '0');
                DateTime dateTime = DateTime.ParseExact(data, "yyyy-MM-dd", CultureInfo.InvariantCulture);

                NecessitaGiornoDto necessitaGiornoDto = await _necessitaGiorniService.GetById(idNecessitaInfermiere);

                NecessitaGiornoDto[] necessitaGiorno = null;
                IndisponibilitaDto[] indisponibilita = null;

                if (idInfermiereSelezionato != 0)
                {
                    if (necessitaGiornoDto != null)
                    {
                        necessitaGiorno = await _necessitaGiorniService.GetTurnazioneMensileForDayForInfermiere(this.EditId, dateTime.Year,
                            dateTime.Month, dateTime.Day, necessitaGiornoDto.PeriodoDa, necessitaGiornoDto.PeriodoA,
                            idInfermiereSelezionato);

                        indisponibilita = await _indisponibilitaService.GetIndisponibilitaPerInfermierePerData(idInfermiereSelezionato, data, necessitaGiornoDto.PeriodoDa, necessitaGiornoDto.PeriodoA);
                    }
                }

                if (System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>(necessitaGiorno))
                {
                    string necessita = "ATTENZIONE! Nel periodo indicato esiste già una allocazione presso la struttura: " + necessitaGiorno[0].Struttura + "\nSe si procede la allocazione verrà eliminata. Confermi?";
                    var idNecessita = necessitaGiorno[0].IdNecessita;
                    BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
                    {
                        Title = "Conferma modifica allocazione",
                        Closable = false,
                        Message = necessita,
                        OnShown = () => { },
                        Buttons = new BootstrapDialog.BootstrapDialogButton[]
                        {
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Chiudi",
                                Action = (self) => {
                                    self.ToDynamic().close();
                                    //Script.Call("SetInfermiereStruttura", id.ToString(), infermiereStruttura.IdInfermieri[id].ToString(), alert);
                                    SetInfermiereStruttura(id, infermiereStruttura.IdInfermieri[id], alert);
                                }
                            },
                            new BootstrapDialog.BootstrapDialogButton()
                            {
                                Label = "Conferma",
                                Action = async (e) =>
                                {
                                    e.ToDynamic().close();
                                    try
                                    {
                                        jQuery.Select("#Errore").Text("").Trigger("change");
                                        var nuovoInfermiereId = await _necessitaGiorniService.Put(idNecessitaInfermiere, idInfermiereSelezionato, necessitaGiorno[0].IdNecessita);

                                        //Script.Call("SetInfermiereStruttura", id.ToString(), nuovoInfermiereId.ToString(), alert);
                                        SetInfermiereStruttura(id, nuovoInfermiereId, alert);

                                        Script.Call("DestroyTableSawStruttura");
                                        await LoadCalendario(dateTime.Year, dateTime.Month,this.EditId);
                                        Script.Call("RefreshTableSawStruttura");
                                    }
                                    catch
                                    {
                                        result = false;
                                        //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! Errore in fase di modifica allocazione infermiere");
                                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di modifica allocazione infermiere").Trigger("change");
                                    }
                                }
                            }
                        }
                    });
                }
                else
                {
                    try
                    {
                        if (!System.Linq.Enumerable.Any<global::Aip.Florence.FrontEnd.SpafApp.Dto.IndisponibilitaDto>(indisponibilita))
                        {
                            jQuery.Select("#Errore").Text("").Trigger("change");
                            var nuovoInfermiereId = await _necessitaGiorniService.Put(idNecessitaInfermiere, idInfermiereSelezionato, 0);

                            //Script.Call("SetInfermiereStruttura", id.ToString(), nuovoInfermiereId.ToString(), alert);
                            SetInfermiereStruttura(id, nuovoInfermiereId, alert);

                            Script.Call("DestroyTableSawStruttura");
                            await LoadCalendario(dateTime.Year, dateTime.Month, this.EditId);
                            Script.Call("RefreshTableSawStruttura");
                        }
                        else
                        {
                            result = false;
                            //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! L'infermiere risulta indisponibile nel periodo selezionato.\nSelezionare un altro infermiere");
                            jQuery.Select("#Errore").Text("ATTENZIONE! L'infermiere risulta indisponibile nel periodo selezionato.\nSelezionare un altro infermiere").Trigger("change");
                        }
                    }
                    catch
                    {
                        result = false;
                        //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! Errore in fase di modifica allocazione infermiere");
                        jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di modifica allocazione infermiere").Trigger("change");
                    }
                }
            }
            else
            {
                try
                {
                    jQuery.Select("#Errore").Text("").Trigger("change");
                    var nuovoInfermiereId = await _necessitaGiorniService.Put(idNecessitaInfermiere, idInfermiere, 0);

                    //Script.Call("SetInfermiereStruttura", id.ToString(), nuovoInfermiereId.ToString(), alert);
                    SetInfermiereStruttura(id, nuovoInfermiereId, alert);

                    Script.Call("DestroyTableSawStruttura");
                    await LoadCalendario(this.AnnoCorrente.Self(), this.MeseCorrente.Self(), this.EditId);
                    Script.Call("RefreshTableSawStruttura");
                }
                catch
                {
                    result = false;
                    //associaInfermiereViewModel.AssociaInfermiereKo.Errore.Self("ATTENZIONE! Errore in fase di modifica allocazione infermiere");
                    jQuery.Select("#Errore").Text("ATTENZIONE! Errore in fase di modifica allocazione infermiere").Trigger("change");
                }
            }

            UnBlock();

            return result;
        }   

        /// <summary>
        /// Allocazione infermiere
        /// </summary>
        /// <param name="infermiere"></param>
        /// <param name="alert"></param>
        public void AllocazioneInfermiere(CrossFasceOrarieGiorniPerCalendarioStrutturaDto infermiere, int alert)
        {
            this.infermiereStruttura = infermiere;

            var modalGialogHTML = "<form class=\"form-horizontal\" role=\"form\"> " +
                                    "<div class=\"row\"> " +
                                       " <div class=\"col-md-12\"><span id=\"Errore\" style=\"background-color: red \" class=\"floating-label\"></span></div> " +
                                    "</div> " + 
                                    "<div class=\"row\"> " +
                                        "<div class=\"col-md-2\">Causale</div>" +
                                        "<div class=\"col-md-1\">Da</div>" +
                                        "<div class=\"col-md-1\">A</div>" +
                                        "<div class=\"col-md-8\">Infermiere</div>" +
                                    "</div> ";

            int indice = 0;

            associaInfermiereViewModel = Bridge.Spaf.SpafApp.Container.Resolve<AssociaInfermiereViewModel>();

            //associaInfermiereViewModel.Seleziona = new int[infermiere.IdInfermieri.Count];

            while (indice < infermiere.IdInfermieri.Count)
            {
                //associaInfermiereViewModel.Seleziona[indice] = infermiere.IdInfermieri[indice];

                modalGialogHTML += "<div id=\"row" + indice.ToString() + "\" class=\"row\" style=\"height: 50px\"> " +
                                   "<input type=\"hidden\" id=\"hidden" + indice.ToString() + "\" name=\"hidden" + indice.ToString() + "\" value=\"" + infermiere.IdGiorno + "|" + 
                                   infermiere.IdInfermieri[indice].ToString() + "|" + infermiere.IdListaNecessita[indice].ToString() + "\">" + 
                                    "<div class=\"col-md-2\">" + infermiere.Causali[indice].ToString() + "</div>" +
                                    "<div class=\"col-md-1\">" + infermiere.PeriodoDa[indice].ToString() + "</div>" +
                                    "<div class=\"col-md-1\">" + infermiere.PeriodoA[indice].ToString() + "</div>" +
                                    "<div class=\"col-md-8\">" +
                                        "<div class=\"row\"> " +
                                               "<div class=\"col-md-7\">" +
                                                   " <select id =\"infermiere" + indice.ToString() + "\" class=\"form-control\" style=\"width: 100%; background-color: #FFFFFF;\" \">";
                                                    foreach (InfermieriDto w in this.InfermieriOrdinati.Self())
                                                    {                    
                                                       modalGialogHTML += "<option value=\"" + w.Id + "\">" + w.NomeCompleto + "</option>";
                                                    }
                                                    modalGialogHTML += "</select>" +
                                               "</div>" +
                                            "<div class=\"col-md-5\">" +
                                                "<span id=\"btnDel" + infermiere.IdListaNecessita[indice].ToString() + "\" class=\"btn btn-aip\" style=\"margin-top:-5px\">Cancella Necessità</span>" + 
                                            "</div>" +
                                        "</div> " +
                                    "</div>" +
                                  "</div> ";
                indice++;
            }


            modalGialogHTML += "</form>";

            var htmlElement = new HTMLElement();
            htmlElement.InnerHTML = modalGialogHTML;

            BootstrapDialog.Show(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Modifica associazione infermiere",
                Closable = false,
                Message = htmlElement.InnerHTML,
                OnShown = () =>
                {
                    var pageNode = dom.document.getElementsByClassName("modal-dialog")[0];

                    associaInfermiereViewModel.InitEditAssociaInfermiere(infermiere.IdInfermieri, this.EditId, "");

                    Retyped.knockout.ko.applyBindings(associaInfermiereViewModel, pageNode);
                                       

                    int i = 0;

                    while (i < infermiere.IdInfermieri.Count)
                    {
                        SetInfermiereStruttura(i, infermiere.IdInfermieri[i], alert);

                        var infermiereId = string.Format("#infermiere{0}",i);
                        var hiddenId = string.Format("#hidden{0}",i);
                        jQuery.Select(infermiereId).Val(infermiere.IdInfermieri[i].ToString()).Trigger("change");
                        Select2.Render((global::System.Action<object>)(o =>
                            {
                                int id = Convert.ToInt32(infermiereId.ToString().Replace("#infermiere", ""));
                                this.CambiaInfermiereStruttura(infermiereId, jQuery.Select(infermiereId).Val(), alert);

                            }), infermiereId);
                        Script.Write("$(\".bootstrap-dialog\").removeAttr(\"tabindex\");");

                        jQuery.Select(string.Format("#btnDel{0}",infermiere.IdListaNecessita[i])).On("click", (global::System.Action)(() =>
                        {
                            this.CancellaNecessita(infermiereId);
                        }));
                        
                        i++;
                    }

                    Script.Call("SetWidthModalStruttura", "modal-850");
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    }
                }
            });
        }

        #region  AggiungiNecessita
        public void AggiungiNecessita()
        {
            int idInfermiere = 0;

            var modalGialogHTML = "<form class=\"form-horizontal\" role=\"form\"> " +
                                  " <div class=\"form-group\"> <div class=\"col-md-12\"><span id=\"Errore\" data-bind=\"text: necessitaGiornoKo.Errore;\" style=\"background-color: red \" class=\"floating-label\"></span></div> </div>" +
                                  " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Causale\">Causale<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\"><select id =\"Causale\" class=\"form-control\" data-bind=\"textInput: necessitaGiornoKo.Causale\" style=\"width: 100%; background-color: #FFFFFF;\" \">";


            foreach (TipiTurniDto t in this.TipiTurnoStruttura.Self())
            {
                modalGialogHTML += "<option value=\"" + t.Causale + "\">" + t.Causale + "</option>";
            }

            modalGialogHTML += " </select> </div> </div>" +
                                " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"Data\">Data<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\"><input type=\"text\" id=\"Data\" data-bind=\"textInput: necessitaGiornoKo.Data\" class=\"dateTimePicker form-control floating-label\"></div> </div>" +
                                " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoDa\">Da<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\"><input type=\"text\" id=\"PeriodoDa\" data-bind=\"textInput: necessitaGiornoKo.PeriodoDa\" class=\"form-control floating-label\" maxLength=\"5\" placeholder=\"__:__\"></div> </div>" +
                                " <div class=\"form-group\"> <label class=\"col-md-4 control-label\" for=\"PeriodoA\">A<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\"><input type=\"text\" id=\"PeriodoA\" class=\"form-control floating-label\" data-bind=\"textInput: necessitaGiornoKo.PeriodoA\" maxLength=\"5\" placeholder=\"__:__\"></div> </div> " +
                                " <div class=\"clearfix\"></div>" +
                                "</form>";

            var htmlElement = new HTMLElement();
            htmlElement.InnerHTML = modalGialogHTML;

            NecessitaGiorniViewModel necessitaGiorniViewModel = null;

            BootstrapDialog.Show(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Nuova necessità",
                Closable = false,
                Message = htmlElement.InnerHTML,
                OnShown = () =>
                {
                    var pageNode = dom.document.getElementsByClassName("modal-dialog")[0];

                    BootstrapMaterialDatePicker.Render(new BootstrapMaterialDatePickerOption()
                    {
                        Time = false,
                        WeekStart = 0
                    }, ".dateTimePicker");
                    
                    necessitaGiorniViewModel = Bridge.Spaf.SpafApp.Container.Resolve<NecessitaGiorniViewModel>();

                    necessitaGiorniViewModel.InitNuovaNecessitaGiorni(this.TurnazioneMensileId.Self(), idInfermiere, this.AnnoCorrente.Self(), this.MeseCorrente.Self(), "", "");

                    Retyped.knockout.ko.applyBindings(necessitaGiorniViewModel, pageNode);
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Salva",
                        Action = async(o) => {
                            necessitaGiorniViewModel.necessitaGiornoKo.Errore.Self("");
                            Block();
                            bool errore = false;
                            string messaggioErrore = "ATTENZIONE! ";
                            string messaggioErroreDa = "Il campo Da non è nel formato corretto. ";
                            string messaggioErroreA = "Il campo A non è nel formato corretto. ";
                            string messaggioErroreDaA = "I campi Da e A non sono nel formato corretto. ";
                            bool erroreDa = false;
                            bool erroreA = false;

                            TimeSpan da = TimeSpan.MinValue;
                            TimeSpan a = TimeSpan.MinValue;
                            if(necessitaGiorniViewModel.necessitaGiornoKo.Data.Self() == "")
                            {
                                errore = true;
                                messaggioErrore += "E' necessario inserire una data.";
                            }
                            else
                            {
                                DateTime dateTime = DateTime.ParseExact(necessitaGiorniViewModel.necessitaGiornoKo.Data.Self(), "yyyy-MM-dd", CultureInfo.InvariantCulture);
                                
                                int mese = this.MeseCorrente.Self();
                                int anno = this.AnnoCorrente.Self();
                                int giorno = DateTime.DaysInMonth(anno, mese);

                                DateTime dataUltimoGiornoMese = Convert.ToDateTime(anno.ToString() + "-" + mese.ToString().PadLeft(2, '0') + "-" + giorno.ToString().PadLeft(2, '0'));

                                if(DateTime.Compare(dateTime, dataUltimoGiornoMese) > 0) {
                                    errore = true;
                                    messaggioErrore += "La data selezionata deve essere compresa nel mese selezionato!";
                                }
                            }

                            try
                            {
                                string[] values = necessitaGiorniViewModel.necessitaGiornoKo.PeriodoDa.Self().Split(':');
                                da = new TimeSpan(Convert.ToInt32(values[0]), Convert.ToInt32(values[1]), 0);
                            }
                            catch
                            {
                                errore = true;
                                erroreDa = true;
                            }

                            try
                            {
                                string[] values = necessitaGiorniViewModel.necessitaGiornoKo.PeriodoA.Self().Split(':');
                                a = new TimeSpan(Convert.ToInt32(values[0]), Convert.ToInt32(values[1]), 0);
                            }
                            catch
                            {
                                errore = true;
                                erroreA = true;
                            }
                            if(errore)
                            {
                                if(erroreDa && erroreA)
                                    messaggioErrore += messaggioErroreDaA;
                                else if(erroreDa && !erroreA)
                                    messaggioErrore += messaggioErroreDa;
                                 else if(!erroreDa && erroreA)
                                    messaggioErrore += messaggioErroreA;
                            }

                            if(!errore)
                            {
                                if(da > a)
                                {
                                    errore = true;
                                    messaggioErrore += " Fasce orarie non coerenti.";
                                }
                            }

                            if(errore)
                                necessitaGiorniViewModel.necessitaGiornoKo.Errore.Self(messaggioErrore);
                            else
                            {
                                DateTime dateTime = DateTime.ParseExact(necessitaGiorniViewModel.necessitaGiornoKo.Data.Self(), "yyyy-MM-dd", CultureInfo.InvariantCulture);

                                this.MeseCorrente.Self(Convert.ToInt32(dateTime.Month));
                                this.AnnoCorrente.Self(Convert.ToInt32(dateTime.Year));

                                jQuery.Select("#mese").Val(Convert.ToString(dateTime.Month)).Trigger("change");
                                jQuery.Select("#anno").Val(Convert.ToString(dateTime.Year)).Trigger("change");

                                PeriodiDto periodo = new PeriodiDto();
                                string periodoDa = necessitaGiorniViewModel.necessitaGiornoKo.PeriodoDa.Self();
                                string periodoA = necessitaGiorniViewModel.necessitaGiornoKo.PeriodoA.Self();

                                var necessitaGiornoDto = new NecessitaGiornoDto()
                                {
                                    IdTurnazioneMensile = necessitaGiorniViewModel.necessitaGiornoKo.IdTurnazioneMensile.Self(),
                                    IdInfermiere = necessitaGiorniViewModel.necessitaGiornoKo.IdInfermiere.Self(),
                                    Data = dateTime,
                                    PeriodoDa = periodoDa,
                                    PeriodoA = periodoA,
                                    Causale = necessitaGiorniViewModel.necessitaGiornoKo.Causale.Self()
                                };

                                o.ToDynamic().close();
                                try
                                {

                                    NecessitaGiornoDto necessitaGiorno = await _necessitaGiorniService.AddNecessita(necessitaGiorniViewModel.necessitaGiornoKo.IdTurnazioneMensile.Self(),
                                                                                                                    necessitaGiorniViewModel.necessitaGiornoKo.IdInfermiere.Self(),
                                                                                                                    necessitaGiorniViewModel.necessitaGiornoKo.Data.Self(),
                                                                                                                    necessitaGiorniViewModel.necessitaGiornoKo.Causale.Self(), 
                                                                                                                    necessitaGiorniViewModel.necessitaGiornoKo.PeriodoDa.Self(),
                                                                                                                    necessitaGiorniViewModel.necessitaGiornoKo.PeriodoA.Self());

                                    Script.Call("DestroyTableSawStruttura");
                                    await LoadCalendario(dateTime.Year, dateTime.Month,this.EditId);
                                    Script.Call("RefreshTableSawStruttura");
                                }
                                catch
                                {
                                    necessitaGiorniViewModel.necessitaGiornoKo.Errore.Self("ATTENZIONE! Errore in fase di inserimento della necessità");
                                }
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        #region VisualizzaAggiungiNecessita
        public void VisualizzaAggiungiNecessita ()
        {
            int idAnno = AnnoCorrente.Self();
            int idMese = this.MeseCorrente.Self();
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Aggiungi necessità",
                Closable = false,
                Message = "Vuoi rendere visibile il pulsante Aggiungi Necessità per questa struttura?",
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (e) =>
                        {
                            Block();
                            try
                            {
                                var result = await _turnazioniMensiliService.VisualizzaAggiungiNecessita(idAnno, idMese, EditId);
                                e.ToDynamic().close();
                                if (result)
                                {
                                    BootstrapDialog.Alert("Ricarica la pagina per vedere il pulsante");
                                }
                                else
                                {
                                    BootstrapDialog.Alert("ATTENZIONE: qualcosa è andato storto, chiamare assistenza !");
                                }
                            }
                            catch (Exception ex)
                            {
                                string err = ex.Message;
                                BootstrapDialog.Alert("ATTENZIONE! Errore in fase di visualizzazione del pulsante Aggiungi Necessità");
                            }

                            UnBlock();
                        }
                    }
                }
            });
        }
        #endregion

        public void EsportaTurniStruttura()
        {
            this.ExportLink.Self(_exportService.ExportFoglioPresenzePerStruttura(this.AnnoCorrente.Self(), this.MeseCorrente.Self(), this.EditId));
            Bridge.Utils.Console.Log(ExportLink.Self());
            Global.Open(ExportLink.Self(), "_blank");
        }
        public void ListaIndisponibilita(InfermieriDto Infermiere)
        {
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("infermiereId",Infermiere.Id);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.Indisponibilita, parameters);
        }
    }
}
