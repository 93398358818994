using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class GiorniService: IGiorniService
    {
        private readonly IGiorniResource _giorniResource;

        public GiorniService(IGiorniResource GiorniResource)
        {
            _giorniResource = GiorniResource;
        }

        public Task<GiorniDto[]> GetAllGiorni(int idStruttura, int anno, int mese)
        {
            return _giorniResource.GetAllGiorni(idStruttura, anno, mese);
        }

        public Task<GiorniDto[]> AggiungiSettimana(int strutturaId, int periodoId)
        {
            return _giorniResource.AggiungiSettimana(strutturaId, periodoId);
        }

        public Task<BaseDtoOutputModel<GiorniDto>> GetAllGiorni(int anno, int mese)
        {
            return _giorniResource.GetAllGiorni(anno, mese);
        }

        public Task RimuoviSettimana(int strutturaId, int periodoId)
        {
            return _giorniResource.RimuoviSettimana(strutturaId, periodoId);
        }
    }
}
