using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class StruttureResource: AuthorizedResourceBase, IStruttureResource
    {
        public Task<BaseDtoOutputModel<StruttureDto>> GetAllStrutture()
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild("/strutture"),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<BaseDtoOutputModel<StruttureDto>>(options);
        }

        public Task<InfermiereOrdinatoDto[]> GetInfermieriOrdinatiByStruttura(int strutturaId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/strutture/GetInfermieriOrdinati?idStruttura=")+ strutturaId),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<InfermiereOrdinatoDto[]>(options);
        }

        public Task<InfermieriDto[]> GetOrderedAssociatedInfermieriForStruttura(int strutturaId, int all)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/{1}",strutturaId,all)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<InfermieriDto[]>(options);
        }

        public Task<StruttureDto> GetStrutturaById(int editId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/strutture/{0}",editId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<StruttureDto>(options);
        }

        public Task<StruttureDto> EditStruttura(int strutturaId, StruttureDto dto)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}",strutturaId)),
                Type = "PUT",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(dto)
            };

            return base.MakeCall<StruttureDto>(options);
        }

        public StruttureResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
