using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Newtonsoft.Json;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    class ListaErrori
    {
        public int Id;
        public string Errore;

        public ListaErrori(int id, string errore)
        {
            Id = id;
            Errore = errore;
        }
    }

    public class StruttureGiorniResource: AuthorizedResourceBase, IStruttureGiorniResource
    {
      

        public Task<BaseDtoOutputModel<StruttureGiorniErroriDto>> GetErrori(int idStruttura, int idGiorno)
        {
            BaseDtoOutputModel<StruttureGiorniErroriDto> errori = new BaseDtoOutputModel<StruttureGiorniErroriDto>();

            StruttureGiorniErroriDto[] items = new StruttureGiorniErroriDto[10];

            int index = 0;

            while (index < 10)
            {
                items[index] = new StruttureGiorniErroriDto();
                items[index].IdErrore = index + 1;
                items[index].Errore = "Errore in data " + Convert.ToString(index + 1) + "/05/2018";

                index++;
            }

            errori.Items = items;

            return Task.FromResult<BaseDtoOutputModel<StruttureGiorniErroriDto>>(errori);
        }

        public StruttureGiorniResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }
    }
}
