using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Navigation;
using Retyped;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Necessita
{
    public class OverlappingNecessitaViewModel : BlockableViewModel
    {
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.OverlapNecessita;
}        
        private readonly IGiorniService _giornoService;
        private readonly IFasceOrarieService _fasceOrarieService;
        private readonly IInfermieriService _infermieriService;
        private readonly ICookieService _cookieService;
        private readonly IIndisponibilitaService _indisponibilitaService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        
        
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.OverlapNecessitaDto>OverlappedNecessita { get; set; }
        public knockout.KnockoutObservableArray<AnniDto> Anni { get; set; }
        public knockout.KnockoutObservableArray<int> AnnoSelectedId { get; set; }
        public knockout.KnockoutObservable<string> AnnoFilterCaption { get; set; }

        public knockout.KnockoutObservableArray<MesiDto> Mesi { get; set; }
        public knockout.KnockoutObservableArray<int> MeseSelectedId { get; set; }
        public knockout.KnockoutObservable<string> MeseFilterCaption { get; set; }

        public knockout.KnockoutObservableArray<GiorniDto> Giorni { get; set; }
        public knockout.KnockoutObservableArray<int> GiornoSelectedId { get; set; }
        public knockout.KnockoutObservable<string> GiornoFilterCaption { get; set; }

        public knockout.KnockoutObservableArray<FasceOrarieDto> FasceOrarie { get; set; }
        public knockout.KnockoutObservableArray<int> FasciaOrariaSelected { get; set; }
        public knockout.KnockoutObservable<string> FasciaFilterCaption { get; set; }

        public knockout.KnockoutObservableArray<InfermieriDto> Infermieri { get; set; }
        public knockout.KnockoutObservableArray<int> InfermiereSelectedId { get; set; }
        public knockout.KnockoutObservable<string> InfermiereFilterCaption { get; set; }

        public knockout.KnockoutObservableArray<string> Causali { get; set; }
        public knockout.KnockoutObservableArray<string> CausaleSelected { get; set; }
        public knockout.KnockoutObservable<string> CausaleFilterCaption { get; set; }
        
        public knockout.KnockoutObservable<bool> LoaderPage { get; set; }

        public OverlappingNecessitaViewModel(IIndisponibilitaService indisponibilitaService, IInfermieriService infermieriService, ICookieService cookieService, IFasceOrarieService fasceOrarieService, IGiorniService giornoService, INecessitaGiorniService necessitaGiorniService)
        {
            _indisponibilitaService = indisponibilitaService;
            _infermieriService = infermieriService;
            _cookieService = cookieService;
            _fasceOrarieService = fasceOrarieService;
            _giornoService = giornoService;
            _necessitaGiorniService = necessitaGiorniService;
            OverlappedNecessita = Retyped.knockout.ko.observableArray.Self<OverlapNecessitaDto>();
            Anni = Retyped.knockout.ko.observableArray.Self<AnniDto>();
            Mesi = Retyped.knockout.ko.observableArray.Self<MesiDto>();
            Giorni = Retyped.knockout.ko.observableArray.Self<GiorniDto>();
            FasceOrarie = Retyped.knockout.ko.observableArray.Self<FasceOrarieDto>();
            Infermieri = Retyped.knockout.ko.observableArray.Self<InfermieriDto>();
            Causali = Retyped.knockout.ko.observableArray.Self<string>();

            AnnoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            MeseSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            GiornoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            FasciaOrariaSelected = Retyped.knockout.ko.observableArray.Self<int>();
            InfermiereSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            CausaleSelected = Retyped.knockout.ko.observableArray.Self<string>();
            LoaderPage = Retyped.knockout.ko.observable.Self<bool>();

            AnnoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Anno...");
            MeseFilterCaption = Retyped.knockout.ko.observable.Self<string>("Mese...");
            GiornoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Giorno...");
            FasciaFilterCaption = Retyped.knockout.ko.observable.Self<string>("Orario...");
            InfermiereFilterCaption = Retyped.knockout.ko.observable.Self<string>("Infermiere...");
            CausaleFilterCaption = Retyped.knockout.ko.observable.Self<string>("Causali...");
        }
        
        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            LoaderPage.Self(true);

            base.OnLoad(parameters);

            await Hydrate(parameters);

            LoaderPage.Self(false);
        }

        private async Task Hydrate(Dictionary<string,object> parameters)
        {
            var anni = _indisponibilitaService.GetAnni();
            var infermieri = _infermieriService.GetAllInfermieri();
            var mesi = _indisponibilitaService.GetMesi();
            var giorni = _giornoService.GetAllGiorni(2018, 7); //Always load 31 days
            var fascie = _fasceOrarieService.GetAllFasceOrarie();
            await Task.WhenAll(anni, mesi, giorni, fascie, infermieri);

            Mesi.removeAll();
            Giorni.removeAll();
            FasceOrarie.removeAll();

            Anni.push(anni.Result.Items);
            Infermieri.push(infermieri.Result.Items);
            Mesi.push(mesi.Result.Items);
            Giorni.push(giorni.Result.Items);
            FasceOrarie.push(fascie.Result.Items);
            if (parameters.Count == 0)
            {
                var year = DateTime.Now.Year;
                var month = DateTime.Now.Month;

                var defaultDate = _cookieService.GetDefaultDate();
                if (defaultDate.HasValue)
                {
                    year = defaultDate.Value.Year;
                    month = defaultDate.Value.Month;
                }

                AnnoSelectedId.push(year);
                MeseSelectedId.push(month);

                LoadFilters();

            } else {

                int anno = (parameters.GetParameter<int>("anno"));
                int mese = (parameters.GetParameter<int>("mese"));
                int giorno = (parameters.GetParameter<int>("giorno"));
                int fasciaorariaId = (parameters.GetParameter<int>("fasciaOraria"));

                //Set Selected filter with current dto
                AnnoSelectedId.push(anno);
                MeseSelectedId.push(mese);
                GiornoSelectedId.push(giorno);
                FasciaOrariaSelected.push(fasciaorariaId);
                RemoveCaptionGiorno();
                RemoveCaptionFascieOrarie();
                RemoveCaptionInfermiere();
                
                string fascia = await _fasceOrarieService.GetFasciaOrariaById(fasciaorariaId);
                var overlapped = await _necessitaGiorniService.GetOverlappedNecessita(anno, mese, giorno, fascia, fascia, 0);
                OverlappedNecessita.removeAll();
                OverlappedNecessita.push(overlapped);

            }
        }
        
        public async void LoadFilters()
        {
            LoaderPage.Self(true);
            string fascia = null;
            int anno = 0;
            int mese = 0;
            int giorno = 0;
            int infermiere = 0;

            if (FasciaOrariaSelected.Self().Length > 0)
                fascia = await _fasceOrarieService.GetFasciaOrariaById(FasciaOrariaSelected.Self()[0]);
            if (AnnoSelectedId.Self().Length > 0)
                anno = AnnoSelectedId.Self()[0];
            if (MeseSelectedId.Self().Length > 0)
                mese = MeseSelectedId.Self()[0];
            if (GiornoSelectedId.Self().Length > 0)
                giorno = GiornoSelectedId.Self()[0];
            if (InfermiereSelectedId.Self().Length > 0)
                infermiere = InfermiereSelectedId.Self()[0];

            var overlapped = await _necessitaGiorniService.GetOverlappedNecessita(anno, mese, giorno, fascia, fascia, infermiere);
            OverlappedNecessita.removeAll();
            OverlappedNecessita.push(overlapped);
            
            LoaderPage.Self(false);
        }

        private void ClearFilters()
        {
            AnnoFilterCaption.Self("Anno...");
            MeseFilterCaption.Self("Mese...");
            GiornoFilterCaption.Self("Giorno...");
            FasciaFilterCaption.Self("Orario...");
            AnnoSelectedId.removeAll();
            MeseSelectedId.removeAll();
            GiornoSelectedId.removeAll();
            FasciaOrariaSelected.removeAll();
            CausaleFilterCaption.Self("Causali...");
            CausaleSelected.removeAll();
            Causali.removeAll();
            InfermiereFilterCaption.Self("Infermiere...");
            InfermiereSelectedId.removeAll();
        }
        
        #region Remove caption

        private void RemoveCaptionAnno()
        {
            AnnoFilterCaption.Self(null);
        }
        
        private void RemoveCaptionMese()
        {
            MeseFilterCaption.Self(null);
        }
        
        private void RemoveCaptionGiorno()
        {
            GiornoFilterCaption.Self(null);
        }
        
        private void RemoveCaptionFascieOrarie()
        {
            FasciaFilterCaption.Self(null);
        }
        
        private void RemoveCaptionCausale()
        {
            CausaleFilterCaption.Self(null);
        }
        
        private void RemoveCaptionInfermiere()
        {
            InfermiereFilterCaption.Self(null);
        }

        #endregion
        
    }
}