using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class InfermieriService: IInfermieriService
    {
        private readonly IInfermieriResource _infermieriResource;

        public InfermieriService(IInfermieriResource InfermieriResource)
        {
            _infermieriResource = InfermieriResource;
        }

        public Task<InfermieriDto> Infermiere(int id)
        {
            return _infermieriResource.Infermiere(id);
        }

        public Task DeleteInfermiere(int id)
        {
            return _infermieriResource.DeleteInfermiere(id);
        }

        public Task<BaseDtoOutputModel<InfermieriDto>> GetAllInfermieri()
        {
            return _infermieriResource.GetAllInfermieri();
        }

        public Task<BaseDtoOutputModel<InfermieriDto>> GetAllInfermieriForHomePage()
        {
            return _infermieriResource.GetAllInfermieriForHomePage();
        }
        
        public Task<BaseDtoOutputModel<InfermieriDto>> GetAllInfermieriDisponibili(int idNecessita)
        {
            return _infermieriResource.GetAllInfermieriDisponibili(idNecessita);
        }

        public async Task<BaseDtoOutputModel<InfermiereOrdinatoDto>> GetAllInfermieriMap()
        {
            var ret = new List<InfermiereOrdinatoDto>();
            var empty = new InfermiereOrdinatoDto
            {
                Id = 0,
                Infermiere = new InfermieriDto { Id = 0, Cognome = "", MexalId = "", Nome = "", NomeCompleto = "" },
                NomeCompletoPriorita = "",
                Priorita = -1
            };
            ret.Add(empty);
            var val = await _infermieriResource.GetAllInfermieri();
            var infermieri = val.Items;
            foreach (var item in infermieri)
            {
                var inford = new InfermiereOrdinatoDto {
                    Id = item.Id,
                    Infermiere = item,
                    NomeCompletoPriorita = item.NomeCompleto,
                    Priorita = -1
                };
                ret.Add(inford);
            }
            ret = System.Linq.Enumerable.OrderBy<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermiereOrdinatoDto,string>(ret,(global::System.Func<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermiereOrdinatoDto, string>)(i => i.Infermiere.Cognome)).ToList();
            return new BaseDtoOutputModel<InfermiereOrdinatoDto> { Items = ret.ToArray()};
        }
    }
}
