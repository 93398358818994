using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class DashboardService: IDashboardService
    {
        private readonly IDashboardResource _dashboardResource;

        public DashboardService(IDashboardResource DashboardResource)
        {
            _dashboardResource = DashboardResource;
        }

        public Task<DashboardDto[]> GetDashboardPerMese(int anno, int mese)
        {
            return _dashboardResource.GetDashboardPerMese(anno, mese);
        }

        public Task<BaseDtoOutputModel<MesiDto>> GetMesi()
        {
            return _dashboardResource.GetMesi();
        }

        public Task<BaseDtoOutputModel<AnniDto>> GetAnni()
        {
            return _dashboardResource.GetAnni();
        }
    }
}
