using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.KnockoutModels;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Anomalie
{
    public class ResolveAnomaliaViewModel : LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IInfermieriService _infermieriService;
        private readonly IStruttureService _struttureService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        private readonly IAnomalieService _anomaliaService;
        private readonly IMessenger _messenger;

        public Aip.Florence.FrontEnd.SpafApp.ViewModels.Anomalie.AnomaliaViewModel.AnomaliaKo AnomaliaSelected { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermiereOrdinatoDto>Infermieri { get; set; }
        public Retyped.knockout.KnockoutObservable <int>InfermiereSelected { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>AllInfermieri { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>Loader { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.ConflittoDto>ConflittiTurni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.ConflittoDto>ConflittiIndisponibilita { get; set; }

        public int AnnoSelectedId { get; set; }
        public int MeseSelectedId { get; set; }
        public int GiornoSelectedId { get; set; }
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.ResolveAnomaliaId;
}
        public ResolveAnomaliaViewModel(INavigator navigator, IInfermieriService infermieriService, IStruttureService struttureService, INecessitaGiorniService necessitaGiorniService, 
                                        IMessenger messenger, IAnomalieService anomaliaService)
        {
            _navigator = navigator;
            _infermieriService = infermieriService;
            _struttureService = struttureService;
            _necessitaGiorniService = necessitaGiorniService;
            _anomaliaService = anomaliaService;
            _messenger = messenger;

            AnomaliaSelected = new Aip.Florence.FrontEnd.SpafApp.ViewModels.Anomalie.AnomaliaViewModel.AnomaliaKo(null);
            InfermiereSelected = Retyped.knockout.ko.observable.Self<int>();
            Infermieri = Retyped.knockout.ko.observableArray.Self<InfermiereOrdinatoDto>();
            AllInfermieri = Retyped.knockout.ko.observable.Self<bool>(false);
            ConflittiTurni = Retyped.knockout.ko.observableArray.Self<ConflittoDto>();
            ConflittiIndisponibilita = Retyped.knockout.ko.observableArray.Self<ConflittoDto>();
            Loader = Retyped.knockout.ko.observable.Self<bool>(false);
        }

        public async void LoadInfermiereTurnazione()
        {
            Loader.Self(true);
            Script.Call("DisableConfirmButton");
            ConflittiTurni.removeAll();
            ConflittiIndisponibilita.removeAll();
            if(InfermiereSelected.Self() > -1)
            {
                var conflics = await _anomaliaService.LoadConflicts(AnnoSelectedId, MeseSelectedId, GiornoSelectedId, AnomaliaSelected.PeriodoDa.Self(), AnomaliaSelected.PeriodoA.Self(), InfermiereSelected.Self());
                foreach (var item in conflics)
                {
                    if (item.Stato == StatoInfermiere.Indisponibile)
                        ConflittiIndisponibilita.push(item);
                    if (item.Stato == StatoInfermiere.Allocato)
                        ConflittiTurni.push(item);
                }
                if (ConflittiIndisponibilita.Self().Length == 0)
                    Script.Call("EnableConfirmButton");
            }
            Loader.Self(false);
        }
    }
}
