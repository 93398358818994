using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class ClientiService : IClientiService
    {
        public Task<ClientiDto[]> GetAllClienti()
        {
            throw new NotImplementedException();
        }

        public Task<ClientiDto> GetClientiById(int id)
        {
            throw new NotImplementedException();
        }
    }
}
