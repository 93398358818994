using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CrossFasceOrarieGiorniPerIndisponibilitaDto
    {
        [JsonProperty("id")]
        public int Id { get; set; }

        [JsonProperty("idinfermiere")]
        public int IdInfermiere { get; set; }

        [JsonProperty("idgiorno")]
        public int IdGiorno { get; set; }

        [JsonProperty("idfasciaoraria")]
        public int IdFasciaOraria { get; set; }

        [JsonProperty("indisponibile")]
        public int Indisponibile { get; set; }
        
        public CrossFasceOrarieGiorniPerIndisponibilitaDto(int id, int idInfermiere, int idGiorno, int idFasciaOraria, int indisponibile)
        {
            Id = id;
            IdInfermiere = idInfermiere;
            IdFasciaOraria = idFasciaOraria;
            IdGiorno = idGiorno;
            Indisponibile = indisponibile;
        }
    }
}
