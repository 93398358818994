using System.Collections.Generic;
using Aip.Florence.FrontEnd.SpafApp.ViewModels;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.Anomalie;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.Indisponibilita;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.Infermieri;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.Necessita;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.PeriodoPrototipo;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.Timbrature;
using Aip.Florence.FrontEnd.SpafApp.ViewModels.TipiTurni;
using Bridge.jQuery2;
using Bridge.Navigation;

namespace Bridge.Spaf
{
    class CustomRoutesConfig : BridgeNavigatorConfigBase
    {
        public override IList<IPageDescriptor> CreateRoutes()
        {
            return global::Bridge.Script.CallFor(new List<IPageDescriptor>(),(_o1)=>{_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = ()=>"pages/login.html", // yout html location
                    Key = SpafApp.LoginId,
                    PageController = () => SpafApp.Container.Resolve<LoginViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = ()=>true,
                    HtmlLocation = ()=>"pages/home.html", // yout html location
                    Key = SpafApp.HomeId,
                    PageController = () => SpafApp.Container.Resolve<HomeViewModel>(),
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = ()=>"pages/infermieri/infermieri.html", // yout html location
                    Key = SpafApp.InfermieriId,
                    PageController = () => SpafApp.Container.Resolve<InfermiereViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = () => "pages/infermieri/editCreateInfermieri.html",
                    Key = SpafApp.EditCreateInfermieriId,
                    PageController = () => SpafApp.Container.Resolve<CreateEditInfermiereViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = ()=>"pages/strutture/strutture.html", // yout html location
                    Key = SpafApp.StruttureId,
                    PageController = () => SpafApp.Container.Resolve<StrutturaViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = () => "pages/strutture/editStrutture.html",
                    Key = SpafApp.EditStruttureId,
                    PageController = () => SpafApp.Container.Resolve<EditStrutturaViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = () => "pages/tipiturni/editCreateTipiTurni.html",
                    Key = SpafApp.TipiTurniId,
                    PageController = () => SpafApp.Container.Resolve<EditTipiTurniViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = () => "pages/indisponibilita/indisponibilita.html",
                    Key = SpafApp.Indisponibilita,
                    PageController = () => SpafApp.Container.Resolve<ListaIndisponibilitaViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = () => "pages/periodoPrototipo/periodoPrototipo.html",
                    Key = SpafApp.PeriodoPrototipoId,
                    PageController = () => SpafApp.Container.Resolve<PeriodoPrototipoViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = () => "pages/anomalie/anomalie.html",
                    Key = SpafApp.AnomaliaId,
                    PageController = () => SpafApp.Container.Resolve<AnomaliaViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = () =>true,
                    HtmlLocation = () => "pages/timbrature/timbrature.html",
                    Key = SpafApp.Timbrature,
                    PageController = () => SpafApp.Container.Resolve<ListaTimbratureViewModel>()
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = ()=>true,
                    HtmlLocation = ()=>"pages/necessita/necessita.html",
                    Key = SpafApp.Necessita,
                    PageController = () => SpafApp.Container.Resolve<NecessitaViewModel>(),
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = ()=>true,
                    HtmlLocation = ()=>"pages/necessita/overlapNecessita.html",
                    Key = SpafApp.OverlapNecessita,
                    PageController = () => SpafApp.Container.Resolve<OverlappingNecessitaViewModel>(),
                });_o1.Add(new PageDescriptor
                {
                    CanBeDirectLoad = ()=>true,
                    HtmlLocation = ()=>"pages/necessita/alertNight.html",
                    Key = SpafApp.AlertNightNecessita,
                    PageController = () => SpafApp.Container.Resolve<AlertNightNecessitaViewModel>(),
                });return _o1;});
        }

        public override jQuery Body { get; private set; }
        public override string HomeId { get; private set; }
public override bool DisableAutoSpafAnchorsOnNavigate
{
    get
    {
        return false;
    }
}
    
private jQuery __Property__Initializer__Body=jQuery.Select("#pageBody");private string __Property__Initializer__HomeId=SpafApp.HomeId;}
}
