using System;
using Bridge;

namespace Aip.Florence.FrontEnd.SpafApp.Utility
{
    [External]
    [Namespace(false)]
    public static class Select2
    {
        [Template("$({selector}).select2().on('select2:select',{onSelect})")]
        public static void Render(Action<object> onSelect, string selector)
        {
            return;
        }
    }

    [External]
    public class Select2RenderOptions
    {
        public OnHelp OnHelp;
    }

    public class OnHelp
    {
        private readonly Action<object> _onAction;

        public OnHelp(Action<object> onAction)
        {
            _onAction = onAction;
        }

        public static OnHelp Build(Action<object> onAction)
        {
            return new OnHelp(onAction);
        }
    }
}