using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using Bridge.jQuery2;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class ExportService : IExportService
    {
        private readonly IExportResource exportResource;

        public ExportService(IExportResource exportResource)
        {
            this.exportResource = exportResource;
        }

        public string ExportStrutturePerInfermiere(int anno, int mese, int infermiereId)
        {
            return this.exportResource.ExportStrutturePerInfermiere(anno, mese, infermiereId);
        }

        public string ExportFoglioPresenzePerStruttura(int anno, int mese, int strutturaId)
        {
            return this.exportResource.ExportFoglioPresenzePerStruttura(anno, mese, strutturaId);

        }

        public async Task ExportReportPerMexal(int anno, int mese)
        {
            await this.exportResource.ExportReportPerMexal(anno, mese);
        }
       
    }
}
