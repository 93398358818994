using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Navigation;
using Bridge.Spaf;
using System.Collections.Generic;
using System.Threading.Tasks;
using Retyped;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture
{
    public class StrutturaViewModel : LoadableViewModel
    {
        private readonly IStruttureService _strutturaService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.StruttureId;
}
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.StruttureDto>Strutture { get; set; }
        public Retyped.knockout.KnockoutObservable <bool>Loader { get; set; }

        public StrutturaViewModel(IStruttureService StrutturaService)
        {
            _strutturaService = StrutturaService;
            Strutture = Retyped.knockout.ko.observableArray.Self<StruttureDto>();
            Loader = knockout.ko.observable.Self<bool>();
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            Loader.Self(true);
            base.OnLoad(parameters);

            await HydrateStrutture();
            Loader.Self(false);
        }

        private async Task HydrateStrutture()
        {
            var strutture = await _strutturaService.GetAllStrutture();

            this.Strutture.push(strutture.Items);
        }
    }
}
