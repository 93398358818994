using Newtonsoft.Json;
using System;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class AnomalieDto
    {
        [JsonProperty("id")]
        public int Id { get; set; }

        [JsonProperty("struttura")]
        public StruttureDto Struttura { get; set; }
        
        [JsonProperty("causale")]
        public string Causale { get; set; }
        
        [JsonProperty("periodo")]
        public PeriodiDto Periodo { get; set; }

        [JsonProperty("data")]
        public DateTime Data { get; set; }
        
        public string DataString { get{ return Data.Date.ToString("dd/MM/yyyy"); }}
    }
}
