using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class CrossFasceOrarieGiorniPerCalendarioStrutturaDto
    {
        [JsonProperty("idnecessita")]
        public int IdNecessita { get; set; }

        [JsonProperty("idlistanecessita")]
        public List<int> IdListaNecessita { get; set; }

        [JsonProperty("struttura")]
        public string Struttura { get; set; }

        [JsonProperty("causale")]
        public string Causale { get; set; }

        [JsonProperty("causali")]
        public List<string> Causali { get; set; }

        [JsonProperty("idinfermiere")]
        public int IdInfermiere { get; set; }

        [JsonProperty("infermiere")]
        public string Infermiere { get; set; }

        [JsonProperty("idinfermieri")]
        public List<int> IdInfermieri { get; set; }

        [JsonProperty("infermieri")]
        public List<string> Infermieri { get; set; }

        [JsonProperty("periododa")]
        public List<string> PeriodoDa { get; set; }

        [JsonProperty("periodoa")]
        public List<string> PeriodoA { get; set; }

        [JsonProperty("idgiorno")]
        public int IdGiorno { get; set; }

        [JsonProperty("idfasciaoraria")]
        public int IdFasciaOraria { get; set; }

        [JsonProperty("stato")]
        public int Stato { get; set; }

        [JsonProperty("tooltip")]
        public string Tooltip { get; set; }

        [JsonProperty("numeroinfermieri")]
        public int NumeroInfermieri { get; set; }

        [JsonProperty("giorniFestivi")]
        public string GiorniFestivi { get; set; }

        [JsonProperty("giorniPreFestivi")]
        public string GiorniPreFestivi { get; set; }

        [JsonProperty("patrono")]
        public string Patrono { get; set; }

        //public CrossFasceOrarieGiorniPerCalendarioStrutturaDto(int idNecessita, int idInfermiere, string infermiere, int idGiorno, string struttura, string causale, int idFasciaOraria, int stato)
        //{
        //    IdNecessita = idNecessita;
        //    Struttura = struttura;
        //    Causale = causale;
        //    IdInfermiere = idInfermiere;
        //    Infermiere = infermiere;
        //    IdFasciaOraria = idFasciaOraria;
        //    IdGiorno = idGiorno;
        //    Stato = stato;
        //}

        public CrossFasceOrarieGiorniPerCalendarioStrutturaDto(int idNecessita, List<int> idListaNecessita, List<int> idInfermieri, List<string> infermieri, int idGiorno, string struttura, List<string> causali, 
                                                               int idFasciaOraria, List<string> periodoDa, List<string> periodoA, int stato, string tooltip, int numeroInfermieri,
                                                               string giorniFestivi, string giorniPreFestivi, string patrono)
        {
            IdNecessita = idNecessita;
            IdListaNecessita = idListaNecessita;
            Struttura = struttura;
            Causali = causali;
            IdInfermieri = idInfermieri;
            Infermieri = infermieri;
            IdFasciaOraria = idFasciaOraria;
            IdGiorno = idGiorno;
            PeriodoDa = periodoDa;
            PeriodoA = periodoA;
            Stato = stato;
            Tooltip = tooltip;
            NumeroInfermieri = numeroInfermieri;
            GiorniFestivi = giorniFestivi;
            GiorniPreFestivi = giorniPreFestivi;
            Patrono = patrono;
        }
    }
}
