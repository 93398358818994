using Bridge.Messenger;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Utility
{
    public class ExternalCallScripts
    {

        public static void RimuoviUltimaSettimana()
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender>(Bridge.Spaf.SpafApp.Messages.Sender, Bridge.Spaf.SpafApp.Messages.RimuoviUltimaSettimana);
        }

        public static void AggiungiSettimana()
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender>(Bridge.Spaf.SpafApp.Messages.Sender,  Bridge.Spaf.SpafApp.Messages.AggiungiSettimana);
        }

        public static void CambiaPaginaPeriodoPrototipo()
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender>(Bridge.Spaf.SpafApp.Messages.Sender, Bridge.Spaf.SpafApp.Messages.CambiaPaginaPeriodoPrototipo);
        }



        public static void CambiaAnnoMeseInfermiere(int idAnno, int idMese)
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender,global::Aip.Florence.FrontEnd.SpafApp.Utility.AnnoMeseArguments>(Bridge.Spaf.SpafApp.Messages.Sender,
                Bridge.Spaf.SpafApp.Messages.CambiaAnnoMeseInfermiere, new AnnoMeseArguments()
                {
                    IdMese = idMese,
                    IdAnno = idAnno
                });
        }

        public static void CambiaAnnoMese(int idMese, int idAnno)
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender,global::Aip.Florence.FrontEnd.SpafApp.Utility.FasceOrarieGiorniArguments>(Bridge.Spaf.SpafApp.Messages.Sender,
                Bridge.Spaf.SpafApp.Messages.ModificaMeseAnno, new FasceOrarieGiorniArguments()
                {
                    IdMese = idMese,
                    IdAnno = idAnno
                });
        }

        public static void AnomalieStruttura(int idStruttura)
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender,global::Aip.Florence.FrontEnd.SpafApp.Utility.StrutturaArguments>(Bridge.Spaf.SpafApp.Messages.Sender,
                Bridge.Spaf.SpafApp.Messages.AnomalieStruttura, new StrutturaArguments()
                {
                    IdStruttura = idStruttura
                });
        }

        public static void ChiudiTurnazioneMensile(int idAnno, int idMese)
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender,global::Aip.Florence.FrontEnd.SpafApp.Utility.FasceOrarieGiorniArguments>(Bridge.Spaf.SpafApp.Messages.Sender,
                Bridge.Spaf.SpafApp.Messages.ChiudiTurnazioneMensile, new FasceOrarieGiorniArguments()
                {
                    IdMese = idMese,
                    IdAnno = idAnno
                });
        }

       
        public static void CambiaInfermierePeriodoPrototipo(int id, int idInfermiere, int alert)
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender,global::Aip.Florence.FrontEnd.SpafApp.Utility.InfermiereArguments>(Bridge.Spaf.SpafApp.Messages.Sender,
                Bridge.Spaf.SpafApp.Messages.CambiaInfermierePeriodoPrototipo, new InfermiereArguments()
                {
                    Id = id,
                    IdInfermiere = idInfermiere,
                    Alert = alert
                });
        }

        public static void RefreshInfermieriListAll()
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender>(Bridge.Spaf.SpafApp.Messages.Sender,
                Bridge.Spaf.SpafApp.Messages.RefreshInfermieriListAll);
        }

        public static void RefreshInfermieriListByStructure()
        {
            var messanger = Bridge.Spaf.SpafApp.Container.Resolve<IMessenger>();
            messanger.Send<global::Bridge.Spaf.SpafApp.Messages.GlobalSender>(Bridge.Spaf.SpafApp.Messages.Sender,
                Bridge.Spaf.SpafApp.Messages.RefreshInfermieriListByStructure);
        }
    }

    public class AnnoMeseArguments
    {
        public int IdMese { get; set; }
        public int IdAnno { get; set; }
    }

    public class FasceOrarieGiorniArguments
    {
        public int IdMese { get; set; }
        public int IdAnno { get; set; }
    }

    public class StrutturaArguments
    {
        public int IdStruttura { get; set; }
    }

    public class InfermiereArguments
    {
        public int Id { get; set; }
        public int IdInfermiere { get; set; }
        public int Alert { get; set; }
    }

    public class NecessitaArguments
    {
        public int Id { get; set; }
        public int IdNecessita { get; set; }
    }
}
