using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class NecessitaGiorniService : INecessitaGiorniService
    {
        private readonly INecessitaGiorniResource _necessitaGiorniResource;

        public NecessitaGiorniService(INecessitaGiorniResource NecessitaGiorniResource)
        {
            _necessitaGiorniResource = NecessitaGiorniResource;
        }

        public Task<NecessitaGiornoDto[]> GetTurnazioneMensileForDayForInfermiere(int strutturaId, int anno, int mese, int giorno, string da, string a, int idInfermiere)
        {
            return _necessitaGiorniResource.GetTurnazioneMensileForDayForInfermiere(strutturaId, anno, mese, giorno, da,a, idInfermiere);
        }

        public Task<NecessitaGiornoDto[]> GetTurnazioneMensileForInfermiere(int anno, int mese, int idInfermiere)
        {
            return _necessitaGiorniResource.GetTurnazioneMensileForInfermiere(anno, mese, idInfermiere);
        }

        public Task<NecessitaGiornoDto> GetEntityById(int entityKey, int id)
        {
            return _necessitaGiorniResource.GetEntityById(entityKey, id);
        }

        public Task<NecessitaGiornoDto> GetById(int idNecessita)
        {
            return _necessitaGiorniResource.GetById(idNecessita);
        }

        public Task<int> Put(int idNecessita, int idInfermiere, int idNecessitaRemove)
        {
            return _necessitaGiorniResource.Put(idNecessita, idInfermiere, idNecessitaRemove);
        }

        public Task Delete(int idNecessita)
        {
            return _necessitaGiorniResource.Delete(idNecessita);
        }

        public Task<NecessitaGiornoDto> AddNecessita(int idTurnazioneMensile, int idInfermiere, string date, string causale, string periodoDa, string periodoA)
        {
            return _necessitaGiorniResource.AddNecessita(idTurnazioneMensile, idInfermiere, date, causale, periodoDa, periodoA);
        }

        public Task<NecessitaGiornoDto[]> GetNecessitaGiorno(int anno, int mese, int giorno, string periodoDa, string periodoA, int strutturaId, int infermiereId, string causale)
        {
            return _necessitaGiorniResource.GetNecessitaGiorno(anno, mese, giorno, periodoDa, periodoA, strutturaId, infermiereId, causale);
        }

        public Task<OverlapNecessitaDto[]> GetOverlappedNecessita(int anno, int mese, int giorno, string periodoDa,
            string periodoA, int infermiereId)
        {
            return _necessitaGiorniResource.GetOverlappedNecessita(anno, mese, giorno, periodoDa, periodoA, infermiereId);
        }

        public Task<NightNecessitaGiornoDto[]> GetAlertNightNecessita(int anno, int mese, int giorno, int struttura)
        {
            return _necessitaGiorniResource.GetAlertNightNecessita(anno, mese, giorno, struttura);
        }
    }
}
