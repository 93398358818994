using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public class DashboardDto
    {
        [JsonProperty("giorno")]
        public string Giorno { get; set; }

        [JsonProperty("fasciaoraria")]
        public string FasciaOraria { get; set; }

        [JsonProperty("anomalia")]
        public int Anomalia { get; set; }

        [JsonProperty("fasciaorariada")]
        public int FasciaOrariaDa { get; set; }

        [JsonProperty("fasciaorariaa")]
        public int FasciaOrariaA { get; set; }

        [JsonProperty("numeroanomalie")]
        public int NumeroAnomalie { get; set; }

        public DashboardDto(string giorno, string fasciaOraria, int anomalia, int numeroAnomalie, int fasciaOrariaDa, int fasciaOrariaA)
        {
            Giorno = giorno;
            FasciaOraria = fasciaOraria;
            Anomalia = anomalia;
            FasciaOrariaDa = fasciaOrariaDa;
            FasciaOrariaA = fasciaOrariaA;
            NumeroAnomalie = numeroAnomalie;
        }
    }
}
