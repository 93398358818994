using System;
using System.Threading.Tasks;
using System.Collections.Generic;
using Bridge.Messenger;
using Bridge.Navigation;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Html5;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels
{
    public class NecessitaViewModel : BlockableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IMessenger _messenger;
        private readonly IStruttureService _struttureService;
        private readonly ITipiTurniService _tipiTurniService;
        private readonly IIndisponibilitaService _indisponibilitaService;
        private readonly IGiorniService _giornoService;
        private readonly IFasceOrarieService _fasceOrarieService;
        private readonly IInfermieriService _infermieriService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        private readonly ICookieService _cookieService;
        private readonly IExportService _exportService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.Necessita;
}
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.NecessitaGiornoDto>NecessitaGiorno { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.AnniDto>Anni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>AnnoSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>AnnoFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.MesiDto>Mesi { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>MeseSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>MeseFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.GiorniDto>Giorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>GiornoSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>GiornoFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FasceOrarieDto>FasceOrarie { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>FasciaOrariaSelected { get; set; }
        public Retyped.knockout.KnockoutObservable <string>FasciaFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.StruttureDto>Strutture { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>StrutturaSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>StrutturaFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto>Infermieri { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>InfermiereSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>InfermiereFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <string>Causali { get; set; }
        public Retyped.knockout.KnockoutObservableArray <string>CausaleSelected { get; set; }
        public Retyped.knockout.KnockoutObservable <string>CausaleFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservable <string>ExportLink { get; set; }

        public Retyped.knockout.KnockoutObservable <bool>LoaderPage { get; set; }

        public NecessitaViewModel(INavigator navigator, IMessenger messenger, IIndisponibilitaService indisponibilitaService,
                                  IGiorniService giornoService, IFasceOrarieService fasceOrarieService, IStruttureService struttureService,
                                  ITipiTurniService tipiTurniService, IInfermieriService infermieriService, INecessitaGiorniService necessitaGiorniService, ICookieService cookieService,
                                  IExportService exportService)
        {
            _navigator = navigator;
            _messenger = messenger;
            _indisponibilitaService = indisponibilitaService;
            _giornoService = giornoService;
            _fasceOrarieService = fasceOrarieService;
            _struttureService = struttureService;
            _tipiTurniService = tipiTurniService;
            _infermieriService = infermieriService;
            _necessitaGiorniService = necessitaGiorniService;
            _cookieService = cookieService;
            _exportService = exportService;

            NecessitaGiorno = Retyped.knockout.ko.observableArray.Self<NecessitaGiornoDto>();
            Anni = Retyped.knockout.ko.observableArray.Self<AnniDto>();
            Mesi = Retyped.knockout.ko.observableArray.Self<MesiDto>();
            Giorni = Retyped.knockout.ko.observableArray.Self<GiorniDto>();
            FasceOrarie = Retyped.knockout.ko.observableArray.Self<FasceOrarieDto>();
            Strutture = Retyped.knockout.ko.observableArray.Self<StruttureDto>();
            Infermieri = Retyped.knockout.ko.observableArray.Self<InfermieriDto>();
            Causali = Retyped.knockout.ko.observableArray.Self<string>();

            AnnoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            MeseSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            GiornoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            FasciaOrariaSelected = Retyped.knockout.ko.observableArray.Self<int>();
            StrutturaSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            InfermiereSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            CausaleSelected = Retyped.knockout.ko.observableArray.Self<string>();
            LoaderPage = Retyped.knockout.ko.observable.Self<bool>();

            AnnoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Anno...");
            MeseFilterCaption = Retyped.knockout.ko.observable.Self<string>("Mese...");
            GiornoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Giorno...");
            FasciaFilterCaption = Retyped.knockout.ko.observable.Self<string>("Orario...");
            StrutturaFilterCaption = Retyped.knockout.ko.observable.Self<string>("Strutture...");
            InfermiereFilterCaption = Retyped.knockout.ko.observable.Self<string>("Infermiere...");
            CausaleFilterCaption = Retyped.knockout.ko.observable.Self<string>("Causali...");
            ExportLink = Retyped.knockout.ko.observable.Self<string>();
        }

        #region OnLoad
        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            LoaderPage.Self(true);

            base.OnLoad(parameters);

            await Hydrate(parameters);

            LoaderPage.Self(false);
        }
        #endregion

        #region Hydrate
        private async Task Hydrate(Dictionary<string, object> parameters)
        {
            var anni = _indisponibilitaService.GetAnni();
            var strutture = _struttureService.GetAllStrutture();
            var infermieri = _infermieriService.GetAllInfermieri();
            var mesi = _indisponibilitaService.GetMesi();
            var giorni = _giornoService.GetAllGiorni(2018, 7); //Always load 31 days
            var fascie = _fasceOrarieService.GetAllFasceOrarie();
            await Task.WhenAll(anni, mesi, giorni, fascie, strutture, infermieri);

            Mesi.removeAll();
            Giorni.removeAll();
            FasceOrarie.removeAll();

            Anni.push(anni.Result.Items);
            Strutture.push(strutture.Result.Items);
            Infermieri.push(infermieri.Result.Items);
            Mesi.push(mesi.Result.Items);
            Giorni.push(giorni.Result.Items);
            FasceOrarie.push(fascie.Result.Items);
            if (parameters.Count == 0)
            {
                var year = DateTime.Now.Year;
                var month = DateTime.Now.Month;

                var defaultDate = _cookieService.GetDefaultDate();
                if (defaultDate.HasValue)
                {
                    year = defaultDate.Value.Year;
                    month = defaultDate.Value.Month;
                }

                AnnoSelectedId.push(year);
                MeseSelectedId.push(month);

                LoadFilters();

            } else {

                int anno = (parameters.GetParameter<int>("anno"));
                int mese = (parameters.GetParameter<int>("mese"));
                int giorno = (parameters.GetParameter<int>("giorno"));
                int fasciaorariaId = (parameters.GetParameter<int>("fasciaOraria"));

                string fascia = await _fasceOrarieService.GetFasciaOrariaById(fasciaorariaId);
                var necessita = await _necessitaGiorniService.GetNecessitaGiorno(anno, mese, giorno, fascia, fascia, 0, 0, null);
                
                //Set Selected filter with current dto
                AnnoSelectedId.push(anno);
                MeseSelectedId.push(mese);
                GiornoSelectedId.push(giorno);
                FasciaOrariaSelected.push(fasciaorariaId);
                RemoveCaptionGiorno();
                RemoveCaptionFascieOrarie();
                RemoveCaptionInfermiere();

                RefreshNecessitaGiorno(necessita);

            }
        }
        #endregion

        #region LoadCausaleByStruttura
        private async void LoadCausaleByStruttura()
        {
            StrutturaFilterCaption.Self(null);
            CausaleFilterCaption.Self("Causali...");
            CausaleSelected.removeAll();
            Causali.removeAll();
            var tipiTurniListTask = _tipiTurniService.GetAllCausaliLabels(StrutturaSelectedId.Self()[0]);
            await Task.WhenAll<string[]>(tipiTurniListTask);
            Causali.push(tipiTurniListTask.Result);
        }
        #endregion

        #region RemoveCaptionAnno
        private void RemoveCaptionAnno()
        {
            AnnoFilterCaption.Self(null);
        }
        #endregion

        #region RemoveCaptionMese
        private void RemoveCaptionMese()
        {
            MeseFilterCaption.Self(null);
        }
        #endregion

        #region RemoveCaptionGiorno
        private void RemoveCaptionGiorno()
        {
            GiornoFilterCaption.Self(null);
        }
        #endregion

        #region RemoveCaptionFascieOrarie
        private void RemoveCaptionFascieOrarie()
        {
            FasciaFilterCaption.Self(null);
        }
        #endregion

        #region RemoveCaptionCausale
        private void RemoveCaptionCausale()
        {
            CausaleFilterCaption.Self(null);
        }
        #endregion

        #region RemoveCaptionInfermiere
        private void RemoveCaptionInfermiere()
        {
            InfermiereFilterCaption.Self(null);
        }
        #endregion

        #region RefreshNecessitaGiorno
        private void RefreshNecessitaGiorno(NecessitaGiornoDto[] newNecessitaGiorno)
        {
            var removed = NecessitaGiorno.removeAll();
            NecessitaGiorno.push(newNecessitaGiorno);
        }
        #endregion

        #region LoadFilters
        public async void LoadFilters()
        {
            string fascia = null;
            int anno = 0;
            int mese = 0;
            int giorno = 0;
            int struttura = 0;
            int infermiere = 0;
            string causale = null;

            if (FasciaOrariaSelected.Self().Length > 0)
                fascia = await _fasceOrarieService.GetFasciaOrariaById(FasciaOrariaSelected.Self()[0]);
            if (AnnoSelectedId.Self().Length > 0)
                anno = AnnoSelectedId.Self()[0];
            if (MeseSelectedId.Self().Length > 0)
                mese = MeseSelectedId.Self()[0];
            if (GiornoSelectedId.Self().Length > 0)
                giorno = GiornoSelectedId.Self()[0];
            if (StrutturaSelectedId.Self().Length > 0)
                struttura = StrutturaSelectedId.Self()[0];
            if (InfermiereSelectedId.Self().Length > 0)
                infermiere = InfermiereSelectedId.Self()[0];
            if (CausaleSelected.Self().Length > 0)
                causale = CausaleSelected.Self()[0];

            var necessita = await _necessitaGiorniService.GetNecessitaGiorno(anno, mese, giorno, fascia, fascia, struttura, infermiere, causale);
            RefreshNecessitaGiorno(necessita);
        }
        #endregion

        #region ClearFilters
        private void ClearFilters()
        {
            ClearDayFilters();
            ClearStructureFilters();
            ClearInfermieriFilters();
        }
        #endregion

        #region ClearDayFilters
        private void ClearDayFilters()
        {
            AnnoFilterCaption.Self("Anno...");
            MeseFilterCaption.Self("Mese...");
            GiornoFilterCaption.Self("Giorno...");
            FasciaFilterCaption.Self("Orario...");
            AnnoSelectedId.removeAll();
            MeseSelectedId.removeAll();
            GiornoSelectedId.removeAll();
            FasciaOrariaSelected.removeAll();
        }
        #endregion

        #region ClearStructureFilters
        private void ClearStructureFilters()
        {
            StrutturaFilterCaption.Self("Strutture...");
            CausaleFilterCaption.Self("Causali...");
            StrutturaSelectedId.removeAll();
            CausaleSelected.removeAll();
            Causali.removeAll();
        }
        #endregion

        #region ClearInfermiereFilters
        private void ClearInfermieriFilters()
        {
            InfermiereFilterCaption.Self("Infermiere...");
            InfermiereSelectedId.removeAll();
        }
        #endregion

        #region CallToAnomalie
        public void CallToAnomalie(NecessitaGiornoDto anomalia)
        {
            var periodoDa = (int.Parse(anomalia.PeriodoDa.Substring(0, 2))+1).ToString();
            var parameters = global::Bridge.Script.CallFor(new Dictionary<string, object>(),(_o1)=>{_o1.Add("anno",this.AnnoSelectedId.Self());_o1.Add("mese",this.MeseSelectedId.Self());_o1.Add("giorno",anomalia.Data.Day);_o1.Add("fasciaOraria",periodoDa);return _o1;});
            _navigator.Navigate(Bridge.Spaf.SpafApp.AnomaliaId, parameters);
        }
        #endregion

        #region ExportStrutturePerInfermiere
        public void ExportStrutturePerInfermiere()
        {
            int anno = 0;
            int mese = 0;
            int infermiere = 0;

            if (AnnoSelectedId.Self().Length > 0)
                anno = AnnoSelectedId.Self()[0];
            if (MeseSelectedId.Self().Length > 0)
                mese = MeseSelectedId.Self()[0];
            if (InfermiereSelectedId.Self().Length > 0)
                infermiere = InfermiereSelectedId.Self()[0];

            this.ExportLink.Self(_exportService.ExportStrutturePerInfermiere(anno, mese, infermiere));
            Global.Open(ExportLink.Self(), "_blank");
        }
        #endregion
    }
}
