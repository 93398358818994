using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge;
using Bridge.Html5;
using Bridge.Messenger;
using Bridge.Navigation;
using Retyped;
using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge.jQuery2;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Anomalie
{
    public class AnomaliaViewModel : BlockableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IAnomalieService _anomaliaService;
        private readonly IMessenger _messenger;
        private readonly IStruttureService _struttureService;
        private readonly ITipiTurniService _tipiTurniService;
        private readonly ICookieService _cookieService;
        private readonly IIndisponibilitaService _indisponibilitaService;   //per ottenere lista mesi e anni
        private readonly IGiorniService _giornoService;
        private readonly IFasceOrarieService _fasceOrarieService;
        private readonly IInfermieriService _infermieriService;
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.AnomaliaId;
}
        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.AnomalieDto>Anomalie { get; set; }
        public ResolveAnomaliaViewModel resolveAnomaliaViewModel { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.StruttureDto>Strutture { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>StrutturaSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>StrutturaFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <string>Causali { get; set; }
        public Retyped.knockout.KnockoutObservableArray <string>CausaleSelected { get; set; }
        public Retyped.knockout.KnockoutObservable <string>CausaleFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.AnniDto>Anni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>AnnoSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>AnnoFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.MesiDto>Mesi { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>MeseSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>MeseFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.GiorniDto>Giorni { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>GiornoSelectedId { get; set; }
        public Retyped.knockout.KnockoutObservable <string>GiornoFilterCaption { get; set; }

        public Retyped.knockout.KnockoutObservableArray <global::Aip.Florence.FrontEnd.SpafApp.Dto.FasceOrarieDto>FasceOrarie { get; set; }
        public Retyped.knockout.KnockoutObservableArray <int>FasciaOrariaSelected { get; set; }
        public Retyped.knockout.KnockoutObservable <string>FasciaFilterCaption { get; set; }
        
        public Retyped.knockout.KnockoutObservable <bool>LoaderPage { get; set; }

        public HTMLElement modal { get; set; }
        public HTMLCollection<HTMLElement> closeModal { get; set; }

        public AnomaliaViewModel(INavigator navigator, IAnomalieService AnomaliaService, IMessenger messenger, IIndisponibilitaService indisponibilitaService,
                                    IGiorniService giornoService, IFasceOrarieService fasceOrarieService, IStruttureService struttureService, 
                                    ITipiTurniService tipiTurniService, IInfermieriService infermieriService, INecessitaGiorniService necessitaGiorniService, ICookieService cookieService)
        {
            _navigator = navigator;
            _messenger = messenger;
            _anomaliaService = AnomaliaService;
            _indisponibilitaService = indisponibilitaService;
            _giornoService = giornoService;
            _fasceOrarieService = fasceOrarieService;
            _struttureService = struttureService;
            _tipiTurniService = tipiTurniService;
            _infermieriService = infermieriService;
            _cookieService = cookieService;

            Anomalie = Retyped.knockout.ko.observableArray.Self<AnomalieDto>();
            Strutture = Retyped.knockout.ko.observableArray.Self<StruttureDto>();
            Causali = Retyped.knockout.ko.observableArray.Self<string>();
            Anni = Retyped.knockout.ko.observableArray.Self<AnniDto>();
            Mesi = Retyped.knockout.ko.observableArray.Self<MesiDto>();
            Giorni = Retyped.knockout.ko.observableArray.Self<GiorniDto>();
            FasceOrarie = Retyped.knockout.ko.observableArray.Self<FasceOrarieDto>();

            StrutturaSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            CausaleSelected = Retyped.knockout.ko.observableArray.Self<string>();
            AnnoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            MeseSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            GiornoSelectedId = Retyped.knockout.ko.observableArray.Self<int>();
            FasciaOrariaSelected = Retyped.knockout.ko.observableArray.Self<int>();
            LoaderPage = Retyped.knockout.ko.observable.Self<bool>();

            StrutturaFilterCaption = Retyped.knockout.ko.observable.Self<string>("Strutture...");
            CausaleFilterCaption = Retyped.knockout.ko.observable.Self<string>("Causali...");
            AnnoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Anno...");
            MeseFilterCaption = Retyped.knockout.ko.observable.Self<string>("Mese...");
            GiornoFilterCaption = Retyped.knockout.ko.observable.Self<string>("Giorno...");
            FasciaFilterCaption = Retyped.knockout.ko.observable.Self<string>("Orario...");

            modal = Document.GetElementById<HTMLDivElement>("anomalie_errorModal");
            closeModal = Document.GetElementsByClassName("close");

            //subscribers
            _messenger.Subscribe<Bridge.Spaf.SpafApp.Messages.GlobalSender>(this,
                Bridge.Spaf.SpafApp.Messages.RefreshInfermieriListByStructure, (global::System.Action<global::Bridge.Spaf.SpafApp.Messages.GlobalSender>)(async (vm) =>
                {
                    this.RefreshInfermieriListByStructure();
                }));

            _messenger.Subscribe<Bridge.Spaf.SpafApp.Messages.GlobalSender>(this,
                Bridge.Spaf.SpafApp.Messages.RefreshInfermieriListAll, (global::System.Action<global::Bridge.Spaf.SpafApp.Messages.GlobalSender>)(async (vm) =>
                {
                    this.RefreshInfermieriListAll();
                }));
        }

        public override async void OnLoad(Dictionary<string, object> parameters)
        {
            LoaderPage.Self(true);

            base.OnLoad(parameters);
            await HydrateAnomalie(parameters);

            LoaderPage.Self(false);
        }

        private async Task HydrateAnomalie(Dictionary<string, object> parameters)
        {
            //JS modal manage
            //for (var i = 0; i < closeModal.Length; i++)
            //{
            //    closeModal[i].OnClick = e => { modal.Style.Display = "none"; };
            //}
            //Window.OnClick = e =>
            //{
            //    if (e.Target == modal)
            //        modal.Style.Display = "none";
            //};

            var anni = _indisponibilitaService.GetAnni();
            var strutture = _struttureService.GetAllStrutture();
            await Task.WhenAll(anni, strutture);
            Anni.push(anni.Result.Items);
            Strutture.push(strutture.Result.Items);
            LoadMesi();
            LoadGiorni();
            LoadFascieOrarie();


            if (parameters.Count == 0)
            {
                var year = DateTime.Now.Year;
                var month = DateTime.Now.Month;

                var defaultDate = _cookieService.GetDefaultDate();
                if (defaultDate.HasValue)
                {
                    year = defaultDate.Value.Year;
                    month = defaultDate.Value.Month;
                }

                AnnoSelectedId.push(year);
                MeseSelectedId.push(month);

                LoadFilters();
            }
            else
            {
                int anno = (parameters.GetParameter<int>("anno"));
                int mese = (parameters.GetParameter<int>("mese"));
                int giorno = (parameters.GetParameter<int>("giorno"));
                int fasciaorariaId = (parameters.GetParameter<int>("fasciaOraria"));
                string fascia = await _fasceOrarieService.GetFasciaOrariaById(fasciaorariaId);
                var anomalie = await _anomaliaService.GetAnomalieByFilters(anno, mese, giorno, fascia, fascia, 0, null);
                //Set Selected filter with current dto
                AnnoSelectedId.push(anno);
                MeseSelectedId.push(mese);
                GiornoSelectedId.push(giorno);
                FasciaOrariaSelected.push(fasciaorariaId);
                RemoveCaptionAnno();
                RemoveCaptionMese();
                RemoveCaptionGiorno();
                RemoveCaptionFascieOrarie();

                RefreshAnomalie(anomalie);
            }
        }

        public async void Detail(AnomalieDto anomalia)
        {
            var modalDialogHTML = "<div class=\"row\">" +
                                    "<div class=\"col-md-6\">" +
                                        "<select id = \"resolveInfermieri\" class=\"form-control custom-select\" data-bind=\"options: Infermieri, value: InfermiereSelected, event: { change: LoadInfermiereTurnazione }, optionsText: 'NomeCompletoPriorita', optionsValue: 'Id', optionsCaption: \"Infermieri...\"\">" +
                                        "</select>" +
                                    "</div>" +
                                    "<div class=\"col-md-1\">" +
                                        "<div style=\"display:none\" data-bind = \"visible: Loader()\">" +
                                            "<svg class=\"circular\" viewBox=\"25 25 50 50\">" +
                                                "<circle class=\"path\" cx=\"50\" cy=\"50\" r=\"15\" fill=\"none\" stroke-width=\"2\" stroke-miterlimit=\"10\" />" +
                                            "</svg>" +
                                        "</div>" +
                                    "</div>" +
                                    "<div class=\"col-md-5 pb-5\">" +
                                        "<input type=\"checkbox\" data-bind=\"checked: AllInfermieri\" onclick=\"ReloadInfermieri(); \" id=\"checkbox_id\"/>" +
                                        "<label for=\"checkbox_id\">Vedi tutti gli infermieri</label>" +
                                    "</div>" +
                                  "</div>" +
                                  "<div class=\"row\">" +
                                    "<div class=\"col-md-6\">" +
                                        "<div class=\"card card-outline-aip\">" +
                                            "<div class=\"card-header\">" +
                                                "<h3>Anomalia Corrente</h3>" +
                                            "</div>" +
                                            "<div class=\"card-body\">" +
                                                    "<div class=\"form-group\"> <label class=\"control-label\" for=\"DataString\">Data<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\">" +  anomalia.DataString + "</div> </div>" +
                                                    "<div class=\"form-group\"> <label class=\"control-label\" for=\"Struttura\">Struttura<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\">" +  anomalia.Struttura.RagioneSociale + "</div> </div>" +
                                                    "<div class=\"form-group\"> <label class=\"control-label\" for=\"Causale\">Causale<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\">" +  anomalia.Causale + "</div> </div>" +
                                                    "<div class=\"form-group\"> <label class=\"control-label\" for=\"PeriodoDa\">Da<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\">" + anomalia.Periodo.Da + "</div> </div>" +
                                                    "<div class=\"form-group\"> <label class=\"control-label\" for=\"PeriodoA\">A<span class=\"mandatory\">*</span></label> <div class=\"col-md-8\">" + anomalia.Periodo.A + "</div> </div> " +
                                                    "<div class=\"clearfix\"></div>" +
                                            "</div>" +
                                        "</div>" +
                                    "</div>" +
                                    "<div class=\"col-md-6\">" +
                                        "<div class=\"card card-outline-aip\">" +
                                            "<div class=\"card-header\">" +
                                                "<h3>Turno Infermiere</h3>" +
                                            "</div>" +
                                            "<div class=\"card-body\">" +
                                                "<div class=\"table-responsive\">" +
                                                    "<caption> Turnazione </caption>" +
                                                    "<table class=\"table table-hover table-striped table-bordered\">" +
                                                        "<thead>" +
                                                            "<tr>" +
                                                                "<th>Struttura</th>" +
                                                                "<th>Causale</th>" +
                                                                "<th>FasciaOraria</th>" +
                                                            "</tr>" +
                                                        "</thead>" +
                                                        "<tbody data-bind=\"foreach:ConflittiTurni\">" +
                                                            "<tr>" +
                                                                "<td data-bind=\"text: Conflitto.Struttura.RagioneSociale\"></td>" +
                                                                "<td data-bind=\"text: Conflitto.Causale\"></td>" +
                                                                "<td><span data-bind=\"text: Conflitto.Periodo.Da\"></span>-<span data-bind=\"text: Conflitto.Periodo.A\"></span></td>" +
                                                            "</tr>" +
                                                        "</tbody>" +
                                                    "</table>" +
                                                "</div>" +
                                                "<div class=\"table-responsive\">" +
                                                    "<caption> Indisponibilita </caption>" +
                                                    "<table class=\"table table-hover table-striped table-bordered\">" +
                                                        "<thead>" +
                                                            "<tr>" +
                                                                "<th>FasciaOraria</th>" +
                                                            "</tr>" +
                                                        "</thead>" +
                                                        "<tbody data-bind=\"foreach:ConflittiIndisponibilita\">" +
                                                            "<tr>" +
                                                                "<td><span data-bind=\"text: Conflitto.Periodo.Da\"></span>-<span data-bind=\"text: Conflitto.Periodo.A\"></span></td>" +
                                                            "</tr>" +
                                                        "</tbody>" +
                                                    "</table>" +
                                                "</div>" +
                                            "</div>" +
                                        "</div>" +
                                    "</div>" +
                                  "</div>";

            var htmlElement = new HTMLElement();
            htmlElement.InnerHTML = modalDialogHTML;

            resolveAnomaliaViewModel = Bridge.Spaf.SpafApp.Container.Resolve<ResolveAnomaliaViewModel>();
            resolveAnomaliaViewModel.AnomaliaSelected = new AnomaliaKo(anomalia);
            resolveAnomaliaViewModel.AnomaliaSelected.Struttura.Init(anomalia.Struttura);
            resolveAnomaliaViewModel.AnnoSelectedId = anomalia.Data.Year;
            resolveAnomaliaViewModel.MeseSelectedId = anomalia.Data.Month;
            resolveAnomaliaViewModel.GiornoSelectedId = anomalia.Data.Day;

            var infermieriOrdinati = await _struttureService.GetInfermieriOrdinatiByStruttura(anomalia.Struttura.Id);
            resolveAnomaliaViewModel.Infermieri.push(infermieriOrdinati);
            Block();
            BootstrapDialog.Show(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Risolvi Anomalia",
                Closable = false,
                Message = htmlElement.InnerHTML,
                OnShown = () =>
                {
                    var pageNode = dom.document.getElementsByClassName("modal-dialog")[0];
                    
                    Retyped.knockout.ko.applyBindings(resolveAnomaliaViewModel, pageNode);
                    
                    Script.Call("SetModal750");
                    //Script.Call("SetSelect2");
                    Select2.Render((global::System.Action<object>)(o =>
                    {
                    }), "#resolveInfermieri");
                    Script.Write("$(\".bootstrap-dialog\").removeAttr(\"tabindex\");");
                },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => { self.ToDynamic().close(); }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Id = "btnConferma",
                        Label = "Conferma",
                        Action = async (self) => {
                            await this.ConfirmAnomaliaResolution(anomalia, self);
                        }
                    },
                }
            });
            UnBlock();
        }

        public async Task<bool> ConfirmAnomaliaResolution(AnomalieDto anomalia, object modal)
        {
            bool result = true;
            string resolveTxt = "Confermi l'associazione dell'infermiere selezionato?";
            if (resolveAnomaliaViewModel.ConflittiTurni.Self().Length > 0)
                resolveTxt = "ATTENZIONE! La selezione dell'infermiere potrebbe causare la generazione di una anomalia\nConfermi?";
            
            BootstrapDialog.Confirm(new BootstrapDialog.BootstrapDialogOptions()
            {
                Title = "Conferma risoluzione anomalia",
                Closable = false,
                Message = resolveTxt,
                OnShown = () => { },
                Buttons = new BootstrapDialog.BootstrapDialogButton[]
                {
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Chiudi",
                        Action = (self) => {
                            self.ToDynamic().close();
                        }
                    },
                    new BootstrapDialog.BootstrapDialogButton()
                    {
                        Label = "Conferma",
                        Action = async (o) =>
                        {
                            Block();
                            if(resolveAnomaliaViewModel.InfermiereSelected.Self() > -1)
                            {
                                var idAnomaliaToResolve = resolveAnomaliaViewModel.AnomaliaSelected.Id;
                                var idInfermiereToAssign = resolveAnomaliaViewModel.InfermiereSelected.Self();
                                var idsArrayNecessitaToDetach = resolveAnomaliaViewModel.ConflittiTurni.Self();
                                if(resolveAnomaliaViewModel.ConflittiIndisponibilita.Self().Length == 0 && resolveAnomaliaViewModel.InfermiereSelected.Self() > 0)
                                {
                                    try
                                    {
                                        List<int> ids = new List<int>();
                                        foreach (var item in idsArrayNecessitaToDetach)
                                        {
                                            ids.Add(item.Conflitto.Id);
                                        }
                                        var resolve = new ResolveAnomaliaDto{
                                            IdInfermiereToAssign = idInfermiereToAssign,
                                            IdsArrayNecessitaToDetach = ids.ToArray()
                                        };
                                        await _anomaliaService.ResolveAnomalia(idAnomaliaToResolve, resolve);
                                        LoadFilters();
                                        //Window.Location.Reload();
                                        jQuery.Select("#"+anomalia.Id).Hide();
                                    }
                                    catch (Exception)
                                    {
                                        result = false;
                                        BootstrapDialog.Alert("ATTENZIONE! Errore in fase di risoluzione anomalia");
                                    }
                                }
                                else
                                {
                                    result = false;
                                    BootstrapDialog.Alert("ATTENZIONE! Impossibile risolvere l'anomalia");
                                }
                            }
                            else{
                                result = false;
                                BootstrapDialog.Alert("ATTENZIONE! Nessun infermiere selezionato");
                            }

                            o.ToDynamic().close();
                            modal.ToDynamic().close();

                            UnBlock();
                        }
                    }
                }
            });
            return result;
        }

        public async void RefreshInfermieriListByStructure()
        {
            if (resolveAnomaliaViewModel != null)
            {
                resolveAnomaliaViewModel.Infermieri.removeAll();
                var infermieri = await _struttureService.GetInfermieriOrdinatiByStruttura(resolveAnomaliaViewModel.AnomaliaSelected.Struttura.StrutturaId.Self());
                resolveAnomaliaViewModel.Infermieri.push(infermieri);

            }
        }

        public async void RefreshInfermieriListAll()
        {
            if (resolveAnomaliaViewModel != null)
            {
                resolveAnomaliaViewModel.Infermieri.removeAll();
                var infermieri = await _infermieriService.GetAllInfermieriMap();
                resolveAnomaliaViewModel.Infermieri.push(infermieri.Items);
            }
        }

        //Called by Struttura Filter Select
        private async void LoadCausaleByStruttura()
        {
            StrutturaFilterCaption.Self(null);
            CausaleFilterCaption.Self("Causali...");
            CausaleSelected.removeAll();
            Causali.removeAll();
            var tipiTurniListTask = _tipiTurniService.GetAllCausaliLabels(StrutturaSelectedId.Self()[0]);
            await Task.WhenAll<string[]>(tipiTurniListTask);
            Causali.push(tipiTurniListTask.Result);
        }

        //Called by Anno Filter Select
        private void RemoveCaptionAnno()
        {
            AnnoFilterCaption.Self(null);
        }
        //Called by Mese Filter Select
        private void RemoveCaptionMese()
        {
            MeseFilterCaption.Self(null);
        }
        private async void LoadMesi()
        {
            Mesi.removeAll();
            var mesi = await _indisponibilitaService.GetMesi();
            Mesi.push(mesi.Items);
        }
        

        //Called by Giorno Filter Select
        private void RemoveCaptionGiorno()
        {
            GiornoFilterCaption.Self(null);
        }

        private async void LoadGiorni()
        {
            Giorni.removeAll();
            //Always load 31 days
            var giorni = await _giornoService.GetAllGiorni(2018, 7);
            Giorni.push(giorni.Items);
        }
        
        //Called By Fascia Filter Select
        private void RemoveCaptionFascieOrarie()
        {
            FasciaFilterCaption.Self(null);
        }

        private async void LoadFascieOrarie()
        {
            FasceOrarie.removeAll();
            var fascie = await _fasceOrarieService.GetAllFasceOrarie();
            FasceOrarie.push(fascie.Items);
        }
        
        //Called By Causale Filter Select
        private void RemoveCaptionCausale()
        {
            CausaleFilterCaption.Self(null);
        }

        //Called by Apply Button press...
        private async void LoadFilters()
        {
            LoaderPage.Self(true);

            string fascia = null;
            int anno = 0;
            int mese = 0;
            int giorno = 0;
            int struttura = 0;
            string causale = null;

            if(FasciaOrariaSelected.Self().Length > 0)
                fascia = await _fasceOrarieService.GetFasciaOrariaById(FasciaOrariaSelected.Self()[0]);
            if (AnnoSelectedId.Self().Length > 0)
                anno = AnnoSelectedId.Self()[0];
            if (MeseSelectedId.Self().Length > 0)
                mese = MeseSelectedId.Self()[0];
            if (GiornoSelectedId.Self().Length > 0)
                giorno = GiornoSelectedId.Self()[0];
            if (StrutturaSelectedId.Self().Length > 0)
                struttura = StrutturaSelectedId.Self()[0];
            if (CausaleSelected.Self().Length > 0)
                causale = CausaleSelected.Self()[0];

            var anomalie = await _anomaliaService.GetAnomalieByFilters(anno, mese, giorno, fascia, fascia, struttura, causale);
            RefreshAnomalie(anomalie);

            LoaderPage.Self(false);
        }

        private void ClearFilters()
        {
            ClearDayFilters();
            ClearStructureFilters();
        }

        private void RefreshAnomalie(AnomalieDto[] newAnomalie)
        {
            var removed = Anomalie.removeAll();
            Anomalie.push(newAnomalie);
        }

        private void ClearDayFilters()
        {
            AnnoFilterCaption.Self("Anno...");
            MeseFilterCaption.Self("Mese...");
            GiornoFilterCaption.Self("Giorno...");
            FasciaFilterCaption.Self("Orario...");
            AnnoSelectedId.removeAll();
            MeseSelectedId.removeAll();
            GiornoSelectedId.removeAll();
            FasciaOrariaSelected.removeAll();
        }

        private void ClearStructureFilters()
        {
            StrutturaFilterCaption.Self("Strutture...");
            CausaleFilterCaption.Self("Causali...");
            StrutturaSelectedId.removeAll();
            CausaleSelected.removeAll();
            Causali.removeAll();
        }

        public class AnomaliaKo
        {
            public AnomaliaKo(AnomalieDto anomalia)
            {
                Id = ((anomalia!=null?anomalia.Id:(int?)null) ?? 0);
                Struttura = new Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture.EditStrutturaViewModel.StrutturaKo((anomalia == null) ? null : anomalia.Struttura);
                Causale = Retyped.knockout.ko.observable.Self<string>((anomalia == null) ? string.Empty : anomalia.Causale);
                PeriodoDa = Retyped.knockout.ko.observable.Self<string>((anomalia == null || anomalia.Periodo == null) ? string.Empty : anomalia.Periodo.Da);
                PeriodoA = Retyped.knockout.ko.observable.Self<string>((anomalia == null || anomalia.Periodo == null) ? string.Empty : anomalia.Periodo.A);
                Data = Retyped.knockout.ko.observable.Self<DateTime>((anomalia == null) ? new DateTime() : anomalia.Data);
            }

            public int Id { get; set; }
            public Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture.EditStrutturaViewModel.StrutturaKo Struttura { get; set; }
            public Retyped.knockout.KnockoutObservable <string>Causale { get; set; }
            public Retyped.knockout.KnockoutObservable <string>PeriodoDa { get; set; }
            public Retyped.knockout.KnockoutObservable <string>PeriodoA { get; set; }
            public Retyped.knockout.KnockoutObservable <global::System.DateTime>Data { get; set; }
        }
    }
}
