using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.jQuery2;
using Bridge.Navigation;
using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public class PeriodoPrototipoResource : AuthorizedResourceBase, IPeriodoPrototipoResource
    {
        public PeriodoPrototipoResource(ISettings settings, INavigator navigator) : base(settings, navigator) { }


        public Task<PeriodoPrototipoDto> GetPeriodoPrototipoByStructureId(int structId)
        {
            var options = new AjaxOptions
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo",structId)),
                Type = "GET",
                DataType = "json"
            };

            return base.MakeCall<PeriodoPrototipoDto>(options);
        }


        public Task<PeriodoPrototipoDto> CreateNewPeriodoPrototipo(int strutturaId, PeriodoPrototipoDto currentPeriodoPrototipo)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo",strutturaId)),
                Type = "POST",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(currentPeriodoPrototipo)
            };

            return base.MakeCall<PeriodoPrototipoDto>(options);
        }

        public Task<PeriodoPrototipoDto> EditPeriodoPrototipo(int strutturaId, int id, PeriodoPrototipoDto currentPeriodoPrototipo)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo/{1}",strutturaId,id)),
                Type = "PUT",
                DataType = "json",
                ContentType = "application/json",
                Data = JsonConvert.SerializeObject(currentPeriodoPrototipo)
            };

            return base.MakeCall<PeriodoPrototipoDto>(options);
        }

        public Task DeletePeriodoPrototipo(int strutturaId, int id)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo/{1}",strutturaId,id)),
                Type = "DELETE"
            };

            return this.MakeCall<object>(options);
        }

        public Task TranslateTipiTurno(int value, int strutturaId, int id, string causale)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo/TranslateTipiTurno/{1}/{2}/{3}",strutturaId,value,id,causale)),
                Type = "POST"
            };
            return this.MakeCall<object>(options);
        }

        public Task DeleteAllTipiTurno(int strutturaId, int id)
        {
            var options = new AjaxOptions()
            {
                Url = base.UriBuild(string.Format("/strutture/{0}/periodoprototipo/DeleteAllTipiTurno/{1}",strutturaId,id)),
                Type = "DELETE"
            };
            return this.MakeCall<object>(options);
        }
    }
}
