using System;
using System.Threading.Tasks;
using Aip.Florence.FrontEnd.SpafApp.Classes;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Bridge.Html5;
using Bridge.jQuery2;
using Bridge.Navigation;

namespace Aip.Florence.FrontEnd.SpafApp.Resources.Impl
{
    public abstract class AuthorizedResourceBase : ResourceBase
    {
        private readonly INavigator _navigator;

        protected AuthorizedResourceBase(ISettings settings, INavigator navigator) : base(settings)
        {
            this._navigator = navigator;
        }

        protected override async Task<T> MakeCall<T>(AjaxOptions options)
        {
            try
            {
                var token = Window.LocalStorage["token"];

                options.BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", string.Format("Bearer {0}",token));
                    return true;
                };

                return await base.MakeCall<T>(options);
            }
            catch (PromiseException e)
            {
                // manage unauthorized
                var errorCode = e.ErrorCode();

                if (errorCode == 403)
                    Global.Alert("Non autorizzato. Contattare amministratore di sistema.");

                if (errorCode == 401)
                    GetToken();

                throw;
            }
        }

        /// <summary>
        /// Redirect to IS for retrieve token
        /// </summary>
        private void GetToken()
        {
            var authorizationUrl = this.Settings.AuthorizationUri;
            var client_id = this.Settings.ClientID;
            var redirect_uri = this.Settings.RedirectUri;
            var response_type = "token";
            var scope = this.Settings.Scope;
            var state = string.Format("{0}{1}",Date.Now(),Math.Random());

            var url =
                authorizationUrl + "?" +
                "client_id=" + Global.EncodeURI(client_id) + "&" +
                "redirect_uri=" + Global.EncodeURI(redirect_uri) + "&" +
                "response_type=" + Global.EncodeURI(response_type) + "&" +
                "scope=" + Global.EncodeURI(scope) + "&" +
                "state=" + Global.EncodeURI(state);

            Window.LocalStorage.SetItem("redirect", Window.Location.Href);
            Window.Location.Href = url;
        }
    }
}