using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class TurnazioniMensiliService : ITurnazioniMensiliService
    {
        private readonly ITurnazioniMensiliResource _turnazioniMensiliResource;

        public TurnazioniMensiliService(ITurnazioniMensiliResource TurnazioniMensiliResource)
        {
            _turnazioniMensiliResource = TurnazioniMensiliResource;
        }

        public Task<TurnazioniMensiliDto[]> ChangeStatoTurnazioneMensile(int anno, int mese, int stato, int strutturaId)
        {
            return _turnazioniMensiliResource.ChangeStatoTurnazioneMensile(anno, mese, stato, strutturaId);
        }

        public Task<TurnazioniMensiliDto[]> GetAllTurnazioniMensili(int anno, int mese)
        {
            return _turnazioniMensiliResource.GetAllTurnazioniMensili(anno, mese);
        }

        public Task<TurnazioniMensiliDto> GetTurnazioniMensileForStruttura(int anno, int mese, int strutturaId)
        {
            return _turnazioniMensiliResource.GetTurnazioniMensileForStruttura(anno, mese, strutturaId);
        }

        public Task PrepareTurnazioneMensile(NewMonthRequestDto newMonthRequest)
        {
            return _turnazioniMensiliResource.PrepareTurnazioneMensile(newMonthRequest);
        }

        public Task PrepareTurnazioneMensile(int anno, int mese, int strutturaId)
        {
            return _turnazioniMensiliResource.PrepareTurnazioneMensile(anno, mese, strutturaId);
        }

        public Task<bool> TurnazioniMensiliClosed(int anno, int mese, int strutturaId)
        {
            return _turnazioniMensiliResource.TurnazioniMensiliClosed(anno, mese, strutturaId);
        }

        public Task<bool> CheckStato(int anno, int mese, int stato, int strutturaId)
        {
            return _turnazioniMensiliResource.CheckStato(anno, mese, stato, strutturaId);
        }

        public Task<bool> CanPrepareTurnazioneMensileForStruttura(int strutturaId)
        {
            return _turnazioniMensiliResource.CanPrepareTurnazioneMensileForStruttura(strutturaId);
        }

        public Task<bool> TurnazioniMensiliInvioReport(int anno, int mese)
        {
            return _turnazioniMensiliResource.TurnazioniMensiliInvioReport(anno, mese);
        }

        public Task RequireSendReport(int anno, int mese)
        {
            return _turnazioniMensiliResource.RequireSendReport(anno, mese);
        }

        public Task<TurnazioniMensiliInfoStatoDto> TurnazioniMensiliInfoStato(int anno, int mese, int strutturaId)
        {
            return _turnazioniMensiliResource.TurnazioniMensiliInfoStato(anno, mese, strutturaId);
        }

        public Task<bool> NotificaChiusuraMese(int anno, int mese)
        {
            return _turnazioniMensiliResource.NotificaChiusuraMese(anno, mese);
        }

        public Task<bool> VisualizzaSuApp(int anno, int mese)
        {
            return _turnazioniMensiliResource.VisualizzaSuApp(anno, mese);
        }

        public Task<bool> InvioTurniStrutture(int anno, int mese)
        {
            return _turnazioniMensiliResource.InvioTurniStrutture(anno, mese);

        }

        public Task<bool> VisualizzaAggiungiNecessita(int anno, int mese, int strutturaId)
        {
            return _turnazioniMensiliResource.VisualizzaAggiungiNecessita(anno, mese, strutturaId);

        }
    }
}
