using Newtonsoft.Json;
using System.Collections.Generic;

namespace Aip.Florence.FrontEnd.SpafApp.Dto
{
    public enum StatoTurnazioneMensile { Preparing = 0, Prepared = 1, Closing = 2, Closed = 3 };

    public class TurnazioniMensiliDto
    {
        [JsonProperty("id")]
        public int Id { get; set; }

        [JsonProperty("mese")]
        public int Mese { get; set; }

        [JsonProperty("anno")]
        public int Anno { get; set; }

        [JsonProperty("isclosed")]
        public bool IsClosed { get; set; }

        [JsonProperty("strutturaid")]
        public int StrutturaId { get; set; }

        public TurnazioniMensiliDto(int id,int mese, int anno, bool isClosed, int strutturaId)
        {
            Id = id;
            Mese = mese;
            Anno = anno;
            IsClosed = isClosed;
            StrutturaId = strutturaId;
        }
    }
}
