using Bridge.Html5;
using Newtonsoft.Json;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class KeyValueRepository : IKeyValueRepository
    {
        public void AddOrUpdate(string key, string value)
        {
            Window.LocalStorage.SetItem(key, value);
        }

        public string ReadValue(string key)
        {
            return global::Bridge.Script.ToTemp("key1",Window.LocalStorage.GetItem(key))!=null?global::Bridge.Script.FromTemp<object>("key1").ToString():(string)null;
        }

        public void AddOrUpdate<T>(string key, T value)
        {
            var stringa = JsonConvert.SerializeObject(value);
            Window.LocalStorage.SetItem(key, stringa);
        }

        public T ReadValue<T>(string key)
        {
            var stored = this.ReadValue(key);
            return JsonConvert.DeserializeObject<T>(stored);
        }

        public void RemoveItem(string key)
        {
            Window.LocalStorage.RemoveItem(key);
        }
    }
}