using Aip.Florence.FrontEnd.SpafApp.Dto;
using Aip.Florence.FrontEnd.SpafApp.Resources;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.Services.Impl
{
    public class IndisponibilitaService : IIndisponibilitaService
    {
        private readonly IIndisponibilitaResource _indisponibilitaResource;

        public IndisponibilitaService(IIndisponibilitaResource IndisponibilitaMensiliResource)
        {
            _indisponibilitaResource = IndisponibilitaMensiliResource;
        }

        public Task<IndisponibilitaDto[]> GetIndisponibilitaPerInfermiere(int infermiereId)
        {
            return _indisponibilitaResource.GetIndisponibilitaPerInfermiere(infermiereId);
        }

        public Task<BaseDtoOutputModel<MesiDto>> GetMesi()
        {
            return _indisponibilitaResource.GetMesi();
        }

        public Task<BaseDtoOutputModel<AnniDto>> GetAnni()
        {
            return _indisponibilitaResource.GetAnni();
        }

        public Task<IndisponibilitaDto> CreateNewIndisponibilita(IndisponibilitaDto indisponibilita)
        {
            return _indisponibilitaResource.CreateNewIndisponibilita(indisponibilita);
        }

        public Task<IndisponibilitaDto> EditIndisponibilita(IndisponibilitaDto indisponibilita)
        {
            return _indisponibilitaResource.EditIndisponibilita(indisponibilita);
        }

        public Task<IndisponibilitaDto> GetIndisponibilitaPerInfermierePerId(int infermiereId, int id)
        {
            return _indisponibilitaResource.GetIndisponibilitaPerInfermierePerId(infermiereId, id);
        }

        public Task DeleteIndisponibilitaPerInfermiere(int infermiereId, int id)
        {
            return _indisponibilitaResource.DeleteIndisponibilitaPerInfermiere(infermiereId, id);
        }

        public Task<IndisponibilitaDto[]> GetIndisponibilitaPerInfermierePerData(int infermiereId, string data, string da, string a)
        {
            return _indisponibilitaResource.GetIndisponibilitaPerInfermierePerData(infermiereId, data, da, a);
        }

        public Task<IndisponibilitaDto[]> GetAllByInfermiere(int infermiereId, string start, string end)
        {
            return _indisponibilitaResource.GetAllByInfermiere(infermiereId, start, end);
        }
    }
}
