using Aip.Florence.FrontEnd.SpafApp.KnockoutModels;
using Aip.Florence.FrontEnd.SpafApp.Services;
using Aip.Florence.FrontEnd.SpafApp.Utility;
using Bridge.Messenger;
using Bridge.Navigation;
using Bridge.Spaf;
using System.Collections.Generic;
using System.Threading.Tasks;

namespace Aip.Florence.FrontEnd.SpafApp.ViewModels.Strutture
{
    public class AssociaInfermiereViewModel : LoadableViewModel
    {
        private readonly INavigator _navigator;
        private readonly IInfermieriService _infermieriService;
        private readonly IStruttureService _struttureService;
        private readonly INecessitaGiorniService _necessitaGiorniService;
        private readonly IMessenger _messenger;

        public AssociaInfermiereKo AssociaInfermiereKo { get; set; }
//public int[] Seleziona { get; set; }
//public List<AssociaInfermiereKo> AssociaInfermieriKo { get; set; }
public override string ElementId()
{
    return Bridge.Spaf.SpafApp.NecessitaId;
}
        public AssociaInfermiereViewModel(INavigator navigator, IInfermieriService InfermieriService, IStruttureService StruttureService, INecessitaGiorniService NecessitaGiorniService, IMessenger messenger)
        {
            _navigator = navigator;
            _infermieriService = InfermieriService;
            _struttureService = StruttureService;
            _necessitaGiorniService = NecessitaGiorniService;
            _messenger = messenger;

            AssociaInfermiereKo = new AssociaInfermiereKo();
        }        

        public async void InitEditAssociaInfermiere(int idInfermiere, int idStruttura, string errore)
        {
            var infermieri = _struttureService.GetOrderedAssociatedInfermieriForStruttura(idStruttura, 1);

            await Task.WhenAll<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto[]>(infermieri);

            AssociaInfermiereKo associaInfermiereKo = new AssociaInfermiereKo();
            AssociaInfermiereKo.Refresh(idInfermiere, infermieri.Result, errore);
        }

        public async void InitEditAssociaInfermiere(List<int> idInfermieri, int idStruttura, string errore)
        {
            var infermieri = _struttureService.GetOrderedAssociatedInfermieriForStruttura(idStruttura, 1);

            await Task.WhenAll<global::Aip.Florence.FrontEnd.SpafApp.Dto.InfermieriDto[]>(infermieri);

            AssociaInfermiereKo associaInfermiereKo = new AssociaInfermiereKo();
            AssociaInfermiereKo.Refresh(idInfermieri, infermieri.Result, errore);
        }

        //public async void InitEditAssociaInfermiere(List<int> idInfermieri, int idStruttura, string errore)
        //{
        //    var infermieri = _struttureService.GetOrderedAssociatedInfermieriForStruttura(idStruttura, 1);

        //    await Task.WhenAll(infermieri);

        //    foreach (int elemento in idInfermieri)
        //    {
        //        AssociaInfermiereKo associaInfermiereKo = new AssociaInfermiereKo();
        //        AssociaInfermiereKo.Refresh(elemento, infermieri.Result, errore);
        //        AssociaInfermieriKo.Add(associaInfermiereKo);
        //    }
        //}
    }
}
